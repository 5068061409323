import React, { useEffect } from 'react';

const IssueCollector = ({ collectorId }) => {
  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem('harploggedin') === 'true';

    if (isLoggedIn) {
      const script = document.createElement('script');
      script.src = `https://qnetjira.cms.gov/s/dfcd361f4ac205bed666905aa08c765f-T/-vkpdbr/813002/dc612081449bde6c3e001c67ab5d6f50/4.0.3/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=${collectorId}`;
      script.async = true;
      script.type = 'text/javascript';
      
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [collectorId]); // Include collectorId in the dependency array to re-run effect if it changes

  return null;
};

export default IssueCollector;
