import React, { Component } from 'react';
var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

class Similarity extends Component {

	constructor(props) {
		super(props);
		this.state = {
			url: '',
		};
		this.dashboard = null;
	}
	componentDidMount() {
		fetch('/api/' + this.props.office + '/quicksight/' + this.props.board)
			.then((response) => response.json())
			.then((json) => {
				var embedUrl = json.EmbedUrl + "#p.Partition=" + this.props.docketId

				this.setState({ url: embedUrl })
			});
	}

	updateFilters = (p) => {
		//console.log("Param: "+JSON.stringify(p));
		var newText = this.props.filters.text;
		var newCategory = this.props.filters.category;
		var newCid = this.props.filters.cid;
		var newDocId = this.props.filters.docId;
		var changed = false;
		if (typeof p !== 'undefined' && p.hasOwnProperty('changedParameters')) {
			for (var k in p.changedParameters) {
				if (p.changedParameters[k].name === 'Free') {
					newText = p.changedParameters[k].value;
					changed = true;
				}
			}
			if (changed) {
				var newFilters = {
					text: newText,
					category: newCategory,
					cid: newCid,
					docId: newDocId,
				}
				//console.log(`New Filters from QS: ${JSON.stringify(newFilters)}`);
				this.props.setFilters(newFilters)
			}
		}
	}
	loaded = () => {
		var flt = this.translateFilters(this.props.docketId, this.props.filters)
		if (flt !== null) {
			//console.log(`QS outbound paramters: ${JSON.stringify(flt)}`)
			this.dashboard.setParameters(flt)
		}
	}
	qsLoadError = (err) => {
		console.log(`Error loading QS for ${this.props.office}: \n${JSON.stringify(err)}`);
	}
	translateFilters = (did, flt) => {
		var rc = { Choosedocket: this.props.docketId }
		if (flt.text !== '') {
			rc['Free'] = flt.text;
		}
		return rc;
	}
	embed = (url) => {
		if (url !== undefined && url !== '') {
			console.log(`Setting docketId to ${this.props.docketId}`)
			var container = document.getElementById("embeddingContainer");
			var options = {
				url: url,
				container: container,
				scrolling: "no",
				height: "1000rem",
				parameters: {
					Choosedocket: this.props.docketId,
					Free: this.props.filters.text,
				},
				width: "100%",
				footerPaddingEnabled: true,

			};
			this.dashboard = QuickSightEmbedding.embedDashboard(options);
			this.dashboard.on('error', this.qsLoadError)
			this.dashboard.on("load", this.loaded)
			this.dashboard.on("parametersChange", this.updateFilters);
		}
	}

	render() {
		if (this.state.url !== '') {
			return (
				<div className="tab-bottom-dashboards" key={this.props.board} id="embeddingContainer">{this.embed(this.state.url)}</div>
			);
		} else {
			return (
				<div className="tab-top" key={this.props.board} id="embeddingContainer">No {this.props.label} available for {this.props.office}.</div>
			)
		}
	};

}
export default Similarity;