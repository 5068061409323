import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";

/**
* shows an important flag state for a comment.
*/
class CommentFlag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flagged: {}, 
            unFlagged: true,
        };  
    }

    _isMounted=false;
    
    componentWillUnmount() {
        this._isMounted = false;
    }
    getData = () => {
        this._isMounted = true;
        fetch(`/api/flagged/${this.props.user}/${this.props.docketId}/${this.props.commentId}/${this.props.attNum}/`)
          .then((response) => {
              if (this._isMounted)
                  return response.json()
          })
          .then((json) => {
              if (this._isMounted) {
                if(Object.keys(json).length === 0) {
					this.setState({unFlagged: true})
				  } else {
					this.setState({unFlagged: false})

				  }
                this.setState({ flagged: json })
              }
          });
    }
    componentDidMount() {
    	if (this.props.docketId!=='' && this.props.commentId!=='')
    		this.getData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.docketId!=='' && this.props.commentId!==''
        	&& (this.props.commentId !== prevProps.commentId || this.state.isFlagged !== prevState.isFlagged)) {
        	this.getData();
		}
    }
    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;   
    }   
    setIsFlagged = (e) => {
        let isFlagged = this.state.isFlagged
        // console.log(`isFlagged Changing to ${e.target.checked}` + isFlagged);
    }

	handleKeyPressFlagged = (event) => {
		if(event.key === 'Enter'){
		  this.handleFlagged(event);
		}
	  }
    handleFlagged = (event) => {
        // console.log("Flag button clicked" + event.target.checked)
        if (this._isMounted) {
            var m = this.convertUTCDateToLocalDate(new Date());
            var dtt=    m.getUTCFullYear() + "/" +
                ("0" + (m.getUTCMonth()+1)).slice(-2) + "/" +
                ("0" + m.getUTCDate()).slice(-2) + " " +
                ("0" + m.getUTCHours()).slice(-2) + ":" +
                ("0" + m.getUTCMinutes()).slice(-2);
            this.setState({ isFlagged: true });
            this.state.isFlagged = true;
            // console.log(this.state.isFlagged)
            this.setState({flagged: {uid: this.props.user, when: dtt,}});
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    commentId: this.props.commentId,
                    docketId: this.props.docketId,
                    uid: this.props.user,
                    when: dtt,
                    attNum: this.props.attNum,
                    comment: this.props.comment
                })
            };
            fetch('/api/flagged', requestOptions)
            .then(response => response.json())
            .then((json) => {
                if (this._isMounted) {
                    this.setState({ flagged: json,
                                    unFlagged: false })
                    // this.next(this.props.commentId);
                }
            });
        }
    };

    handleKeyPressUnflagged = (event) => {
		if(event.key === 'Enter'){
		  this.handleUnflagged(event);
		}
	  }

    handleUnflagged = (event) => {
        if(window.confirm('Are you sure you want to unflag this comment?')) {

            if (this._isMounted) {
                // console.log("Confirm")
                event.target.checked = false;
                this.state.flagged = {}
                this.setState({ isFlagged: false, flagged: {}})
                // console.log(typeof this.state.flagged.user)



                    // console.log("unflag button clicked" + event.target.checked)
                    this.setState({ isFlagged: false, flagged: {}})
                    this.state.isFlagged = false;
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            commentId: this.props.commentId,
                            docketId: this.props.docketId,
                            uid: this.props.user,
                            attNum: this.props.attNum
                        })
                    };

                    fetch('/api/flagged_delete', requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        if (this._isMounted) {
                            this.setState({ unFlagged: true, flagged: json })
                        }
                    });
            } 
        } else {
            event.preventDefault();
            event.target.checked = true;
            // console.log("Cancel")
            this.setState({ isFlagged: true, flagged: this.state.flagged})
            this.isFlagged = true;

        }
    } 
    

    next = (cid) => {
        if (typeof this.props.nextComment !== 'undefined') {
            this.props.nextComment(cid,'Comment List');
        }
    }

    mr = (r) => {
		return r && r.user && r.when ? `${r.user}: ${r.when}` : '';
    }
    
    render() { 
        const flaggedInfo = this.mr(this.state.flagged); 
		const isTooltipVisible = flaggedInfo.length > 0;
        if (this.state.unFlagged) {
            const Checkbox = ({ id, ariaLabel, value, onChange, onKeyPress}) => {
                return (
                        <input id={id} style={{margin: '3px'}} type="checkbox" onChange={onChange} onKeyPress={onKeyPress} aria-label={ariaLabel} value={value}/>


                );
            };
            return (
            <span>
                    <Checkbox
                        id={`flag-${this.props.commentId}`}
                        value={this.props.user}
                        onChange={this.handleFlagged}
                        onKeyPress={this.handleKeyPressFlagged}
                        ariaLabel={`flag comment ${this.props.commentId}`}
                    /></span>
        
         
            )
        } else {
            const Checkbox = ({ id, ariaLabel, value, onChange, onKeyPress, defaultChecked}) => {
                return (
                        <input name="flag" id={id} style={{margin: '3px'}} type="checkbox" onChange={onChange} onKeyPress={onKeyPress} aria-label={ariaLabel} value={value} defaultChecked={defaultChecked}/>
                );
            };
            return (
<>
                    <span data-tip data-for={"whodunit_flag" + this.props.commentId} >
                    <span>
                    <Checkbox
                        id={`unflag-${this.props.commentId}`}
                        value={this.props.user}
                        onChange={this.handleUnflagged}
                        onKeyPress={this.handleKeyPressUnflagged}
                        ariaLabel={`unflag comment ${this.props.commentId}`}
                        defaultChecked={true}
                        
      

        

                    />
                    </span>
                    </span>
                    {isTooltipVisible && (
                        <ReactTooltip id={"whodunit_flag" + this.props.commentId} place="top" effect="solid">
                            {flaggedInfo}
                        </ReactTooltip>
                    )}
              </>
            )
        }
    };
}
export default CommentFlag;
