import React, { Component } from 'react';

import DownloadFlagged from './DownloadFlagged';
import { Alert } from 'react-bootstrap';
class FlagSelections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selections: [],  
            ariaLiveMessage: ''
        }; 
       
    }
    setup() {
        fetch(`/api/flaggedSelections/${this.props.user}/${this.props.docketId}`)
		  .then((response) => {
			  if (this._isMounted)
			  	  return response.json()
		  })
		  .then((json) => {
			  if (this._isMounted) {
		  		this.setState({ selections: json.Items })
                this.props.setFlagCount(json.Items.length)
                if(json.Items.length===0) {
                    this.updateAriaLiveRegion("No flagged selections for docket " + this.props.docketId)
                }
              }
		  });
        //   console.log(this.state.selections)

    }
    componentDidMount() {

        this._isMounted = true;
        this.setup()
    }
 
    componentDidUpdate(pp,ps) {
        if(pp.docketId !== this.props.docketId) {
            this.setup()
        }
    }
    updateAriaLiveRegion = (message) => {
        this.setState({ariaLiveMessage: message});
    };
    render() {
        // console.log(this.state.selections)
        const DisplayData = this.state.selections.map(
            (info) => {
                return (
                    <tr>
                        <td>{info.comment_id}</td>
                        <td>{info.attachment_num}</td>
                        <td>{info.comment}</td>
                    </tr>
                )
            }
        )
        if(this.state.selections.length === 0) {
            return (<div style={{paddingTop: "10px"}}><Alert variant="info" style={{ backgroundColor: "#F4E3DB", borderColor: "red"  }}>
            <Alert.Heading><svg style={{ marginRight: "1%" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>No Flagged Selections</Alert.Heading>
            <p>
                Currently you have no Flagged selections for this docket.                       </p>
        </Alert></div>)
        
        }else {
        return (
            
            <>
                <div>
               
            
                <span  style={{fontWeight: "bold"}}><svg  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag-fill" viewBox="0 0 16 16">
  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
</svg>&nbsp; Flag | Below are the Comment IDs at attachment level that have been added to a Flag group</span><DownloadFlagged docketTitle={this.props.docketTitle} flagCount={this.props.flagCount} filters={this.props.filters} selections={this.state.selections} docketId ={this.props.docketId} office={this.props.office}/>
                <table className="table table-striped" role="presentation">
                            <col style={{ width: "15%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "75%" }}></col>
                            <thead>
                                <tr>
                                    <th>Comment ID</th>
                                    <th>ATT #</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DisplayData}
                            </tbody>
                        </table>
                        </div>
            </>
        )}
};
}
export default FlagSelections;