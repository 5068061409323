import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import {Button} from '@cmsgov/design-system';

class AttachmentPartPDF extends Component {
	state = {
		machine: '',
	}
	
	componentDidMount() {
        fetch('/api/machine')
			.then((response) => { return response.json() })
            .then((json) => { this.setState({ machine: json.machine } )})
	}
	
	render() {  
		if (this.props.attNum >= 1 && this.state.ip !== '') {
			let url=`${this.state.machine}/api/${this.props.office}/getPdf/${this.props.commentId}/${this.props.attNum}#search=${this.props.filterText}`;
	
			var txtButton=this.props.hasText?
				<Button  tabIndex="0" size="small" onClick={() => this.props.setShowPdf(false)}>Show Text and Modeling Data</Button> : 
				<Button tabIndex="0" aria-label="Text and Modeling Data unavailable for this attachment" size="small" disabled>Show Text and Modeling Data</Button>
			return (
				<>
					<Row className="comment-border-dotted-bottom comment-border-no-bottom">
						<Col md={3}>
							{txtButton}
						</Col>
						<Col>
						<div style={{padding: "8px"}}><b>{this.props.commentId} Attachment: {this.props.attNum}</b></div>
						</Col>
					</Row>
					<iframe id="pdf-js-viewer" 
						src={url} 
						title="webviewer" 
						style={{height: '900px', width: '100%'}}
						allowFullScreen={true} 
						frameBorder="1">
					</iframe>
				</>
			)
		} else {
			return null;
		}
	};
}
export default AttachmentPartPDF;