import React, { useEffect, useState } from 'react';
import { Table, Button, TableHead, TableBody, TableCell, TableRow } from '@cmsgov/design-system';
import './docketselect.css'
import { useNavigate } from 'react-router';

import DocketSelected from './DocketSelected';

const DocketSelect = (props) => {
    const navigate = useNavigate()

    const [docketData, setDocketData] = useState([]);

    useEffect(() => {
        setup();
        document.title = 'Docket Select';

    }, []);

   
    const setup = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user: props.user,
                restricted: props.restricted,
                admin: props.admin,
                group: props.office
            })
        };
        fetch('/api/docketIds', requestOptions)
            .then((response) => response.json())
            .then((json) => setDocketData(json.Items));

        props.setOffice(props.office, 'Docket Select');
        props.setDocketId(props.docketId, 'Docket Select');
    };

    const handleDocketChange = (office, docket, title) => {
        props.setOffice(office, 'Comment List');
        props.setDocketId(docket, 'Comment List');
        props.setDocketTitle(title);
        navigate('/comment-list')

    };

    const commaize = (i) => {
        return i.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    };

    const jsonDataDisplay = () => {
        return docketData.map((docket) => {
            if (docket.docket_id === props.docketId) {
                return (
                    <TableRow key={docket.docket_id}>
                        <TableCell>
                            <Button
                                id={"active docket" + docket.docket_id}
                                aria-label={"active docket"  + docket.docket_id}
                                size="small"
                                type="button"
                                className='selected_docket_button'

                                onClick={() => handleDocketChange(docket.office, docket.docket_id, docket.title)}
                            >
                                Active Docket
                            </Button>
                        </TableCell>
                        <TableCell aria-labelledby="id-header">{docket.docket_id}</TableCell>
                        <TableCell  aria-labelledby="docs-header" align="right">{docket.num_documents}</TableCell>
                        <TableCell aria-labelledby="type-header">{docket.type}</TableCell>
                        <TableCell aria-labelledby="title-header">{docket.title}</TableCell>
                        <TableCell aria-labelledby="last-updated-header">{docket.last_updated}</TableCell>
                        <TableCell aria-labelledby="comments-header" align="right">{docket.num_comments}</TableCell>
                    </TableRow>
                );
            } else {
                return (
                    <TableRow key={docket.docket_id}>
                        <TableCell>
                            <Button
                                id={"select docket" + docket.docket_id}
                                aria-label={"select docket" + docket.docket_id}
                                size="small"
                                type="button"
                                onClick={() => handleDocketChange(docket.office, docket.docket_id, docket.title)}
                                className='nonselected_docket_button'
                                disabled={docket.num_comments === 0}
                            >
                                Select Docket
                            </Button>
                        </TableCell>
                        <TableCell >{docket.docket_id}</TableCell>
                        <TableCell align="right">{docket.num_documents}</TableCell>
                        <TableCell >{docket.type}</TableCell>
                        <TableCell >{docket.title}</TableCell>
                        <TableCell >{docket.last_updated}</TableCell>
                        <TableCell align="right">{commaize(docket.num_comments)}</TableCell>
                    </TableRow>
                );
            }
        });
    };
    const tableClass = props.docketId === '' ? "docket-select-body-no-docket" : "docket-select-body";

    return (
        <>
            <div className="tab-top">
                <div className="row" style={{ marginTop: "1.5%" }}>
                    <div className="column">
                        <h1 className="level-0-headings">Docket Select</h1>
                        Below are the available Dockets from regulations.gov that have been loaded into the FAS Application and are available for analysis.               
                     </div>
                    <div className="column">
                        <DocketSelected docketId={props.docketId} docketTitle={props.docketTitle}></DocketSelected>
                    </div>
                </div>
            </div>
            <div className={tableClass}>
            <div style={{fontSize: 22, fontWeight: 600, paddingBottom: '16px'}}>Click on the “Select Docket” button in the table below to select a Docket. </div>
            <Table borderless={true} scrollable={true} aria-label="Docket Select Table" >
                <TableHead role="rowgroup" style={{backgroundColor: '#F0F0F0'}}>
                        <TableRow role="row">
                            <TableCell align="left">
                                <div className="select-box" id="select-header">
                                    Select
                                </div>
                            </TableCell>
                            <TableCell align="left">
                                <div className="id-box" id="id-header" >
                                    ID
                                </div>
                            </TableCell>
                            <TableCell align="left" id="docs-header" >
                                Docs
                            </TableCell>
                            <TableCell align="left" id="type-header" >
                                Type
                            </TableCell>
                            <TableCell align="left" id="title-header" >
                                Title
                            </TableCell>
                            <TableCell id="last-updated-header" align="center" >
                                Last Updated
                            </TableCell>
                            <TableCell id="comments-header" align="center" >
                                Comments
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jsonDataDisplay()}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

export default DocketSelect;
