import React, { Component } from 'react';

import { Row, Col, Form, Collapse } from 'react-bootstrap';
import { Choice, Button } from '@cmsgov/design-system';

import ActiveFilters from './ActiveFilters'
import ActiveKeywords from './ActiveKeywords';
import FilterSelect from '../sharedComponents/FilterSelect';
import Pager from './Pager';
import TotalComments from './TotalComments'
import './activeFilters.css'
import DocketSelected from '../docketSelect/DocketSelected';
import MultipleSelectChip from './MultipleSelectChip';


class FiltersBox extends Component {

	constructor(props) {
		super(props);

		this.filterText = React.createRef();
		this.cid = React.createRef();
		this.filterText = React.createRef();
		this.cid = React.createRef();
		this.state = {
			categories: [],
			docIds: [],
			topics: [],
			themes: [],
			stakeholders: [],
			settingsOfCare: [],
			specialty: [],
			role: [],
			locations: [],
			hasDupes: false,
			settingsOfCare: [],
			specialty: [],
			role: [],
			filtersBoxOpen: false,
			isRotating: false,
			resetFlag: false,
			noTopics: false,
			noCategories: false,
			noLocations: false,
			noStakeholders: false,
			noSpecialties: false,
			noRoles: false,
			noThemes: false,
			noSettingsOfCare: false

		}

	}
	_intialDataLoad = false;

	handleToggleFiltersBox = () => {
		this.setState(prevState => ({
			filtersBoxOpen: !prevState.filtersBoxOpen,
		}));
	};
	closeFilterBoxandApplyFilters = () => {
		// this.props.updateAriaLiveRegion(this.props.count + "filtered comments")
		// console.log('hereerere')
		console.log('1')
		this.props.setFilters({
			text: this.filterText.current.value,
			category: this.props.filterSelections.category,
			stakeholder: this.props.filterSelections.stakeholder,
			settingOfCare: this.props.filterSelections.settingOfCare,
			specialty: this.props.filterSelections.specialty,
			role: this.props.filterSelections.role,
			location: this.props.filterSelections.location,
			theme: this.props.filterSelections.theme,
			topic: this.props.filterSelections.topic,
			cid: this.cid.current.value,
			docId: this.props.filterSelections.docId,
			ignoreDupes: this.props.filterSelections.ignoreDupes,
			submitterOrgFound: this.props.filterSelections.submitterOrgFound,
			recInstancesFound: this.props.filterSelections.recInstancesFound,
			clarInstancesFound: this.props.filterSelections.clarInstancesFound,
			agreementInstancesFound: this.props.filterSelections.agreementInstancesFound,
			disagreementInstancesFound: this.props.filterSelections.disagreementInstancesFound,

		})
		this.setState({ filtersBoxOpen: false })
	}
	catOptions = (categories) => {
		var opts = []
		categories.forEach((c) => {
			opts.push({ value: c.category, label: c.category });
		});
		// console.log(`Categories: ${JSON.stringify(opts)}`);
		return opts;
	}
	locationOptions = (locations) => {
		var opts = []
		locations.forEach((c) => {
			opts.push({ value: c.location, label: c.location });
		});
		// console.log(`Categories: ${JSON.stringify(opts)}`);
		return opts;
	}
	stakeOptions = (stakeholders) => {
		var opts = []
		stakeholders.forEach((c) => {
			opts.push({ value: c.stakeholder_type, label: c.stakeholder_type });
		});
		// console.log(`Categories: ${JSON.stringify(opts)}`);
		return opts;
	}

	settingOptions = (settings) => {
		var opts = []
		settings.forEach((c) => {
			opts.push({ value: c.setting_of_care, label: c.setting_of_care });
		});
		// console.log(`Categories: ${JSON.stringify(opts)}`);
		return opts;
	}
	specialtyOptions = (specialty) => {
		var opts = []
		specialty.forEach((c) => {
			opts.push({ value: c.specialty, label: c.specialty });
		});
		// console.log(`Categories: ${JSON.stringify(opts)}`);
		return opts;
	}

	roleOptions = (role) => {
		// console.log(role)
		var opts = []
		role.forEach((c) => {
			opts.push({ value: c.role, label: c.role });
		});
		// console.log(`Roles: ${JSON.stringify(opts)}`);
		return opts;
	}


	didOptions = (dids) => {
		var opts = []
		dids.forEach((c) => {
			opts.push({ value: c.document_id, label: c.document_id });
		});
		//console.log(`documents: ${JSON.stringify(opts)}`);
		return opts;
	}
	isDiff = (x, y) => {
		var rc = false;
		if (typeof x == null || typeof y == null) {
			rc = false;
		} else if ((typeof x) !== (typeof y)) {
			rc = true;
		} else if (typeof x === 'string' && x !== y) {
			rc = true
		} else if (Array.isArray(x) && JSON.stringify(x) !== JSON.stringify(y)) {
			rc = true;
		}
		return rc;
	}
	_isMounted = false;

	componentWillUnmount() {
		this._isMounted = false;
	}


	getData = () => {
		console.log('firing hasDupes test')
		this._isMounted = true;
		if (!this._intialDataLoad) {

			fetch(`/api/${this.props.office}/hasDupes/${this.props.docketId}`)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					this.setState(json)
					console.log('boom')
					if (json.hasDupes === true) {
						console.log('update needed')
						this.props.setDupesFilter(true)
						this.setState({ hasDupes: true })
						// let filterSelections = this.props.filterSelections;
						// filterSelections.ignoreDupes = true;
						// console.log(filterSelections)
						// this.props.setFilterSelections(filterSelections)
						// console.log('2')
						this.props.onTriggerGetData();
					}
					else {
						// let filterSelections = this.props.filterSelections;
						// filterSelections.ignoreDupes = false;
						// console.log(filterSelections)
						// this.props.setFilterSelections(filterSelections)
						// console.log('3')
						// this.props.setFilters(filterSelections);
						this.setState({ hasDupes: false })

						this.props.setDupesFilter(false)
						this.props.onTriggerGetData();
					}

					//					this.setState({ignoreDupes: json.hasDupes?true:false})

				});
			fetch('/api/' + this.props.office + '/categories/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					console.log("API Cats" + JSON.stringify(json))
					this.setState({ categories: this.catOptions(json) })
					if (json.length === 0) {
						this.setState({ noCategories: true })
					}

				});
			fetch('/api/' + this.props.office + '/stakeholder_type/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					this.setState({ stakeholders: this.stakeOptions(json) })
					if (json.length === 0) {
						this.setState({ noStakeholders: true })
					}

				});
			fetch('/api/' + this.props.office + '/setting_of_care/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					this.setState({ settingsOfCare: this.settingOptions(json) })
					if (json.length === 0) {
						this.setState({ noSettingsOfCare: true })
					}

				});
			fetch('/api/' + this.props.office + '/specialty/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					this.setState({ specialty: this.specialtyOptions(json) })
					if (json.length === 0) {
						this.setState({ noSpecialties: true })
					}

				});
			fetch('/api/' + this.props.office + '/role/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					// console.log(JSON.stringify(json))
					this.setState({ role: this.roleOptions(json) })
					if (json.length === 0) {
						this.setState({ noRoles: true })
					}

				});
			fetch('/api/' + this.props.office + '/docIds/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					this.setState({ docIds: this.didOptions(json) })

				});

			// Fetch topics from server
			fetch('/api/' + this.props.office + '/topics/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					this.setState({
						topics: json.map((topic) => {
							return {
								value: topic.topic,
								label: topic.topic,
							}
						})
					})
					if (json.length === 0) {
						this.setState({ noTopics: true })
					}

				});
			fetch('/api/' + this.props.office + '/themes/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					this.setState({
						themes: json.map((themes) => {
							return {
								value: themes.theme,
								label: themes.theme,
							}
						})
					})
					if (json.length === 0) {
						this.setState({ noThemes: true })
					}

				});
			fetch('/api/' + this.props.office + '/locations/' + this.props.docketId)
				.then((response) => {
					return response.json()
				})
				.then((json) => {
					this.setState({ locations: this.locationOptions(json) })
					if (json.length === 0) {
						this.setState({ noLocations: true })
					}

				});
		}
		else {
			console.log('data loaded')
		}

	}
	componentDidUpdate(pp, ps) {
		if (pp.docketId !== this.props.docketId) {
			this.getData()
			this._intialDataLoad = false;
		}
	}
	componentDidMount() {
		this._intialDataLoad = false;
		this.getData()
	}
	hasFilters = () => {
		console.log(this.props.filters)
		console.log(this.props.filterSelections)
		var rc = false;
		if (this.props.filters.hasOwnProperty('text') && this.props.filters.text !== '')
			rc = true;
		if (this.props.filters.hasOwnProperty('cid') && this.props.filters.cid !== '')
			rc = true;
		if (this.props.filters.hasOwnProperty('category') && this.props.filters.category.length > 0)
			rc = true;
		if (this.props.filters.hasOwnProperty('docId') && this.props.filters.docId.length > 0)
			rc = true;
		if (this.props.filters.hasOwnProperty('topic') && this.props.filters.topic.length > 0)
			rc = true;
		if (this.state.hasDupes && this.props.filters.hasOwnProperty('ignoreDupes') && this.props.filters.ignoreDupes === true)
			rc = true;
		if (this.props.filters.hasOwnProperty('submitterOrgFound') && this.props.filters.submitterOrgFound === true)
			rc = true;
		return rc;
	}

	resetFilters = () => {
		this.props.clearFiltersNoKeywords()
		this.setState({ resetFlag: !this.state.resetFlag })
	}
	setIgnoreDupes = (e) => {
		let filterSelections = this.props.filterSelections;
		filterSelections.ignoreDupes = e.target.checked;
		// console.log(`ignoreDupes Changing to ${e.target.checked}`);
		this.props.setFilterSelections(filterSelections);
		console.log('4')
	}

	setSubmitterOrgFound = (e) => {
		console.log(this.props.filters)
		let filterSelections = this.props.filterSelections;
		console.log(filterSelections)
		filterSelections.submitterOrgFound = e.target.checked;
		// console.log(`ignoreDupes Changing to ${e.target.checked}`);
		this.props.setFilterSelections(filterSelections);
		console.log('5')

	}


	setRecInstancesFound = (e) => {
		let filterSelections = this.props.filterSelections;
		filterSelections.recInstancesFound = e.target.checked;
		// console.log(`ignoreDupes Changing to ${e.target.checked}`);
		this.props.setFilterSelections(filterSelections);
		console.log('6')

	}
	setClarInstancesFound = (e) => {
		let filterSelections = this.props.filterSelections;
		filterSelections.clarInstancesFound = e.target.checked;
		// console.log(`ignoreDupes Changing to ${e.target.checked}`);
		this.props.setFilterSelections(filterSelections);
		console.log('7')

	}

	setAgrInstancesFound = (e) => {
		let filterSelections = this.props.filterSelections;
		filterSelections.agreementInstancesFound = e.target.checked;
		// console.log(`ignoreDupes Changing to ${e.target.checked}`);
		this.props.setFilterSelections(filterSelections);
		console.log('8')

	}

	setDisagrInstancesFound = (e) => {
		let filterSelections = this.props.filterSelections;
		filterSelections.disagreementInstancesFound = e.target.checked;
		// console.log(`ignoreDupes Changing to ${e.target.checked}`);
		this.props.setFilterSelections(filterSelections);
		console.log('9')

	}
	handleKeyPress = (event) => {
		var checkbox = document.getElementById("uniqueCheck");
		if (!checkbox.checked) {
			checkbox.checked = true;
		} else if (checkbox.checked) {
			checkbox.checked = false;
		}
		if (event.key === 'Enter') {
			event.target.checked = checkbox.checked;
			this.setIgnoreDupes(event);
		}
	}
	selectCategory = (cat) => {
		// const cats = c.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.category = cat;
		//console.log(`category Changing to ${JSON.stringify(cats)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('11')

	}
	selectStakeholder = (c) => {
		// const stakes = c.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.stakeholder = c;
		//console.log(`category Changing to ${JSON.stringify(cats)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('12')

	}
	selectSettingofCare = (c) => {
		// const settings = c.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.settingOfCare = c;
		//console.log(`category Changing to ${JSON.stringify(cats)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('13')

	}
	selectSpecialty = (c) => {
		// const specialty = c.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.specialty = c;
		//console.log(`category Changing to ${JSON.stringify(cats)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('14')

	}
	selectRole = (c) => {
		// const role = c.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.role = c;
		//console.log(`category Changing to ${JSON.stringify(cats)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('15')

	}
	selectLocation = (c) => {
		// const locations = c.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.location = c;
		//console.log(`category Changing to ${JSON.stringify(cats)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('16')

	}
	selectDocId = (d) => {
		const docs = d.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.docId = docs;
		// console.log(`docs Changing to ${JSON.stringify(docs)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('17')

	}
	selectDocId = (d) => {
		const docs = d.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.docId = docs;
		// console.log(`docs Changing to ${JSON.stringify(docs)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('18')

	}
	selectTopic = (t) => {
		// const tops = t.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.topic = t;
		//console.log(`topic Changing to ${JSON.stringify(tops)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('19')

	}
	selectTheme = (t) => {
		// const themes = t.map(el => el.value);
		let filterSelections = this.props.filterSelections;
		filterSelections.theme = t;
		//console.log(`topic Changing to ${JSON.stringify(tops)}`);
		this.props.setFilterSelections(filterSelections);
		console.log('20')

	}
	clearFilters = () => {
		this.props.clearFilters();
	}
	clearBoxFilters = () => {
		console.log('4')
		this.props.setFilters({
			text: this.filterText.current.value,
			category: [],
			topic: [],
			themes: [],
			cid: '',
			docId: [],
			ignoreDupes: false,
			submitterOrgFound: false,
			recInstancesFound: false,
			clarInstancesFound: false,
			agreementInstancesFound: false,
			disagreementInstancesFound: false,
			settingOfCare: [],
			specialty: [],
			role: [],



		})
	}
	clearKeyword = () => {
		this.props.setFilters({
			text: "",
			category: this.props.filterSelections.category,
			topic: this.props.filterSelections.topic,
			themes: this.props.filterSelections.theme,
			stakeholder: this.props.filterSelections.stakeholder,
			settingOfCare: this.props.filterSelections.settingOfCare,
			location: this.props.filterSelections.location,
			cid: "",
			docId: this.props.filterSelections.docId,
			ignoreDupes: this.props.filterSelections.ignoreDupes,
			submitterOrgFound: this.props.filterSelections.submitterOrgFound,
			recInstancesFound: this.props.filterSelections.recInstancesFound,
			clarInstancesFound: this.props.filterSelections.clarInstancesFound,
			agreementInstancesFound: this.props.filterSelections.agreementInstancesFound,
			disagreementInstancesFound: this.props.filterSelections.disagreementInstancesFound,
			specialty: this.props.filterSelections.specialty,
			role: this.props.filterSelections.role,
		});

		this.setState({
			filterText: "",
			cid: ""
		});

		if (this.filterText.current) {
			this.filterText.current.value = "";
		}
		if (this.cid.current) {
			this.cid.current.value = "";
		}
	};



	applyTextFilters = () => {
		// this.props.updateAriaLiveRegion(this.props.count + "loaded comments")
		console.log('6')

		this.props.setFilters({
			text: this.filterText.current.value,
			category: this.props.filterSelections.category,
			stakeholder: this.props.filterSelections.stakeholder,
			settingOfCare: this.props.filterSelections.settingOfCare,
			location: this.props.filterSelections.location,
			theme: this.props.filterSelections.theme,
			topic: this.props.filterSelections.topic,
			cid: this.cid.current.value,
			docId: this.props.filterSelections.docId,
			ignoreDupes: this.props.filterSelections.ignoreDupes,
			submitterOrgFound: this.props.filterSelections.submitterOrgFound,
			recInstancesFound: this.props.filterSelections.recInstancesFound,
			clarInstancesFound: this.props.filterSelections.clarInstancesFound,
			agreementInstancesFound: this.props.filterSelections.agreementInstancesFound,
			disagreementInstancesFound: this.props.filterSelections.disagreementInstancesFound,
			specialty: this.props.filterSelections.specialty,
			role: this.props.filterSelections.role,
		})
	}


	render() {
		console.log(this.state.hasDupes)
		return (

			<>
				<TotalComments
					updateAriaLiveRegion={this.props.updateAriaLiveRegion}
					filtered={this.hasFilters()}
					changePage={this.props.changePage}
					page={this.props.page}
					count={this.props.count}
					countLoaded={this.props.countLoaded}
					docketId={this.props.docketId}
					docketTitle={this.props.docketTitle}

				/>


				{/* <Accordion.Item eventKey="1">
						<Accordion.Header style={{ border: 'solid 1px', borderColor: "#0071bc" }}><p>Comment Filters</p>
						</Accordion.Header>
						<Accordion.Body> */}

				<div style={{ paddingBottom: '8px', display: 'flex', gap: '16px' }}>
					<Button
						id="specificButton"
						onClick={this.handleToggleFiltersBox}
						className={`comments_button_primary ${this.state.filtersBoxOpen ? 'activeFilterBox' : ''}`}
						aria-label='Filters'
						aria-expanded={this.state.filtersBoxOpen}  // Add this line

					>
						Filters
					</Button>

					<Button
						onClick={() => this.resetFilters()}
						className='comments_button_primary_outline'
						// aria-controls="Reset-filters-except-keyword"
						aria-label="Reset-filters-except-keyword"
						id="clear-active-filters"
					>
						Clear Filters
						<svg aria-hidden="true" style={{ cursor: "pointer", marginLeft: '4px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
							<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
							<path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
						</svg>
					</Button>
				</div>





				<Collapse in={this.state.filtersBoxOpen}>
					<div>
						<section class="ds-l-container-lg" style={{ border: '3px solid #005CB0', padding: '16px' }}>
							<div class="ds-l-row">
								<div class="ds-l-col">
									<fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Deduplication Filter</legend>

										<hr aria-hidden="true" />

										<Choice
											label="Remove Duplicate Comments"
											name="checkbox_unique"
											type="checkbox"
											checked={this.state.hasDupes && this.props.filterSelections.ignoreDupes}
											aria-label="Remove Duplicate Comments"
											disabled={!this.state.hasDupes}
											onChange={e => this.setIgnoreDupes(e)}
											id="duplicate-comments-checkbox"
										></Choice>

									</fieldset>

									<fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Topic Filter</legend>


										<hr aria-hidden="true" />

										<MultipleSelectChip
											// disabled={this.state.noTopics} 
											selectedValues={this.props.filterSelections.topic} disabled={this.state.noTopics} resetFlag={this.state.resetFlag} label="Select Topic(s)" handleChange={this.selectTopic} selections={this.state.topics.map(item => item.value)}></MultipleSelectChip>
										{this.state.noTopics && <p style={{ color: "#AE1C1C", paddingLeft: '5px' }}>No options available.</p>}
										{/* <FilterSelect
											placeholder={''}
											selection={this.props.filterSelections.topic}
											isMulti
											selectItem={this.selectTopic}
											selections={this.state.topics}
											ariaLabel="Topics Filter"
											label="Topics"
											id="topics-select"

										/> */}
									</fieldset>
									{/* <fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Category Filter</legend>
										<hr aria-hidden="true" />


										<FilterSelect
											placeholder={''}
											selection={this.props.filterSelections.category}
											selectItem={this.selectCategory}
											isMulti
											selections={this.state.categories}
											label="Categories"
											ariaLabel="Categories"
											id="categories-select"


										/>
									</fieldset> */}
									<fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Category Filter</legend>
										<hr aria-hidden="true" />
										<MultipleSelectChip
											resetFlag={this.state.resetFlag}
											label="Select Categories"
											handleChange={this.selectCategory}
											selections={this.state.categories.map(item => item.value)}
											disabled={this.state.noCategories}
											selectedValues={this.props.filterSelections.category}
										/>
										{this.state.noCategories && <p style={{ color: "#AE1C1C", paddingLeft: '5px' }}>No options available.</p>}


									</fieldset>

									<fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Location Filter</legend>

										<MultipleSelectChip
											resetFlag={this.state.resetFlag}
											label="Select Location(s)"
											handleChange={this.selectLocation}
											selectedValues={this.props.filterSelections.location}
											selections={this.state.locations.map(item => item.value)}
											disabled={this.state.noLocations}

										/>
										{this.state.noLocations && <p style={{ color: "#AE1C1C", paddingLeft: '5px' }}>No options available.</p>}

									</fieldset>


								</div>
								<div class="ds-l-col">

									<fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Stakeholder Filters</legend>
										<hr aria-hidden="true" />
										<Choice
											label="Submitter Organization Found"
											name="checkbox_submitter_org"
											type="checkbox"
											checked={this.props.filterSelections.submitterOrgFound}
											onChange={e => this.setSubmitterOrgFound(e)}
											id="submitter-organization-checkbox"

										// onChange={}
										></Choice>
									</fieldset>

									<fieldset>

										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Stakeholder Type</legend>

										<MultipleSelectChip selectedValues={this.props.filterSelections.stakeholder} resetFlag={this.state.resetFlag} label="Select Stakeholder Type(s)" handleChange={this.selectStakeholder} selections={this.state.stakeholders.map(item => item.value)}></MultipleSelectChip>
										{this.state.noStakeholders && <p style={{ color: "#AE1C1C", paddingLeft: '5px' }}>No options available.</p>}

									</fieldset>


									<fieldset>

										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Setting of Care</legend>

										<MultipleSelectChip selectedValues={this.props.filterSelections.settingOfCare} resetFlag={this.state.resetFlag} label="Select Setting(s) of Care" handleChange={this.selectSettingofCare} selections={this.state.settingsOfCare.map(item => item.value)}></MultipleSelectChip>

										{this.state.noSettingsOfCare && <p style={{ color: "#AE1C1C", paddingLeft: '5px' }}>No options available.</p>}

									</fieldset>
									<fieldset>

										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Specialty</legend>

										<MultipleSelectChip selectedValues={this.props.filterSelections.specialty} resetFlag={this.state.resetFlag} label="Select Specialties" handleChange={this.selectSpecialty} selections={this.state.specialty.map(item => item.value)}></MultipleSelectChip>
										{this.state.noSpecialties && <p style={{ color: "#AE1C1C", paddingLeft: '5px' }}>No options available.</p>}

									</fieldset>
									<fieldset>

										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Role</legend>
										<MultipleSelectChip selectedValues={this.props.filterSelections.role} resetFlag={this.state.resetFlag} label="Select Role(s)" handleChange={this.selectRole} selections={this.state.role.map(item => item.value)}></MultipleSelectChip>
										{this.state.noRoles && <p style={{ color: "#AE1C1C", paddingLeft: '5px' }}>No options available.</p>}

									</fieldset>
									{/* <FilterSelect
											placeholder={''}
											selection={this.props.filterSelections.role}
											selectItem={this.selectRole}
											isMulti
											selections={this.state.role}
											label="Role"
											ariaLabel="Role"
											id="role-select"


										/> */}


								</div>

								<div class="ds-l-col">

									<fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Recommendation/Clarification Filters</legend>
										<hr aria-hidden="true" />

										<Choice
											label="Recommendation Instances Found"
											name="checkbox_rec_instances"
											type="checkbox"
											checked={this.props.filterSelections.recInstancesFound}
											onChange={e => this.setRecInstancesFound(e)}
											id="recommendation-instances-found-checkbox"
										></Choice>
										<Choice
											label="Clarification Instances Found"
											name="checkbox_clarification"
											type="checkbox"
											checked={this.props.filterSelections.clarInstancesFound}
											onChange={e => this.setClarInstancesFound(e)}
											// onChange={}
											id="clarifications-instances-found-checkbox"

										></Choice>

									</fieldset>
									<fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Sentiment Filters</legend>

										<hr aria-hidden="true" />

										<Choice
											label="Agreement Instances Found"
											name="checkbox_agr_instances"
											type="checkbox"
											checked={this.props.filterSelections.agreementInstancesFound}
											onChange={e => this.setAgrInstancesFound(e)}
											// onChange={}
											id="agreement-instances-found-checkbox"

										></Choice>
										<Choice
											label="Disagreement Instances Found"
											name="checkbox_disagree"
											type="checkbox"
											checked={this.props.filterSelections.disagreementInstancesFound}
											onChange={e => this.setDisagrInstancesFound(e)}
											// onChange={}
											id="disagreement-instances-found-checkbox"

										></Choice>
									</fieldset>
									<fieldset>
										<legend style={{ display: 'block', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>Themes Filters</legend>
										<hr aria-hidden="true" />
										<MultipleSelectChip selectedValues={this.props.filterSelections.theme} resetFlag={this.state.resetFlag} label="Select Theme(s)" handleChange={this.selectTheme} selections={this.state.themes.map(item => item.value)}></MultipleSelectChip>
										{this.state.noThemes && <p style={{ color: "#AE1C1C", paddingLeft: '5px' }}>No options available.</p>}

										{/* <FilterSelect
											placeholder={''}
											selection={this.props.filterSelections.theme}
											isMulti
											selectItem={this.selectTheme}
											selections={this.state.themes}
											ariaLabel="Themes Filter"
											label="Themes"
											id="themes-select"


										/> */}
									</fieldset>
								</div>
							</div>
							<div style={{ marginTop: '1%', paddingLeft: '16px' }} class="ds-l-row">

								<Button
									style={{ fontWeight: "700" }}
									className='comments_button_primary'
									children="Apply"
									id="apply filters button"
									aria-label='Apply and Hide Filters'
									onClick={this.closeFilterBoxandApplyFilters}
								/>
								{/* <Button 
										children='Clear'
										onClick={() => this.resetFilters()}
										className='comments_button_primary_outline'
										// aria-controls="Reset-filters-except-keyword"
										aria-label="Reset filters except keyword"
										style={{marginLeft: '16px'}}
										id="clear-active-filters"
									>
										Reset Filters
										<svg  aria-hidden="true" style={{cursor: "pointer", marginLeft: '4px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
												<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
												<path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
										</svg>
									</Button> */}
							</div>
						</section>
					</div>
				</Collapse>

				<ActiveFilters

					filters={this.props.filters}
					hasFilters={this.hasFilters}
					clearFiltersNoKeywords={this.props.clearFiltersNoKeywords}
				/>
				<Form className="form-left">
					<Row className="pb-3">


					</Row>
					<Row className="pb-3 align-items-center">

						<Col md={3} style={{ marginLeft: "10px" }}>
							<Form.Label><p style={{ fontWeight: 700, margin: 0 }}>Keyword Search</p></Form.Label>

							<Form.Control
								style={{ borderRadius: 0, outline: '1px solid #1B1B1B' }}
								id="keyword-search"
								key={this.props.filters.text}
								type="text"
								ref={this.filterText}
								defaultValue={this.props.filters.text}
								placeholder="Keyword/Phrase"
								onKeyPress={event => {
									if (event.key === 'Enter') {
										this.props.setFilters({
											text: this.filterText.current.value,
											category: this.props.filterSelections.category,
											stakeholder: this.props.filterSelections.stakeholder,
											settingOfCare: this.props.filterSelections.settingOfCare,
											location: this.props.filterSelections.location,
											theme: this.props.filterSelections.theme,
											topic: this.props.filterSelections.topic,
											cid: this.cid.current.value,
											specialty: this.props.filterSelections.specialty,
											role: this.props.filterSelections.role,
											docId: this.props.filterSelections.docId,
											ignoreDupes: this.props.filterSelections.ignoreDupes,
											submitterOrgFound: this.props.filterSelections.submitterOrgFound,
											recInstancesFound: this.props.filterSelections.recInstancesFound,
											clarInstancesFound: this.props.filterSelections.clarInstancesFound,
											agreementInstancesFound: this.props.filterSelections.agreementInstancesFound,
											disagreementInstancesFound: this.props.filterSelections.disagreementInstancesFound,
										});
									}
								}}
								aria-label="Keyword search"
							/>

						</Col>
						<Col md={1} >
							<Form.Label><p style={{ fontWeight: 700, margin: 0 }}>ID </p></Form.Label>

							<Form.Control className="form-cid"
								style={{ borderRadius: 0, outline: '1px solid #1B1B1B' }}

								key={this.props.filters.cid}
								id="comment-id-search"
								type="text" ref={this.cid} defaultValue={this.props.filters.cid}
								placeholder="ID (0000)"
								onKeyPress={event => {
									if (event.key === 'Enter') {
										console.log('8')

										this.props.setFilters({
											text: this.filterText.current.value,
											category: this.props.filterSelections.category,
											stakeholder: this.props.filterSelections.stakeholder,
											settingOfCare: this.props.filterSelections.settingOfCare,
											location: this.props.filterSelections.location,
											specialty: this.props.filterSelections.specialty,
											role: this.props.filterSelections.role,
											theme: this.props.filterSelections.theme,
											topic: this.props.filterSelections.topic,
											cid: this.cid.current.value,
											docId: this.props.filterSelections.docId,
											ignoreDupes: this.props.filterSelections.ignoreDupes,
										})
									}
								}}
								aria-label="ID search"
							/>
						</Col>
						<Col style={{ paddingLeft: "10px", paddingTop: '30px' }}>

							<Button
								style={{ fontWeight: "700" }}
								className='comments_button_primary'
								id="apply-keywords-button"
								size="small" children="Apply" variation="primary"
								children="Apply"
								variation="primary"
								aria-label='apply text filters'
								onClick={() => this.applyTextFilters()}


							>Apply</Button>
							{/* <Button size="small" children="Apply" onClick={() => this.addKeyword(this.filterText.current.value)}></Button> */}

							<Button
								children='Clear'
								onClick={() => this.clearKeyword()}
								className='comments_button_primary_outline'
								// aria-controls="Reset-keyword-filters"
								aria-label="Clear keyword filters"
								style={{ marginLeft: '16px' }}
								id="clear-keyword-filters"
							>
								Clear Text
								<svg aria-hidden="true" style={{ cursor: "pointer", marginLeft: '4px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
									<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
									<path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
								</svg>
							</Button>
						</Col>
					</Row>
					<Row style={{ marginLeft: '10px', margin: 0 }} >
						<ActiveKeywords
							filters={this.props.filters}
							hasFilters={this.hasFilters}
							clearKeyword={this.clearKeyword}
						/>
					</Row>

					<Pager
						filtered={this.hasFilters()}
						changePage={this.props.changePage}
						page={this.props.page}
						count={this.props.count}
						countLoaded={this.props.countLoaded}
					/>
				</Form>



			</>
		);
	}
}

export default FiltersBox;