import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Toaster, toast } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import "./FASApp.css";
import "./508_compliant/design-system/dist/css/index.css";
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import md5 from 'md5';
import DocketSelect from "./components/docketSelect/DocketSelect"
import Comments from "./components/commentList/Comments";
import CommentHeader from "./components/commentDetail/CommentHeader";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import QuickSight from "./components/quicksight/QuickSight";
import Similarity from "./components/quicksight/dashboards/Similarity";
import Recommendations from "./components/quicksight/dashboards/Recommendations";
import MySelections from './components/mySelections/MySelections';
import Documentation from './components/documentation/Documentation';
import MyAnnotations from './components/myAnnotations/MyAnnotations';
import SkipNavigationLink from './SkipNavigationLink';
class FASApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toastId: null,
            commentId: '',
            attNum: 0,
            docketId: '',
            docketTitle: '',
            office:'',
            Id: '',
            comments: [],
            count: 0,
            previousComment: '',
            next: '',
            activeTab: 'commentList',
            access_token: '',
            sortOption: {
                col: 'cidn',
                dir: 'asc',
            },
            filters: {
                text: '',
                category: [],
                topic: [],
                cid: '',
                docId: [],
                ignoreDupes: false,
                submitterOrgFound: false,
                recInstancesFound: false,
                clarInstancesFound: false,
                agreementInstancesFound: false,
                disagreementInstancesFound: false,
                theme: [],
                settingOfCare: [],
                role: [],
                specialty: [],
                submitterNames: [],
                stakeholder: []


            },
            filterSelections: {
                category: [],
                text: '',
                cid: '',
                ignoreDupes: false,
                docId: [],
                topic: [],
                submitterOrgFound: false,
                recInstancesFound: false,
                clarInstancesFound: false,
                agreementInstancesFound: false,
                disagreementInstancesFound: false,
                location: [],
                theme: [],
                settingOfCare: [],
                role: [],
                specialty: [],
                submitterNames: [],
                stakeholder: []



            },
            page: 0,
            commentChain: {},
            ariaLiveMessage: '',

        }

        /* 
        capture machine name to ensure proper login and out routing.
        */
        this.state.machine = new URL(window.location.href).origin;
    }




    regexCommentId = new RegExp(/(\d+)[^\d]*$/);
    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    truncateCommentId(id) {
        let res = ''

        if (id !== undefined) {
            res = id.match(/(\d+)[^\d]*$/g);
            return res
        } else {
            return
        }
    }

    handleNextClick = () => {
        this.setState({ commentId: this.state.next });
    }

    handlePrevClick = () => {
        let cid = this.getPreviousComment(this.commentChain, this.state.commentId);
        let nextCid = this.state.commentId;
        let prevCid = this.getPreviousComment(this.commentChain, cid)
        this.setState({ previousComment: prevCid, next: nextCid, commentId: cid, attNum: 0 });
    }

    handleNextClick = () => {
        let prevCid = this.state.commentId;
        let cid = this.state.commentChain[this.state.commentId];
        let nextCid = this.state.commentChain[cid]
        this.setState({ commentId: cid, previousComment: prevCid, next: nextCid, attNum: 0 });
    }

    getPreviousComment(comments, id) {
        return this.getKeyByValue(this.state.commentChain, id)
    }

    setDocketId = (id, tab) => {
        // console.log("Inside set docket id" + id + tab)
        this.setState({
            docketId: id,
            activeTab: tab,
            page: 0,
        });
        this.setTab(tab)
        this.clearFilters();
        this.forceUpdate();
    }
    setDocketTitle = (title) => {
        this.setState({
            docketTitle: title,
        });
    }
    setCommentId = (id, tab, att) => {
        var previousComment = '';
        var next = '';
        this.setState({
            commentId: id,
            activeTab: tab,
            attNum: att

        });

        previousComment = this.getPreviousComment(this.commentChain, id);
        next = this.state.commentChain[id];
        this.setState({
            previousComment: previousComment,
            next: next
        });
    }

    nextComment = (id, tab) => {
        if (typeof this.state.commentChain[id] !== 'undefined') {
            var ncid = this.state.commentChain[id];
            this.setState({
                commentId: ncid,
            });
        } else {
            this.setState({
                activeTab: tab,
            });
        }
    }

    setChain = (comments) => {
        var chain = {}
        if (typeof comments !== 'undefined') {
            for (var i = 0; i < comments.length - 1; i++) {
                chain[comments[i].comment_id] = comments[i + 1].comment_id
            }
            this.setState({ commentChain: chain });
        }
    }
    setSort = (col, dir) => {
        this.setState({
            sortOption: {
                col,
                dir
            }
        })
    }
    setTab = (tab) => {
        // console.log("Setting tab " + tab)
        this.setState({
            activeTab: tab,
        });
    }
    setFilters = (flt) => {
        console.log('BADDDDDDDDDDDDDDDDDDD')
        this.setState({
            filters: flt,
            page: 0,
            commentId: '',
        })
    }

    setDupesFilter = (boolean) => {
        console.log(boolean)
        console.log(boolean)
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,  // Spread existing state
                ignoreDupes: boolean   // Update only ignoreDupes
            }
        }));
        this.setState(prevState => ({
            filterSelections: {
                ...prevState.filterSelections,  // Spread existing state
                ignoreDupes: boolean   // Update only ignoreDupes
            }
        }));
    }


    setFilterSelections = (flt) => {
        this.updateAriaLiveRegion('Filters applied successfully.');
        console.log(flt)
        this.setState({
            filterSelections: flt,
        })
        console.log(this.state.filters)
    }
    setAttNum = (num) => {
        this.setState({ attNum: num })
    }

    updateAriaLiveRegion = (message) => {
        this.setState({ ariaLiveMessage: message });
    };
    clearFilters = () => {
        
        this.setState({
            filters: {
                ignoreDupes: false,
                text: '',
                topic: [],
                theme: [],
                category: [],
                location: [],
                cid: '',
                docId: [],
                role: [],
                specialty: [],
                submitterOrgFound: false,
                recInstancesFound: false,
                clarInstancesFound: false,
                agreementInstancesFound: false,
                disagreementInstancesFound: false,
                submitterNames: [],
                stakeholder: [],
                settingOfCare: [],



            },
            filterSelections: {
                category: [],
                location: [],
                text: '',
                cid: '',
                ignoreDupes: false,
                docId: [],
                topic: [],
                theme: [],
                role: [],
                specialty: [],
                submitterOrgFound: false,
                recInstancesFound: false,
                clarInstancesFound: false,
                agreementInstancesFound: false,
                disagreementInstancesFound: false,
                submitterNames: [],
                stakeholder: [],
                settingOfCare: [],


            },
            commentId: '',
        })
        this.setPage(0);
        localStorage.removeItem('filters');
    }
    setPage = (pg) => {
        this.setState({
            page: pg,
        });
    }
    clearFiltersNoKeywords = () => {
        this.setState({
            filters: {
                text: this.state.filters.text,
                topic: [],
                theme: [],
                category: [],
                location: [],
                cid: '',
                docId: [],
                ignoreDupes: false,
                submitterOrgFound: false,
                recInstancesFound: false,
                clarInstancesFound: false,
                agreementInstancesFound: false,
                disagreementInstancesFound: false,
                role: [],
                specialty: [],
                submitterNames: [],
                stakeholder: [],
                settingOfCare: [],


            },
            filterSelections: {
                category: [],
                location: [],
                text: this.state.filters.text,
                cid: '',
                ignoreDupes: false,
                docId: [],
                topic: [],
                theme: [],
                submitterOrgFound: false,
                recInstancesFound: false,
                clarInstancesFound: false,
                agreementInstancesFound: false,
                disagreementInstancesFound: false,
                role: [],
                specialty: [],
                submitterNames: [],
                stakeholder: [],
                settingOfCare: [],


            },
            commentId: '',
        })
        this.setPage(0);
        localStorage.removeItem('filters');
    }

    componentDidMount() {

        this.setState({
            activeTab: "Docket Select",
        })

    }
    setOffice = (ev) => {
        this.setState({ office: ev });
    }
    logout = () => {
        this.setState({
            docketId: '',
            filters: {
                text: '',
                category: [],
                location: [],
                theme: [],
                topic: [],
                cid: '',
                docId: [],
                ignoreDupes: false,
                role: [],
                specialty: [],
                submitterNames: [],
                stakeholder: [],
                settingOfCare: [],


            },
            filterSelections: {
                category: [],
                location: [],
                theme: [],
                text: '',
                cid: '',
                ignoreDupes: false,
                docId: [],
                topic: [],
                role: [],
                specialty: [],
                submitterNames: [],
                stakeholder: [],
                settingOfCare: [],


            },
            activeTab: 'comments',
        });

        this.props.sharedLogout(200, 'Successful')
    }
    
    setWarned = (w) => {
        this.setState({ warned: w });
    }
    setSourceLink = (l) => {
        this.setState({ sourceLink: l === '' || l === 'nan' ? 'Not Available' : l });
    }
    setComments = (cmts) => {
        this.setState({ comments: cmts })
    }
    setCommentsCount = (cnt) => {
        this.setState({ count: cnt })
    }

    download = (dt) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                docketId: this.state.docketId,
                filters: JSON.stringify(this.state.filters),
                start: 0,
                stop: 0,
                sortOption: {
                    col: this.state.sortOption.col,
                    dir: this.state.sortOption.dir,
                },
                docketTitle: this.state.docketTitle,
                userhash: md5(this.props.user),
            })
        };
        this.setState({
            toastId: toast.loading("Download in progress. Please don't open a new site or close your browser.",
                { position: 'bottom-right' })
        })
        fetch('/api/' + this.state.office + '/exportCommentList', requestOptions)
            .then((response) => { return response.json() })
            .then((json) => {
                // console.log(`Export Signed URL: ${json.url}`)
                let a = document.createElement('a');
                a.href = json.url;
                a.download = json.key;
                a.click();
                toast.remove(this.state.toastId)
                this.setState({ toastId: null })
            });
    }

    render() {  
        // console.log("here are filterselections" + JSON.stringify(this.state.filterSelections))
        // console.log("STAKEHOLDERS HERE" + this.state.filterSelections.stakeholder)
        console.log(this.state.sortOption)
        console.log(this.state.filterSelections)
        console.log(this.state.filters)

        if (this.props.validated) {

            return (
                <Container className='mx-auto app-container fluid'>
                    <Toaster aria-label="Download in progress"
                        toastOptions={{
                            style: {
                                background: '#005EA2',
                                color: "#ffffff"
                            },
                        }}
                    />

                    <Router forceRefresh={true}>
                    <SkipNavigationLink />

                        <Header
                            application={this.props.application}
                            adminSwitchApplication={this.props.adminSwitchApplication}
                            admin={this.props.admin}
                            docketId={this.state.docketId}
                            office={this.props.office}
                            user={this.props.user}
                            setDocketId={this.setDocketId}
                            validated={this.props.validated}
                            setOffice={this.setOffice}
                            setWarned={this.setWarned}
                            logInOut={this.logout}
                            setTab={this.setTab}
                            activeTab={this.state.activeTab}
                            setDocketTitle={this.setDocketTitle}
                            docketTitle={this.state.docketTitle}
                            restricted={this.props.restricted}
                            filters={this.state.filters}
                            setFilters={this.setFilters}
                            clearFilters={this.clearFilters}
                            filterSelections={this.state.filterSelections}
                            setFilterSelections={this.setFilterSelections}
                            comments={this.state.comments}
                            setComments={this.setComments}
                            count={this.state.count}
                            setCount={this.setCommentsCount}
                            setChain={this.setChain}
                            page={this.state.page}
                            sortOption={this.state.sortOption}
                            setPage={this.setPage}
                            setCommentId={this.setCommentId}
                            setSort={this.setSort}
                            clearFiltersNoKeywords={this.clearFiltersNoKeywords}
                            commentId={this.state.commentId}
                            nextComment={this.nextComment}
                            sourceLink={this.state.sourceLink}
                            previousComment={this.state.previousComment}
                            handlePrevClick={this.handlePrevClick}
                            next={this.state.next}
                            handleNextClick={this.handleNextClick}
                            truncateCommentId={this.truncateCommentId}
                            setSourceLink={this.setSourceLink}
                            filterText={this.state.filters.text}
                            attNum={this.state.attNum}
                            setAttNum={this.setAttNum}
                            userDisplayName={this.props.userDisplayName}
                        />



                      
<main id="main-content">

                        <Routes>
                            <Route path="/" element={<DocketSelect
                                updateAriaLiveRegion={this.updateAriaLiveRegion}

                                admin={this.props.admin}
                                setDocketId={this.setDocketId}
                                setOffice={this.setOffice}
                                office={this.props.office}
                                docketId={this.state.docketId}
                                setDocketTitle={this.setDocketTitle}
                                docketTitle={this.state.docketTitle}
                                user={this.props.user}
                                restricted={this.props.restricted}
                            />} />
                            <Route path="/comment-list" element={this.state.docketId === '' ? <Navigate to="/" replace /> : <Comments
                                updateAriaLiveRegion={this.updateAriaLiveRegion}

                                docketId={this.state.docketId}
                                office={this.state.office}
                                user={this.props.user}
                                filters={this.state.filters}
                                setFilters={this.setFilters}
                                clearFilters={this.clearFilters}
                                filterSelections={this.state.filterSelections}
                                setFilterSelections={this.setFilterSelections}
                                download={this.download}
                                comments={this.state.comments}
                                setComments={this.setComments}
                                count={this.state.count}
                                setCount={this.setCommentsCount}
                                setChain={this.setChain}
                                page={this.state.page}
                                sortOption={this.state.sortOption}
                                setPage={this.setPage}
                                setCommentId={this.setCommentId}
                                setSort={this.setSort}
                                clearFiltersNoKeywords={this.clearFiltersNoKeywords}
                                docketTitle={this.state.docketTitle}
                                setDupesFilter={this.setDupesFilter} />} />
                            <Route path="/comment-details" element={this.state.docketId === '' ? <Navigate to="/" replace /> : <CommentHeader
                                updateAriaLiveRegion={this.updateAriaLiveRegion}

                                docketId={this.state.docketId}
                                commentId={this.state.commentId}
                                nextComment={this.nextComment}
                                sourceLink={this.state.sourceLink}
                                office={this.state.office}
                                user={this.props.user}
                                previousComment={this.state.previousComment}
                                handlePrevClick={this.handlePrevClick}
                                next={this.state.next}
                                handleNextClick={this.handleNextClick}
                                truncateCommentId={this.truncateCommentId}
                                setSourceLink={this.setSourceLink}
                                filterText={this.state.filters.text}
                                attNum={this.state.attNum}
                                setAttNum={this.setAttNum}
                                docketTitle={this.state.docketTitle}
                                comments={this.state.comments}
                            />} />

                            <Route path="/summary-view" element={<QuickSight
                            
                                filters={this.state.filters}
                                setFilters={this.setFilters}
                                docketId={this.state.docketId}
                                office={this.state.office}
                                label='Summary View'
                                board='management' />} />

                            <Route path="/similarity" element={<Similarity
                                filters={this.state.filters}
                                setFilters={this.setFilters}
                                docketId={this.state.docketId}
                                office={this.state.office}
                                label='Similarity'
                                board='similarity' />} />

                            <Route path="/recommendations" element={<Recommendations

                                filters={this.state.filters}
                                setFilters={this.setFilters}
                                docketId={this.state.docketId}
                                office={this.state.office}
                                label='Recommendations'
                                board='recommendations' />} />
                            <Route path="/my-selections" element={<MySelections
                                                            updateAriaLiveRegion={this.updateAriaLiveRegion}

                                user={this.props.user}
                                docketId={this.state.docketId}
                                office={this.state.office}
                                filters={this.state.filters}
                                docketTitle={this.state.docketTitle}
                                restricted={this.props.restricted}
                            />} />
                            <Route path="/my-annotations" element={<MyAnnotations
                                                            updateAriaLiveRegion={this.updateAriaLiveRegion}

                                user={this.props.user}
                                docketId={this.state.docketId}
                                office={this.state.office}
                                filters={this.state.filters}
                                docketTitle={this.state.docketTitle}
                                restricted={this.props.restricted}
                            />} />
                            <Route path="/documentation" element={<Documentation
                            ></Documentation>} />
                            <Route path="/" element={<Navigate replace to="/" />} />
                        </Routes>
                        </main>

                    </Router>
                    <Footer env={this.props.env} />

                </Container>
            );
        }

    };
}
export default FASApp;