import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select'
import Button from 'react-bootstrap/Button';


class FeedbackDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isMounted: false,
      options: [],
      value: null,
      lastSelectedValueExists: false,
      defaultOptions: [],
      userOptions: [],
      options: []
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    this.getData();
    // this.createOptionsList(this.props.entity);



  }

  handleRemove(event) {
    if (event.key === 'Enter') {
      // Simulate a click event on the ClearIndicator element
      event.currentTarget.click();
    }
  }


  getData = () => {
    Promise.all([this.fetchData1(), this.fetchData2()])
      .then(results => {
        // console.log(results)
        const [defaultOptions, userOptions] = results;
        const options = [...defaultOptions, ...userOptions];
        this.setState({ defaultOptions, userOptions, options });
        this.createOptionsList(this.props.entity);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  fetchData1() {
    return fetch('/api/' + this.props.office + '/' + this.props.entity + '/' + this.props.docketId)
      .then(response => response.json())
      .then(data => {
        // Manipulate the JSON data here
        const modifiedData = data.map(dict => dict[this.props.entity]);
        return modifiedData;
      })
      .catch(error => {
        console.error('Error fetching data 1:', error);
      });
  }

  fetchData2() {
    return fetch('/api/' + this.props.office + '/get_feedback_history/' + this.props.entity)
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data 2:', error);

      });


  }

  createOptionsList = (entity) => {

    // console.log('INSIDE CREATE OPTIONS LIST')

    // //Example Default Items for Stakeholder type
    // const defaultOptions = [
    //   { label: 'Advocacy', value: 1},
    //   { label: 'Health Professional Association / Medical Society', value: 2},
    //   { label: 'Industry', value: 3},
    //   { label: 'Not Available', value: 4},
    //   { label: 'Patient/Consumer', value: 5},
    //   { label: 'Provider', value: 6}
    // ]

    // //Pretend this data is from a fetch call
    // const userOptions = [
    //   {label: 'Default value 1', value: 7},
    //   {label: 'Default value 2', value: 8},
    //   {label: 'Default value 3', value: 9}
    // ]
    // console.log('HERE IS SELECTED VALUE' + this.props.selectedValue)
    if (this.props.selectedValue !== '') {
      // console.log('INSIDE SELECTED VALUE' + this.props.selectedValue)

      //this can preselect your old default value in the menu so the user can see what they last submitted
      const prevValue = { label: this.props.selectedValue, value: this.props.selectedValue }
      this.setState({
        value: prevValue,
        lastSelectedValueExists: true
      })
    }

    // const mergedOptionsArray = [...this.state.defaultOptions, ...this.state.userOptions];
    // console.log(mergedOptionsArray)
    // this.setState({options: mergedOptionsArray})

    let options = this.state.options
    let arr = options.map(x => x.toLowerCase());
    let deduplicatedArray = Array.from(new Set(arr));
    let opts = []
    deduplicatedArray.forEach((c) => {
      opts.push({ value: c, label: c });
    });
    // console.log(`Categories: ${JSON.stringify(opts)}`);
    // console.log(opts)

    // console.log(opts)
    this.setState({ options: opts })
    return opts;
  }

  createOption = (label) => ({
    //When a new dropdown option is created it uses this as a template to build out its dictionary. You can change out my example key and replace with what you need. 
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
    example: 'my value'
  });

  handleCreate = (inputValue) => {
    //this allows you to control what happens when someone clicks to create a dropdown item.
    // console.log(`Creating option for ${inputValue}`)
    this.setState({ isLoading: true });
    const newOption = this.createOption(inputValue);
    // console.log("NEW OPTIONS" +  JSON.stringify(newOption))

    let options = this.state.options
    options.push(newOption)
    // let options = [...this.state.options, newOption]
    // console.log("UPDATED OPTS" + JSON.stringify(options))

    this.setState({
      isLoading: false,
      value: newOption,
      options: options
    },
      () => { console.log("NEW OPTIONS" + JSON.stringify(this.state.options)) });
    //   console.log(`New option created for ${inputValue}`)

  };

  handleValueChange = (existingValue, actionMeta) => {
    // console.log(actionMeta.action)
    if (actionMeta.action === 'select-option') {
      this.setState({ value: existingValue })
      //   console.log(`${existingValue} was selected.`)
    }
    if (actionMeta.action === 'clear') {
      //   console.log(`${actionMeta.removedValues[0].label} was deselected.`)
      this.setState({ value: null })
      //   if(this.props.selectedValue !== '') {
      //     console.log("Selected Value exists")
      //     this.props.handleShow(this.props.entity)
      //   }

    }
  }

  formatCreateLabel = (inputValue) => {
    //This can be used to change what the dropdown item will show when a user types an option.label that does not exist yet.
    return (
      <div>
        <strong>Create "{inputValue}"</strong>
      </div>
    );
  };

  ClearIndicator = (props) => {
    const { innerProps } = props;
    return (
      <components.ClearIndicator {...props}>
        {/* Use a button element with an onClick event */}
        <button
          type="button"
          className="invisible-button-filter"
          aria-label="Remove all selections"
          tabIndex={0}
          onClick={props.clearValue}
          onKeyPress={this.handleRemove}
          {...innerProps}
        >
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-8mmkcg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
        </button>
      </components.ClearIndicator>
    );
  };

  render() {
    const selectStyle = {
      marginRight: "10px",
      width: "100%"

    };
    const { isLoading, options, value } = this.state;
    const customStyles = {
      menu: (provided) => ({
        ...provided,
      }),
    };
    const flexContainerStyle = {
      display: "flex",
      alignItems: "center"
    };


    // console.log(this.state.options)
    // console.log(this.state.value)
    // console.log("Logging selected value" + this.props.selectedValue)
    // console.log(this.state.lastSelectedValueExists)


    let placeholder = ''
    return (
      <div style={flexContainerStyle}
      >
        <CreatableSelect
          placeholder={this.state.lastSelectedValueExists ? null : placeholder}
          value={value}
          //Documentation for props found here: https://react-select.com/props#select-props

          //This disabled the dropdown while data is reloading the dropdown options
          allowCreateWhileLoading={false}

          //This move the position of the create dropdown item to the last or first item.
          createOptionPosition="last"

          //onChange is used for existing values and any value (new or existing) deselection.
          onChange={(existingValue, actionMeta) => this.handleValueChange(existingValue, actionMeta)}

          //onCreateOption is only used for new value creation.
          onCreateOption={this.handleCreate}

          //formatCreateLabel lets you defined what the create dropdown item looks like before a user hits create.
          formatCreateLabel={this.formatCreateLabel}

          isSearchable={true}
          isClearable={true}
          isDisabled={this.props.disabled}
          // isLoading={isLoading}
          options={options}
          // value={value}
          // styles={customStyles}
          styles={{ container: (provided) => ({ ...provided, ...selectStyle }) }}
          components={{

            ClearIndicator: this.ClearIndicator
            ,
          }}

        />
        <Button
          aria-label="Submit feedback"
          disabled={this.props.is_correction === 'false' ? false : true}
          variant="outline-secondary" id={this.props.entity + "apply"}
          onClick={() => this.props.submit_correct_feedback(this.props.entity.toLowerCase(), value.value.toLowerCase())}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              this.props.submit_correct_feedback(this.props.entity.toLowerCase(), value.value.toLowerCase())
            }
          }}
        >
          Go
        </Button>
      </div>
    );
  }
}

export default FeedbackDropdown;