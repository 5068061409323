import React, { Component } from "react";
import { SortAlphaDown, SortAlphaUp } from 'react-bootstrap-icons';

class SortControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order // Initialize order state from props
    };
  }

  // Function to toggle the sorting order
  toggleOrder = () => {
    const newOrder = this.state.order === "asc" ? "desc" : "asc";
    this.setState({ order: newOrder }, () => {
      this.props.onOrderChange(newOrder); // Call the callback function with the new order
    });
  };

  render() {
    const { sortColumn, columnName } = this.props;
    const { order } = this.state;
    const svgSize = 20;
    const svgColor = "#000";

    // Determine the ARIA sort value based on the current sorting status
    let ariaSortValue = "ascending";
    if (sortColumn === columnName) {
      ariaSortValue = order === "asc" ? "ascending" : "descending";
    }

    return (
      <button
        className="d-inline-block btn btn-link pager-arrow"
        aria-label={`Sort in ${order === "asc" ? "descending" : "ascending"} order by ${columnName}`}
        // aria-sort={ariaSortValue} // Set the aria-sort attribute
        onClick={this.toggleOrder} // Use the toggleOrder function
        style={{ color: svgColor }}
      >
        {order === "asc" ? <SortAlphaUp size={svgSize} /> : <SortAlphaDown size={svgSize} />}
      </button>
    );
  }
}

export default SortControl;
