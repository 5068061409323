import React, { Component } from 'react';
import { exportToExcel } from 'react-json-to-excel';
import { Button } from '@cmsgov/design-system';


class DownloadReviewed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoaded: false,
        };

    }
    componentDidUpdate(pp, ps) {
        if (this.props.docketId !== pp.docketId || this.props.docketTitle !== pp.docketTitle || this.props.reviewCount !== pp.reviewCount) {
            this.downloadReviewed()
            // console.log("component updated")

        }
        // console.log(this.state.data)
    }
    componentDidMount() {

        this._isMounted = true;
    }
    downloadReviewed = () => {
        var commentIds = []
        var cmts = ''
        this.props.selections.map(
            (info) => {
                commentIds.push(info.comment_id)
            }
        )
        // console.log(commentIds)
        cmts = (JSON.stringify(commentIds)).slice(1, -1).replace(/"/g, '\'')
        // console.log(cmts)

        /* Now retrieve all comment info of those comment IDS 
        Comment ID, Att #, comment */
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                docketId: this.props.docketId,
                commentIds: cmts,
                docketTitle: this.props.docketTitle,
                reviewCount: this.props.reviewCount

            })
        };
        fetch('/api/' + this.props.office + '/exportReviewed', requestOptions)
            .then((response) => { return response.json() })
            .then((json) => {
                if (this._isMounted) {
                    // console.log("Fetching data" + json)
                    this.setState({
                        data: json,
                        isLoaded: true
                    });
                }
            }
            );




    }

    render() {
        // console.log(this.props.selections)
        // console.log("rendering state data" + this.state.data)
        var currentdate = new Date();
        var datetime = currentdate.getMonth() + 1 + "-"
            + (currentdate.getDate()) + "-"
            + currentdate.getFullYear()

        // console.log(this.props.docketTitle)
        // console.log(this.props.reviewCount)

        return (
            <>

                <Button
                    className='comments_button_primary'
                    aria-label="Download reviewed attachments to excel file"
                    disabled={!this.state.isLoaded}
                    onClick={() => exportToExcel(this.state.data, datetime)}
                    style={{ float: "right", cursor: "pointer" }}
                >
                    Download
                    <svg
                        style={{ cursor: "pointer", paddingLeft: '4px' }}
                        aria-hidden="true"
                        className="ds-c-icon ds-c-icon--download"
                        focusable="false"
                        id="icon-14"
                        viewBox="0 0 15 19"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.6 17.657V16h12.8v3.2H1.6v-1.543zM0 5.337l1.09-1.09L2.178 3.2l2.084 2.043L6.4 7.2V0h3.2V7.2l1.892-1.973C12.624 4.095 13.589 3.2 13.6 3.2c.011 0 .48.457 1.076 1.053l1.084 1.084-3.93 3.93a532.294 532.294 0 01-3.95 3.929c-.011 0-1.79-1.768-3.95-3.93L0 5.336z"
                            fillRule="evenodd">
                        </path>

                    </svg>
                </Button>


            </>
        )

    };
}
export default DownloadReviewed;