import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
  
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			machine: '',
		};
	}

	componentDidMount() {
		fetch('/api/machine')
			.then((response) => { return response.json() })
			.then((json) => { this.setState({ machine: json.machine }) })
			document.title = 'Login'; 

	}
	
	render() {
		
			return (
				<div>
					<Row>
						<Col className=' title ds-text-heading--3xl' style={{ marginBottom: '0px' }}><title>Feedback Analysis System</title></Col>
						<Col className='title' md={2} />
					</Row>
					<Row>
						<Col className='title'></Col>
						<Col className='title pb-3' md={2}>
							<div className="form-right">
								<Button className="btn btn-secondary btn-sm" style={{borderRadius: '0.5rem', paddingTop: '7px', paddingBottom: '6px', backgroundColor: "#005EA2", cursor: 'pointer'}} onClick={() => this.props.loginHarporCognito()}>Login</Button>
							</div>
						</Col>
					</Row>
				</div>
			)
		}
	}

export default Header;