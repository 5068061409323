import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import FASApp from '../FAS/FASApp';
import QSOGApp from '../QSOG/QSOGApp';
import LoginHeader from './components/LoginHeader'
import Footer from "../FAS/components/footer/Footer";
import IdleTimeout from './components/IdleTimeout';
import WarningBanner from "./components/WarningBanner";
import IssueCollector from './components/IssueCollector';
import "../FAS/508_compliant/design-system/dist/css/index.css";

import Cookies from 'js-cookie';
import { formControlClasses } from '@mui/material';

class EntryApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            env: '',
            loginUrl: '',
            logoutUrl: '',
            qsog: false,
            restricted: false,
            admin: false,
            application: 'fas',
            user: '',
            validated: false,
            office: '',
            warned: false,
            ddbConfigurations_IdleTimeout: [],
            sessionID: '',
            isWarningBannerVisible: true,
        }

        /* 
        capture machine name to ensure proper login and out routing.
        */
        this.state.machine = new URL(window.location.href).origin;
    }        

    componentDidMount() {
        let isLoggedIn = JSON.parse(sessionStorage.getItem('harploggedin'));

        if (isLoggedIn === null) {
            isLoggedIn = false;
            sessionStorage.setItem('harploggedin', JSON.stringify(isLoggedIn));
        }

        if (Cookies.get('idP_validated')){
            //User has been logged in with idP.
            sessionStorage.setItem('harploggedin', JSON.stringify(true));
            this.setState({isWarningBannerVisible: false})
            this.setApplicationLevelValues();
            sessionStorage.setItem('sessionStartTime', Date.now());
            Cookies.remove('idP_validated');
            this.fetchDDBConfiguration();

        }
        else if (!Cookies.get('idP_validated') && isLoggedIn === false){
            console.log('Logged needed.')
            console.log('Fetching Login...')

            this.fetchLoginURL();
            console.log('Login Fetched...')

            fetch('/api/environment')
            .then((response) => { return response.json() })
            .then((json) => {
                this.setState({ env: json.env })       
            });
            sessionStorage.setItem('harploggedin', JSON.stringify(false));

        }    
        else if(!Cookies.get('idP_validated') && isLoggedIn === true){
            this.setState({isWarningBannerVisible: false})
            this.setApplicationLevelValues();
            this.fetchDDBConfiguration();
        }   
    }

    fetchLoginURL = () => {
        let opts = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                machine: this.state.machine,
            })
        }
        fetch(`/auth/loginUrl`, opts)
            .then((response) => { return response.json() })
            .then((json) => {
                console.log(json)
                this.setState({
                    loginUrl: json.login,
                    logoutUrl: '',
                    user: '',
                    email: '',
                    office: '',
                    restricted: '',
                    validated: false,
                    access_token: null,

                })
            });
    }

    loginHarporCognito = () => {
        window.open(this.state.loginUrl, '_self');
    }

   
    setApplicationLevelValues = () => {
        let user = Cookies.get('id');
        let atk = Cookies.get('access_token');
        let email = Cookies.get('email');
        let group = Cookies.get('group');
        let restricted = Cookies.get('restricted');
        let logoutUrl = Cookies.get('logout');
        let qsog = Cookies.get('qsog');
        let admin = Cookies.get('admin');
        let sessionID = Cookies.get('sessionID')

        function parseBoolean(value) {
            return value === 'true';
        }
        
        qsog = parseBoolean(qsog);
        restricted = parseBoolean(restricted);
        admin = parseBoolean(admin);

        this.setState({
            qsog: qsog,
            user: user,
            email: email,
            office: group,
            restricted: restricted,
            validated: true,
            logoutUrl: logoutUrl,
            access_token: atk,
            admin: admin,
            application: qsog ? 'qsog' : 'fas',
            sessionID: sessionID
        });
    }

    fetchDDBConfiguration = () => {

        const component = "idle_timeout"
    
        fetch(`/api/${component}/getDDBConfigurations`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
                this.setState({ ddbConfigurations_IdleTimeout: data });
                console.log('DDBCONFIGURATIONS WAS SUCCESSFUL')
                console.log(data[0].forceLogout);
                let forceLogout = data[0].forceLogout
                if(forceLogout === true){
                    this.sharedLogout();
                }   
        })
        .catch(error => {
            console.error('Failed to fetch configuration:', error);
        });
    };

    calculateSessionDuration = (storedStartTime) => {
        const startTime = parseInt(storedStartTime, 10);
        const endTime = Date.now();
        const duration = endTime - startTime
        const durationInSeconds = Math.round(duration / 1000);
        return durationInSeconds;
    }

    sharedLogout = (statusCode, statusMessage) => {
        let logoutUrl = this.state.logoutUrl
        let durationInSeconds = this.calculateSessionDuration(sessionStorage.getItem('sessionStartTime'))
        const fName = Cookies.get('fname') ? Cookies.get('fname') : this.state.user
        const userRole = Cookies.get('userRole')
        let epochTime = Math.floor(Date.now() / 1000);

        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            event: 'Logout',
            statusCode: statusCode,
            name : fName,
            username: this.state.user,
            dateTimeUTC: new Date().toUTCString(),
            dateTimeEpoch: epochTime,
            duration: durationInSeconds,
            status: statusMessage,
            userRole: userRole,
            sessionID: this.state.sessionID,
        })
    };
    
    fetch("/auth/logout_log", requestOptions)
        .then((response) => {
            if (this._isMounted)
                return response.json()
        })
        .then((json) => {
            console.log(json)
            Cookies.remove('id');
            Cookies.remove('access_token');
            Cookies.remove('email');
            Cookies.remove('group');
            Cookies.remove('restricted');
            Cookies.remove('qsog');
            Cookies.remove('fname');
            Cookies.remove('admin');
            Cookies.remove('logout');
            Cookies.remove('sessionID');
            Cookies.remove('idP_validated');
            Cookies.remove('userRole')

            this.setState({
                user: '',
                office: '',
                email: '',
                access_token: null,
                validated: false,
                logoutUrl: '',
                loginUrl: '',
                warned: false,
                qsog: false,
            }, )
           
        });

        sessionStorage.setItem('harploggedin', JSON.stringify(false));
        sessionStorage.removeItem('sessionStartTime');
        this.setState({isWarningBannerVisible: true})
        this.fetchLoginURL();
        window.open(logoutUrl, '_self');
    }

    
    setWarned = (w) => {
        this.setState({ warned: w });
    }

 

    adminSwitchApplication = () => {
        //A switch for the application that admin users can activate to switch the application view.
        this.setState(prevState => ({
            application: prevState.application === 'qsog' ? 'fas' : 'qsog'
        }));
      
    }

    render() {
        let ddbWarningTime = this.state.ddbConfigurations_IdleTimeout.length !== 0 ? this.state.ddbConfigurations_IdleTimeout[0].maxInactiveTimeBeforeWarning : 25
        let ddbLogoutTime = this.state.ddbConfigurations_IdleTimeout.length !== 0 ? this.state.ddbConfigurations_IdleTimeout[0].maxInactiveTimeBeforeLogout : 30
        const userDisplayName = Cookies.get('fname') ? Cookies.get('fname') : Cookies.get('id');

        if (!this.state.isWarningBannerVisible) {
            const commonIdleTimeout = (
                <IdleTimeout
                  maxInactiveTimeBeforeWarning={ddbWarningTime} 
                  maxInactiveTimeBeforeLogout={ddbLogoutTime} 
                  ddbData={this.state.ddbConfigurations_IdleTimeout.length !==0 ? true : false}
                  onTimeout={this.sharedLogout}
                />
            );
            if (this.state.application === 'qsog') {
                return (
                    <>
                        {commonIdleTimeout}
                        <IssueCollector collectorId="58580a6f"/>
                        <QSOGApp 
                        sharedLogout={this.sharedLogout}
                        adminSwitchApplication={this.adminSwitchApplication}
                        
                        application={this.state.application}
                        admin={this.state.admin}
                        email={this.state.email}
                        env={this.state.env}
                        qsog={this.state.qsog}
                        user={this.state.user}
                        validated={this.state.validated}
                        userDisplayName={userDisplayName}
                    />
                    </>
                   
                )
            }
            else if (this.state.application === 'fas'){
                return (
                    <>
                        {commonIdleTimeout}
                        <IssueCollector collectorId="99068b97"/>
                        <FASApp 
                        adminSwitchApplication={this.adminSwitchApplication}
                        sharedLogout={this.sharedLogout}
                        application={this.state.application}
                        admin={this.state.admin}
                        email={this.state.email}
                        env={this.state.env}
                        office={this.state.office}
                        qsog={this.state.qsog}
                        restricted={this.state.restricted}
                        user={this.state.user}
                        validated={this.state.validated}
                        userDisplayName={userDisplayName}
                    />
                    </>
                );
            }
        } else if (this.state.isWarningBannerVisible) {
            return (
                <Container className='p-1'>
                    <LoginHeader
                        loginHarporCognito={this.loginHarporCognito} 
                        />
                    <WarningBanner 
                        warned={this.props.warned} 
                        setWarned={this.props.setWarned} 
                    />
                    <Footer env={this.state.env} />
                </Container>

                
            );
        }
    };
}
export default EntryApp;

