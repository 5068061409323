import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(name, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function MultipleSelectChip(props) {
  const theme = useTheme();
  // console.log("here are selectedValues in the select " + props.selectedValues)
  const [personName, setPersonName] = React.useState(Array.isArray(props.selectedValues) ? props.selectedValues : [props.selectedValues]);

  // const [personName, setPersonName] = React.useState([props.selectedValues]);
  // console.log("PERSON NAME" + props.label + personName)
  // useEffect(() => {
  //   // Reset the selected values when the resetFlag prop changes
  //   // console.log("RESET FLAG" + props.resetFlag)

  //   setPersonName([]);
  // }, [props.resetFlag]);

  useEffect(() => {
    // Reset the selected values when props.selectedValues is reset to []
    if (props.selectedValues.length === 0) {
      setPersonName([]);
    }
  }, [props.selectedValues]);
  const handleChange = (event) => {
    // console.log("EVENT" + event)
    props.handleChange(event.target.value)
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

  };

  return (
    <div >
      <FormControl sx={{ m: 1, width: '100%'}}>

        <InputLabel id="multiple-chip-label">{props.label}</InputLabel>
        <Select
          aria-label={props.label + " filter"}
          labelId="multiple-chip-label"
          
          id="multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          disabled={props.disabled} 
          style={props.disabled ? { backgroundColor: "#F0F0F0" } : {}}

        >
          {props.selections.map((name) => (
            <MenuItem
              key={name}
              value={name}
              // style={getStyles(name, theme)}
              
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}