import React, { Component } from 'react';

import CommentFlag from '../sharedComponents/CommentFlag';

import ReactTooltip from 'react-tooltip'; // Import ReactTooltip

import Card from 'react-bootstrap/Card';



class AttachmentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            keywordsAtts: [],
            tooltipVisible: false,

        };
    }
    _isMounted = false;
    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleTooltip = (visible) => {
        this.setState({ tooltipVisible: visible });

        console.log("toggling")
    };

    getData = () => {
        this._isMounted = true
        fetch('/api/' + this.props.office + '/attachmentParts/' + this.props.docketId + '/' + this.props.commentId)
            .then((response) => { return response.json() })
            .then((json) => {
                if (this._isMounted) {
                    this.setState({ comments: json });
                    // console.log(this.state.comments)
                    this.props.setSourceLink(json[0].link);
                }
            }
            );

        if (this.props.filterText !== '') {
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    group: this.props.office,
                    docketId: this.props.docketId,
                    keywords: this.props.filterText,
                })
            };
            fetch('/api/' + this.props.office + '/commentPartKeyword', requestOptions)
                .then((response) => {
                    if (this._isMounted)
                        return response.json()
                })
                .then((json) => {
                    this.setState({ keywordsAtts: json.Items });
                });
        }
    }
    componentDidMount() {
        if (this.props.office !== '' && this.props.commentId !== '')
            this.getData();
    }

    componentDidUpdate(pp, ps) {
        if (this.props.office !== '' && this.props.commentId !== ''
            && (pp.commentId !== this.props.commentId || pp.filterText !== this.props.filterText || pp.attNum !== this.props.attNum)) {
            this.getData(0);
        }

    }
    lineBreaks = (txt) => {
        if (typeof txt !== 'undefined')
            return txt.replaceAll('<br/>', '\n');
        else
            return txt;
    }

    handleKeyDown = (e, currentIndex) => {
        const { comments } = this.state;

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
                this.focusListItem(prevIndex);
                break;
            case 'ArrowDown':
                e.preventDefault();
                const nextIndex =
                    currentIndex < comments.length - 1 ? currentIndex + 1 : currentIndex;
                this.focusListItem(nextIndex);
                break;
            default:
                break;
        }
    };
    focusListItem = (index) => {
        // Programmatically focus the desired list item
        const listItem = document.querySelector(`li:nth-child(${index + 1})`);
        if (listItem) {
            listItem.focus();
        }
    };
    checkKeywords = (id, att) => {
        if (this.filterText === '') {
            return ''
        }
        var flag = false;
        for (let i = 0; i < this.state.keywordsAtts.length; i++) {
            if (JSON.stringify(this.state.keywordsAtts[i].comment_id) === JSON.stringify(id) && this.state.keywordsAtts[i].attachment_num === JSON.stringify(att)) {
                // console.log(this.state.keywordsAtts[i]);
                // console.log(JSON.stringify(this.state.keywordsAtts[i].comment_id) === JSON.stringify(id)) 
                // console.log(this.state.keywordsAtts[i].attachment_num === JSON.stringify(att));
                flag = true;
                return <><span className="sr-only">Keyword exists in this attachment.</span><svg style={{ color: "#0d6efd", padding: "5px" }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                </svg></>
                    ;
            }
        }
        return <><span style={{ padding: "20px" }}>
        </span><span className="sr-only">Keyword does not exist in this attachment.</span></>
    }

    checkKeywords2 = (id, att) => {
        if (this.props.filterText === '') {
            return null; // Return null if filterText is empty
        }

        for (let i = 0; i < this.state.keywordsAtts.length; i++) {
            if (JSON.stringify(this.state.keywordsAtts[i].comment_id) === JSON.stringify(id) && this.state.keywordsAtts[i].attachment_num === JSON.stringify(att)) {
                return true; // Return true if keywords exist in the attachment
            }
        }

        return false; // Return false if no keywords exist in the attachment
    }

    checkKeywordsAria = (id, att) => {
        console.log("checking keywords" + id)
        if (this.filterText === '') {
            return "nan"
        }
        for (let i = 0; i < this.state.keywordsAtts.length; i++) {
            if (JSON.stringify(this.state.keywordsAtts[i].comment_id) === JSON.stringify(id) && this.state.keywordsAtts[i].attachment_num === JSON.stringify(att)) {
                // console.log(this.state.keywordsAtts[i]);
                // console.log(JSON.stringify(this.state.keywordsAtts[i].comment_id) === JSON.stringify(id)) 
                // console.log(this.state.keywordsAtts[i].attachment_num === JSON.stringify(att));
                return "true"
                    ;
            }
        }
        return "false"
    }
    setPart = (an, len) => {
        this.props.setPart(an);
        this.props.setHasText(len > 1);
    }
    commaize = (i) => {
        return (typeof i !== 'undefined') ? i.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : '';
    }
    show = (cmts) => {
        if (typeof cmts !== 'undefined') {


            const items = cmts.map((cmt, index) => {
                let cls = 'row_' + (index % 2);

                let picked = (this.props.attNum.toString() === cmt.attachment_num.toString()) ? '#E1F3F8' : '';
                const hasKeywords = this.checkKeywords2(cmt.comment_id, cmt.attachment_num);



                return (
                    <>
                        <li
                            key={index}
                            style={{
                                listStyle: 'none',
                                padding: '3px',
                            }}
                            tabIndex={0}
                            onKeyDown={(e) => this.handleKeyDown(e, index)} // Handle keyboard navigation

                        >

                            <Card style={{ width: '7rem', backgroundColor: picked, paddingBottom: " 3px" }}>
                                <Card.Body style={{ padding: "4px" }}>
                                    <span

                                        data-tip="A dot '•' in this column means that the keywords searched for are present in this attachment."
                                        data-for={`keyword-tooltip-${cmt.attachment_num}`}
                                        style={{
                                            whiteSpace: "normal",
                                            visibility: this.props.filterText === '' ? "hidden" : hasKeywords ? "visible" : "hidden",                                        }}
                                    >
                                        {this.checkKeywords(cmt.comment_id, cmt.attachment_num)}

                                    </span>
                                    {/* <span className="sr-only">{this.calculateAriaLabel(cmt.commentId, cmt.attachment_num)}</span> */}
                                    <ReactTooltip
                                        id={`keyword-tooltip-${cmt.attachment_num}`}
                                        effect="solid"
                                        place="top"
                                        wrapper="span" // Wrap the tooltip content in a <span> for styling

                                    />


                                    <button onClick={() => this.setPart(cmt.attachment_num, cmt.len)}
                                        onKeyPress={(event) => { if (event.key === "Enter") { this.props.setPart(cmt.attachment_num) } }}
                                        style={{ padding: "5px" }}
                                        aria-label={"Attachment Number " + cmt.attachment_num + ". Pick attachment."} style={{
                                            border: 'none', // Remove button border
                                            background: 'none', // Remove button background
                                            padding: '0', // Remove button padding
                                            textDecoration: 'underline', // Add underline to make it look like a link
                                            cursor: 'pointer', // Change the cursor to indicate it's clickable
                                            color: 'blue', // Set the link color
                                            fontSize: "1em"

                                        }}
                                    >{cmt.attachment_num}</button>
                                    <span style={{ paddingLeft: "5px" }}>
                                        <CommentFlag

                                            nextComment={this.props.nextComment}
                                            user={this.props.user}
                                            docketId={this.props.docketId}
                                            office={this.props.office}
                                            label='Flagged?'
                                            commentId={this.props.commentId}
                                            attNum={cmt.attachment_num}
                                            comment={cmt.comment.substring(0, 300)} />

                                        <span style={{ paddingLeft: "2px" }}><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="16" height="16" fill="currentColor" class="bi bi-flag-fill" viewBox="0 0 16 16">
                                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                        </svg></span>
                                    </span>


                                </Card.Body>
                            </Card>




                        </li>



                    </>
                );
            });
            return items;
        }
    }

    render() {
        return (
            <>

                <p style={{ fontWeight: "bold", paddingLeft: "10px" }}>Attachments</p>
                <hr aria-hidden="true" style={{ backgroundColor: 'black', height: '5px', border: 'none', borderTop: '5px solid black', width: '120px' }} />
                <ul style={{ paddingLeft: "0px" }} aria-label="Attachments" >{this.show(this.state.comments)}</ul>



            </>
        );

    };
}
export default AttachmentTable;

