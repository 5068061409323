import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@cmsgov/design-system';



class Documentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
          releaseNotes: [],
    userManuals: [],
    machine: '',
      };
  }
  getData = () => {
    this._isMounted = true
    
    }
    componentDidMount() {
      fetch(`/api/machine`)
      .then((response) => { return response.json() })
      .then((json) => { this.setState({ machine: json.machine } )})
      this.getReleaseNotes();
      this.getUserManuals();
      // console.log(this.state.releaseNotes)
  
      // console.log(this.state.userManuals)
      document.title = 'Documentation'; 

  
  }
    getReleaseNotes = () => {
      this._isMounted = true;
    
      fetch(`/api/releaseNotes`)
        .then((response) => {
          if (this._isMounted)
            return response.json()
        })
        .then((json) => {
          if (this._isMounted) {
            var list = []
    
            // console.log(json)
            for (let i = 0; i < json.length; i++) {
              // console.log("Get release note keys  ");
              list.push(this.getReleaseNoteKey(json[i].Key))
              // console.log(list)
              this.setState({releaseNotes: list})
            }
          }
        }
      );
    }
  
    getReleaseNoteKey = (rn) => {
          // console.log("Logging release note" + rn)
          rn = rn.slice(18, -4);
          console.log(rn)
      return rn;
          
      }
  
    getUserManualKey = (um) => {
          um = um.slice(16, -4);
      return um;
          
      }
  
    getUserManuals = () => {
      this._isMounted = true;
    
      fetch(`/api/userManual`)
        .then((response) => {
          if (this._isMounted)
            return response.json()
        })
        .then((json) => {
          // console.log(json)
          if (this._isMounted) {
            var list = [];
            for (let i = 0; i < json.length; i++) {
              // console.log("Get user manual  keys  ");
              list.unshift(this.getUserManualKey(json[i].Key))
              // console.log(list)
              this.setState({userManuals: list})
            }
          }
        }
      );
    }
  // componentDidMount() {
  //   this.setPart(0);	
  //   this.getData();
  // }
  // componentDidUpdate(pp, ps) {
  //   if (pp.commentId !== this.props.commentId)
  //     this.setPart(0);	
  //   if (ps.attNum !== this.state.attNum) {
  //     this.setPart(this.state.attNum)
  //   }
  // }
    
    render() {


        return (
            <>
            <div className="tab-top">
                   <h1 className="docket-select-h1">Documentation</h1>  
                    <p>Below are the links to the documentation for the FAS Application</p>
                    <Row>
                        <Col>
                            <h3>User Manual</h3>
                            <p>Click on the link below to view the FAS User Manual</p>
         									<a href={`${this.state.machine}/api/getUserManual/${this.state.userManuals[0]}`} target="_blank" >Link to the FAS User Manual</a>
    		
                            
                        </Col> 
                        <Col>
                            <h3>Release Notes</h3>
                            <p>Click on a link below to view a FAS Release Note</p>
                            <div className="documentation">
                            <Table
                              stackable
                              stackableBreakpoint="md"
                              aria-label="Release Notes"
                            >

                              <TableHead>
                                <TableRow>
                                  <TableCell>Release Notes</TableCell>
                                
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {this.state.releaseNotes.map((item,index)=>{
                                                      return <TableRow> <TableCell key={`${item}-${index}`} ><a href={`${this.state.machine}/api/getReleaseNote/${this.state.releaseNotes[index]}`} target="_blank" >{item}</a></TableCell></TableRow>
                                                  })}
                              
                              </TableBody>
                            </Table>
                            </div>
                        </Col>
                    </Row>
                    </div>
            </>
        );
    };
}
export default Documentation;