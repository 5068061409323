import React, { Component } from 'react';
import Select, {components} from 'react-select'

class FilterSelect extends Component {
	CustomMultiValue = (props) => {
		const handleRemove = (e) => {
		  e.preventDefault();
		  e.stopPropagation();
		  this.props.removeSelectedItem(props.data);
		};
	
		return (
		  <div className="custom-multi-value">
			<div className="custom-multi-value-label">{props.data.label}</div>
			<div
			  className="custom-multi-value-remove"
			  onClick={handleRemove}
			  aria-label={`Clear filter: ${props.data.label}`}
			  tab-index="0"
			  role="button"
			>
			  &times;
			</div>
		  </div>
		);
	  };

	  handleRemove(event) {
		if (event.key === 'Enter') {
		  // Simulate a click event on the ClearIndicator element
		  event.currentTarget.click();
		}
	  }

	  ClearIndicator = (props) => {
		const { innerProps } = props;
		return (
		  <components.ClearIndicator {...props}>
			{/* Use a button element with an onClick event */}
			<button
			  type="button"
			  className="invisible-button-filter"
			  aria-label="Remove all selections"
			  tabIndex={0}
			  onClick={props.clearValue}
			  onKeyPress={this.handleRemove}
			  {...innerProps}
			>
			  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-8mmkcg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
			</button>
		  </components.ClearIndicator>
		);
	  };
	
    render() {
		  
		//console.log("this.props.selection:",this.props.selection)
		//console.log(`filter options: ${JSON.stringify(this.props.selections)}`);
    	if (this.props.selection !==null && typeof this.props.selection !== 'undefined' && this.props.selection !== '') {
		const colourStyles = {
			control: (provided,  { data, isDisabled, isFocused, isSelected }) => ({
				...provided,
				 '&:focus': {  boxShadow: '0 0 0 1px #02BFE7' }, // border style on 
			
				 borderColor: isFocused ? "#02BFE7" : 'none',
				 boxShadow: isFocused ? "0 0 0 1px #02BFE7" : 'none',
			//	 boxShadow: '0 0 0 1px #2684ff', // border style on hover
			  }),
			option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			  // const color = chroma(data.color);
			  return {
				...styles,
				backgroundColor: isFocused ? "#9BDAF1 !important" : null,
				color: "#333333",
				'&:hover': { backgroundColor: '#9BDAF1 !important' }, // border style on hover
				'&:active': {backgroundColor: '#9BDAF1 !important'},
				'&:focus':{backgroundColor:'#9BDAF1 !important'}
			  };
			}
		}			
			return (
				 <Select
				  	key={`${this.props.placeholder}_fs_+${this.props.selection}`}
					// className="custom-select"
				 	value={
						this.props.isMulti === true
							? this.props.selections.filter((option) => this.props.selection.includes(option.value)) 
							: {value: this.props.selection, label: this.props.selection}
					}
					onChange={this.props.selectItem}
					isMulti={this.props.isMulti || false}
					options={this.props.selections} 
					placeholder={this.props.placeholder}
					styles={colourStyles}
					onKeyPress={this.props.onKeyPress}
					aria-label={this.props.ariaLabel || this.props.placeholder}
					components={{
						MultiValueRemove: (props) => (
						  <button
							tabIndex="0"	
							id="remove-selection"
							className="invisible-button"
							aria-label={"Remove "+ this.props.selection }
							onClick={(e) => {
							  e.preventDefault();
							  props.innerProps.onClick(e);
							}}
						  >
							<components.MultiValueRemove {...props} />
						  </button>
						),
						ClearIndicator: this.ClearIndicator
						,
						


					  }}
				/>
			)
		} else {
			const colourStyles = {
				control: (provided,  { data, isDisabled, isFocused, isSelected }) => ({
					...provided,
					 '&:focus': {  boxShadow: '0 0 0 1px #02BFE7' }, // border style on 
				
					 borderColor: isFocused ? "#02BFE7" : 'none',
					 boxShadow: isFocused ? "0 0 0 1px #02BFE7" : 'none',
				//	 boxShadow: '0 0 0 1px #2684ff', // border style on hover
				  }),
				option: (styles, { data, isDisabled, isFocused, isSelected }) => {
				  // const color = chroma(data.color);
				  return {
					...styles,
					backgroundColor: isFocused ? "#9BDAF1" : null,
					
					color: "#333333",
					
				
				  };
				}
			}

			return (
				 <Select 
				 	className="custom-select"
					styles={colourStyles}
				 	key={`${this.props.placeholder}_fs_+${this.props.selection}`}
					placeholder={this.props.placeholder}
					isMulti={this.props.isMulti || false}
					onChange={this.props.selectItem}
					options={this.props.selections}
					aria-label={this.props.ariaLabel || this.props.placeholder}
					onKeyPress={this.props.onKeyPress}
					components={{
						MultiValueRemove: (props) => (
						  <button

							tabIndex="0"
							aria-label="Remove"
							onClick={(e) => {
							  e.preventDefault();
							  props.innerProps.onClick(e);
							}}
						  >
							<components.MultiValueRemove {...props} />
						  </button>
						),
						ClearIndicator: this.ClearIndicator
						,
					  }}
					/>
			)
		}
    }
}
export default FilterSelect;