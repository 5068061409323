import React, { Component } from "react";
import {Row, Col, Form} from "react-bootstrap";
import { CaretRight, CaretLeft } from 'react-bootstrap-icons';
import ReactTooltip from "react-tooltip";
import { Button } from "@cmsgov/design-system";

import './activeFilters.css'

class Pager extends Component {
	constructor(props) {
		super(props);
		this.pageText = React.createRef(); 
	}
	ps = () => {
		return " of "+Math.ceil(this.props.count/15)
	}

	commaize = (i) => {
		return i.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
	}
	changePage = (pg) => {
		var p=parseInt(pg)
		if (p!=='nan') {
			this.props.changePage(pg);
		}
		this.pageText.current.value='';
	}
	render() {
		const disableLowerBound = (this.props.page === 0)
		const disableUpperBound = ((this.props.page + 1)/(Math.floor(this.props.count/15)+1) === 1)

		if (Math.ceil(this.props.count/15)>=1) {
				return (
				<Row style={{float: "right"}}>

					<Col>
					<Button aria-label="Previous page of comments" hidden={disableLowerBound} onClick={() => this.props.changePage('-')} disabled={disableLowerBound}
						 >
					<CaretLeft hidden={disableLowerBound}
						className='pager-arrow' 
						/></Button>
						<button 
							type="button"
							id='prev'
							aria-label='previous page'	
							className='arrowstyling'
							disabled={disableLowerBound}
							style={{cursor: 'pointer'}}
							onClick={() => this.props.changePage('-')}
							tabIndex="-1"

						>						
						Previous 

						</button>
						&nbsp;<span data-tip data-for="goto-page" >
						<Form.Control 
							className="goto-box" 
							type="text" 
							ref={this.pageText} placeholder={this.props.page + 1}
							onKeyDown={event => {
								if (event.key === 'Enter') {
								  this.changePage(this.pageText.current.value);
								}
							}}
							aria-label="Go to. Type in page number."
							// disabled={disableUpperBound}
							/>
					</span>
					<span>
						
						
						
						{this.ps()}</span>
						<button 
							tabIndex='-1'
							type="button"
							id='next'
							aria-label='next page'	
							className='arrowstyling'
							disabled={disableUpperBound}
							style={{cursor: 'pointer'}}
							onClick={() => this.props.changePage('+')}
							
						>		 Next 
						</button>
						<Button aria-label="Next page of comments" onClick={() => this.props.changePage('+')} hidden={disableUpperBound}
					>
					<CaretRight className='pager-arrow' /></Button>
					
					<ReactTooltip id="goto-page" place="top" effect="solid">
					Enter a page number and press 'Enter' to go to that page. 
					</ReactTooltip>
					</Col>
				</Row>
			);
		} else {
			if(Math.ceil(this.props.count/15) === 0) {
				return (
					<Row style={{float: "right"}}>
	
						<Col>
						<Button aria-label="Previous page of comments" hidden={true} onClick={() => this.props.changePage('-')} disabled={true}
							 >
						<CaretLeft hidden={true}
							className='pager-arrow' 
							/></Button>
							<button 
								type="button"
								id='prev'
								aria-label='previous page'	
								className='arrowstyling'
								disabled={true}
								style={{cursor: 'pointer'}}
								onClick={() => this.props.changePage('-')}
								tabIndex="-1"
	
							>						
							Previous 
	
							</button>
							&nbsp;<span data-tip data-for="goto-page" >
							<Form.Control 
								className="goto-box" 
								type="text" 
								ref={this.pageText} placeholder={this.props.page + 1}
								onKeyDown={event => {
									if (event.key === 'Enter') {
									  this.changePage(this.pageText.current.value);
									}
								}}
								aria-label="Go to. Type in page number."
								disabled={true}
								/>
						</span>
						<span>
							
							
							
							{this.ps()}</span>
							<button 
								tabIndex='-1'
								type="button"
								id='next'
								aria-label='next page'	
								className='arrowstyling'
								disabled={true}
								style={{cursor: 'pointer'}}
								onClick={() => this.props.changePage('+')}
								
							>		 Next 
							</button>
							<Button aria-label="Next page of comments" onClick={() => this.props.changePage('+')} hidden={true}
						>
						<CaretRight className='pager-arrow' /></Button>
						
					
						</Col>
					</Row>
				);
			} else {
			return (
				<Row style={{float: "right"}}>
				
					<Col >
					<span id="page-label" >
						Loading Pagination Control...
						</span>
					</Col>
				</Row>
			);}
		}
	}
}

export default Pager;