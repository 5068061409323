import React, { Component } from 'react';

class SkipNavigationLink extends Component {
  render() {
    return (
      <a href="#main-content" className="skip-link">
        Skip to main content
      </a>
    );
  }
}

export default SkipNavigationLink;
