import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TableCell, TableRow } from '@cmsgov/design-system';
import { useNavigate } from 'react-router';

import CommentAccordion from './CommentAccordion';
import CommentReview from '../sharedComponents/CommentReview';
import FiltersBox from './FiltersBox';

const Comments = (props) => {
    const navigate = useNavigate()
    const accordionRef = useRef(null);
    const prevProps = useRef(null);
    const [ariaLiveMessage, setAriaLiveMessage] = useState('');


    const [countLoaded, setCountLoaded] = useState(false);

    let _isMounted = false;

    const [filtersApplied, setFiltersApplied] = useState(false);

const handleFiltersApplied = () => {
    setFiltersApplied(true);
};

const handleSetCountLoaded = (loaded) => {
    setCountLoaded(loaded);
};


const handleGetData = () => {
    console.log('testinginging')
    // Directly call getData on the CommentAccordion component
    if (accordionRef.current) {
        accordionRef.current.getData();
    }
};

useEffect(() => {
    if (filtersApplied) {
        // Assume getDataFromAccordion is the method you need to call in CommentAccordion
        // This could also be triggered by passing a prop to CommentAccordion
        console.log('testing')
        getDataFromAccordion();
        setFiltersApplied(false); // Reset the state if needed
    }
}, [filtersApplied]);

    // const getData = (col, dir) => {

    //     _isMounted = true;
    //     const start = props.page * 15 + 1;
    //     const stop = start + 15 - 1;
    //     col = col ? col : props.sortOption.col;
    //     dir = dir ? dir : props.sortOption.dir;

    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             docketId: props.docketId,
    //             filters: JSON.stringify(props.filters).replaceAll("'", "%27"),
    //             start: start,
    //             stop: stop,
    //             sortOption: {
    //                 col: col,
    //                 dir: dir,
    //             },
    //         })
    //     };
    //     fetch('/api/' + props.office + '/comments', requestOptions)
    //         .then((response) => {
    //             if (_isMounted)
    //                 return response.json();
    //         })
    //         .then((json) => {
    //             if (_isMounted)
    //                 props.setComments(json);
    //             props.setChain(json);
    //         });
    //     fetch('/api/' + props.office + '/count', requestOptions)
    //         .then((response) => {
    //             if (_isMounted)
    //                 return response.json();
    //         })
    //         .then((json) => {
    //             if (_isMounted)
    //                 props.setCount(json);
    //             setCountLoaded(true);
    //         });
    // };

    useEffect(() => {
        if (props.office !== '' && props.docketId !== '') {
            // getData();
        }
        return () => {
            _isMounted = false;
        };
    }, []);

    useEffect(() => {

        if (
            JSON.stringify(props.filters) !== JSON.stringify(prevProps.filters) ||
            props.page !== prevProps.page ||
            props.docketId !== prevProps.docketId ||
            JSON.stringify(prevState.sortOption) !== JSON.stringify(state.sortOption) ||
            props.sortOption !== prevProps.sortOption
        ) {
            // getData();
        }
    }, [props.filters, props.page, props.docketId, props.sortOption]);

    const changePage = (i) => {
        const cp =
            i === '-'
                ? props.page - 1
                : i === '+'
                    ? props.page + 1
                    : parseInt(i) - 1;
        const pages = Math.ceil(props.count / 15);
        if (cp >= 0 && cp < pages) {
            props.setPage(cp);
        }
    };

    const ids = (commentIds) => {
        return commentIds.map((cmt, index) => {
            const cls = 'row_' + (index % 2);
            return (
                <Row key={index} className={cls}>
                    <CommentReview
                        user={props.user}
                        office={props.office}
                        docketId={props.docketId}
                        commentId={cmt.comment_id}
                        comment={cmt.comment}
                    />
                    <Col
                        id={cmt.comment_id}
                        aria-label={'ID for ' + cmt.comment_id}
                        md="auto"
                        className="docket-id-list"
                        onKeyPress={(event) =>
                            event.key == 'Enter'
                                ? setCommentId(cmt.comment_id, 'Comment Details', 0)
                                : setCommentId(cmt.comment_id, 'Comment Details', 0)
                        }
                        onClick={() => setCommentId(cmt.comment_id, 'Comment Details', 0)}
                    >
                        {cmt.comment_number}
                    </Col>
                    <Col md={1}>
                        <center>{cmt.atts > 0 ? cmt.atts : ''}</center>
                    </Col>
                    <Col
                        onKeyPress={(event) =>
                            event.key == 'Enter'
                                ? setCommentId(cmt.comment_id, 'Comment Details', 0)
                                : setCommentId(cmt.comment_id, 'Comment Details', 0)
                        }
                        md={5}
                        className="docket-id-list"
                        onClick={() => setCommentId(cmt.comment_id, 'Comment Details', 0)}
                    >
                        {cmt.comment}
                    </Col>
                    <Col md={1}>
                        {cmt.primary_org}
                    </Col>
                    <Col md={1}>
                        {cmt.stakeholder_type}
                    </Col>
                    <Col md={1}>
                        {cmt.setting_of_care}
                    </Col>
                    <Col md={1}>
                        {cmt.specialty}
                    </Col>
                </Row>
            );
        });
    };
    const clearFilters = () => {
        props.setSort('cidn', 'asc');
        changePage(1);
        props.clearFilters();
    };

    const getPrimaryOrg = (word) => {
        let fixed = '';
        word === 'See Attachment' ? (fixed = '*See Attachment') : (fixed = word);
        word === 'Not Found' ? (fixed = '') : (fixed = word);
        return fixed;
    };

    const getStakeholder = (word) => {
        let fixed = '';
        word === 'Not Available' ? (fixed = '') : (fixed = word);
        return fixed;
    };

    const getSetting = (word) => {
        let fixed = '';
        word === 'Not Available' ? (fixed = '') : (fixed = word);
        return fixed;
    };

    const getSpecialty = (word) => {
        let fixed = '';
        word === 'Not Available' ? (fixed = '') : (fixed = word);
        return fixed;
    };

    const setCommentId = (id, label, att) => {
        props.setCommentId(id, label, att)

        navigate('/comment-details')
    };
    const updateAriaLiveRegion = (message) => {
        setAriaLiveMessage(message);
    };

    if (props.docketId !== '') {
        var space = "tab-bottom-commentlist"
        if (props.commentId !== '') {
            space += '-commentlist-more'
        }
        var space = "tab-bottom-commentlist"
        return (
            <>
                <div className="sr-only" aria-live="assertive" id="aria-live-region">
                    {ariaLiveMessage}
                </div>
                <div>
                    <Row className="tab-top">
                        <FiltersBox
                            updateAriaLiveRegion={updateAriaLiveRegion}
                            changePage={changePage}
                            page={props.page}
                            count={props.count}
                            setFilters={props.setFilters}
                            clearFilters={clearFilters}
                            filterSelections={props.filterSelections}
                            setFilterSelections={props.setFilterSelections}
                            office={props.office}
                            docketId={props.docketId}
                            docketTitle={props.docketTitle}
                            filters={props.filters}
                            countLoaded={countLoaded}
                            clearFiltersNoKeywords={props.clearFiltersNoKeywords}
                            onTriggerGetData={handleGetData}
                            setDupesFilter={props.setDupesFilter}                        />
                    </Row>
                    <div className={space}>
                        <CommentAccordion
                            docketId={props.docketId}
                            download={props.download}
                            office={props.office}
                            user={props.user}
                            filters={props.filters}
                            setFilters={props.setFilters}
                            clearFilters={props.clearFilters}
                            filterSelections={props.filterSelections}
                            setFilterSelections={props.setFilterSelections}
                            comments={props.comments}
                            setComments={props.setComments}
                            count={props.count}
                            setCount={props.setCount}
                            setChain={props.setChain}
                            page={props.page}
                            sortOption={props.sortOption}
                            setPage={props.setPage}
                            setCommentId={setCommentId}
                            commentId={props.commentId}
                            setSort={props.setSort}
                            ref={accordionRef}
                            setCountLoaded={handleSetCountLoaded}


                        />
                    </div>
                </div>
            </>
        );
    } else {
        return <></>;
    }
};

export default Comments;

