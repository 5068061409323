import React, { Component } from "react";
import DocketSelected from "../docketSelect/DocketSelected";

class TotalComments extends Component {
  constructor(props) {
    super(props);
    this.pageText = React.createRef();
  }
  componentDidMount() {
    // this.setPart(0);
    document.title = 'Comment List'; 

  }
  commaize = (i) => {
    return i.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  componentDidUpdate(prevProps) {
    // Check if the content of the <p> element has changed
    if (this.props.count !== prevProps.count) {
      // Create a message based on the change
      let message = this.props.count + " filtered comments"

      // Call the updateAriaLiveMessage function in the parent component
      this.props.updateAriaLiveRegion(message);
    }
  }

  render() {
    let loading = this.props.countLoaded ? this.commaize(this.props.count) : "Loading";
    let dots = this.props.countLoaded ? "" : "...";

    return (
      <>
        <div className="row">
          <div className="column">
            <h1
              style={{
                fontStyle: "normal",
                textAlign: "left",
                fontSize: "36px",
                fontWeight: "500",
                marginTop: "1.5%",
              }}
            >
              Comment List
            </h1>
          </div>
          <div className="column">
            <p
              id="total-comments"
              aria-label="loaded comments"
              style={{
                textAlign: "right",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {loading} {this.props.filtered ? "Filtered " : ""} Comments{dots}
            </p>
			<DocketSelected docketId={this.props.docketId} docketTitle={this.props.docketTitle}></DocketSelected>

          </div>
        </div>
      </>
    );
  }
}

export default TotalComments;
