import React, { Component } from 'react';

import DownloadReviewed from './DownloadReviewed';

import { Alert } from 'react-bootstrap';


class ReviewSelections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selections: [],
            ariaLiveMessage: ''
        };

    }
    setup() {
        fetch(`/api/reviewedSelections/${this.props.user}/${this.props.docketId}`)
            .then((response) => {
                if (this._isMounted)
                    return response.json()
            })
            .then((json) => {
                if (this._isMounted) {
                    this.setState({ selections: json.Items })
                    this.props.setReviewCount(json.Items.length)
                    if(json.Items.length===0) {
                        this.updateAriaLiveRegion("No reviewed selections for docket " + this.props.docketId)
                    }
                }
            });
        //   console.log(this.state.selections)

    }
    componentDidMount() {

        this._isMounted = true;
        this.setup()
    }

    componentDidUpdate(pp, ps) {
        if (pp.docketId !== this.props.docketId || JSON.stringify(ps.selections) !== JSON.stringify(this.state.selections)) {
            this.setup()
        }
    }
    updateAriaLiveRegion = (message) => {
        this.setState({ariaLiveMessage: message});
    };
    render() {

        const DisplayData = this.state.selections.map(
            (info) => {
                return (
                    <tr>
                        <td>{info.comment_id}</td>
                        <td>{info.comment}</td>
                    </tr>
                )
            }
        )
        if (this.state.selections.length === 0) {
            return (
                <><div className="sr-only" aria-live="assertive" id="aria-live-region">
                    {this.state.ariaLiveMessage}
                </div><div style={{ paddingTop: "10px" }}>

                        <Alert variant="info" style={{ backgroundColor: "#F4E3DB", borderColor: "red" }}>
                            <Alert.Heading><svg style={{ marginRight: "1%" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>No Reviewed Selections</Alert.Heading>
                            <p>
                                Currently you have no Reviewed selections for this docket.</p>
                        </Alert></div></>)

        } else {
            return (
                <>

                    <div>


                        <span style={{ fontWeight: "bold" }}><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                        </svg> Review | Below are the Comment IDs at attachment 0 that have been added to a Review group</span><DownloadReviewed tabIndex="0" docketTitle={this.props.docketTitle} reviewCount={this.props.reviewCount} filters={this.props.filters} selections={this.state.selections} docketId={this.props.docketId} office={this.props.office} />
                        <table className="table table-striped" role="presentation">
                            <col style={{ width: "15%" }}></col>
                            <col style={{ width: "85%" }}></col>
                            <thead>
                                <tr>
                                    <th>Comment ID</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DisplayData}
                            </tbody>
                        </table>
                    </div>
                </>
            )
        }
    };
}
export default ReviewSelections;