import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';

const OffCanvas = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    let levelTwo = "level-two"
    let levelThree = "level-three"

    const handleKeyPress = (e) => {

        if (e.key === 'Enter') {
            handleShow()
        }
    }
    return (
        <>
            <div

                style={{ position: 'relative', display: 'inline-block' }}
            >
                <button type="button" className="invisible-button" aria-label="Open navigation menu" onClick={handleShow}
                    onKeyDown={(e) => handleKeyPress(e)}
                >
                    <svg
                        stroke="#1A4480"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        className="bi bi-list"
                        viewBox="0 0 16 16"
                        aria-hidden="true"
                    >
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg></button>

            </div>



            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header style={{ backgroundColor: "#E1F3F8" }} closeButton>
                    <Offcanvas.Title style={{ marginLeft: "25%", fontWeight: "bold" }}>Navigation Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <nav className="main-nav">
                        <ul>
                            <li key="docket-select" className={props.activeTab === 'Docket Select' ? 'active-on' : ''}>
                                <Link to="/" onClick={() => {props.setTab('Docket Select'); handleClose()}}>
                                    Docket Select
                                </Link>
                            </li>
                            {props.docketId === '' ? <div /> : <li key="comment-list" className={`${levelTwo}-${props.docketId !== '' ? 'active' : 'disabled'}${props.activeTab === 'Comment List' ? '-on' : ''} `}>
                                <Link to="/comment-list" onClick={() => {props.setTab('Comment List'); handleClose()}}>
                                    Comment List
                                </Link>
                            </li>}
                            {props.commentId === '' ? <div /> : <li key="comment-details" className={`${levelThree}-${props.commentId !== '' ? 'active' : 'disabled'}${props.activeTab === 'Comment Details' ? '-on' : ''}`}>
                                <Link to="/comment-details" onClick={() => {props.setTab('Comment Details'); ; handleClose()}}>
                                    Comment Details
                                </Link>
                            </li>}

                            {props.docketId === '' ? <div /> : <li key="summary-view" className={`${levelTwo}-${props.docketId !== '' ? 'active' : 'disabled'}${props.activeTab === 'Summary View' ? '-on' : ''}`}>
                                <Link to="/summary-view" onClick={() => {props.setTab('Summary View'); handleClose()}}>
                                    Summary View
                                </Link>
                            </li>}
                            {props.docketId === '' ? <div /> : <li key="similarity" className={`${levelTwo}-${props.docketId !== '' ? 'active' : 'disabled'}${props.activeTab === 'Similarity' ? '-on' : ''}`}>
                                <Link to="/similarity" onClick={() => {props.setTab('Similarity'); handleClose()}}>
                                    Similarity
                                </Link>
                            </li>}
                            {props.docketId === '' ? <div /> : <li key="recommendations" className={`${levelTwo}-${props.docketId !== '' ? 'active' : 'disabled'}${props.activeTab === 'Recommendations' ? '-on' : ''}`}>
                                <Link to="/recommendations" onClick={() => {props.setTab('Recommendations'); handleClose()}}>
                                    Recommendations
                                </Link>
                            </li>}

                            <li key="my-selections" className={props.activeTab === 'My Selections' ? 'active-on' : ''}>
                                <Link to="/my-selections" onClick={() => {props.setTab('My Selections'); handleClose()}}>
                                    My Selections
                                </Link>
                            </li>

                            <li key="my-annotations" className={props.activeTab === 'My Annotations' ? 'active-on' : ''}>
                                <Link to="/my-annotations" onClick={() => {props.setTab('My Annotations'); handleClose()}}>
                                    My Annotations
                                </Link>
                            </li>
                            <li key="documentation" className={props.activeTab === 'Documentation' ? 'active-on' : ''}>
                                <Link to="/documentation" onClick={() => {props.setTab('Documentation'); handleClose()}}>
                                    Documentation
                                </Link>
                            </li>
                        </ul>
                    </nav>


                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default OffCanvas;