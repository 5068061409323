import React, { Component } from 'react';
import { CaretLeft, CaretRight } from 'react-bootstrap-icons';


class CommentPager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            keywordsAtts: [],
        };
    }
    componentDidUpdate(pp, ps) {
        if (pp.commentId !== this.props.commentId) {
            this.forceUpdate()
        }
    }
    render() {


        return (
            <>
                <div aria-live="polite">
                    {this.props.previousComment !== undefined ? (

                        <button
                                                type="button"

                            id="prevButton"
                            onClick={this.props.handlePrevClick}
                            onKeyDown={(event) => { if (event.key === "Enter") { this.props.handlePrevClick } }}
                            aria-label="Previous comment"
                            className="invisible-button"
                            style={{ textDecoration: 'underline', cursor: 'pointer', color: 'rgb(0, 94, 162)',
                            paddingRight: "5px"
                        }}

                        >

                            <CaretLeft className='pager-arrow' aria-hidden="true" />Previous</button>

                    ) :

                        <span className="sr-only">No previous comments</span>

                    }
                    <span style={{ fontSize: '15px', color: "black" }}className="prev-button"  aria-label={"The previous comment is " + this.props.truncateCommentId(this.props.previousComment)} >{this.props.truncateCommentId(this.props.previousComment)}  ...</span>
                    <span style={{ fontSize: '17px', fontWeight: "bold" }} aria-label={"The current comment is " + this.props.truncateCommentId(this.props.commentId)}> {this.props.truncateCommentId(this.props.commentId)} </span>
                    <span aria-label={"The next comment is " + this.props.truncateCommentId(this.props.next)} style={{ fontSize: '15px' }}> ... {this.props.truncateCommentId(this.props.next)}</span>

                    {this.props.next !== undefined ? (

                        <button
                        type="button"
                            id="nextButton"
                            onClick={this.props.handleNextClick}
                            onKeyDown={(event) => { if (event.key === "Enter") { this.props.handleNextClick } }}
                            aria-label="Next comment"
                            className="invisible-button"
                            style={{ textDecoration: 'underline', cursor: 'pointer', color: 'rgb(0, 94, 162)', paddingLeft: "5px"
                        }}
                        >Next       
                            <CaretRight aria-hidden="true" className='pager-arrow' />
                                             </button>
                    ) : <span className="sr-only">No next comments</span>
                    }


                </div>
            </>
        );
    };
}
export default CommentPager;
