import React, { Component } from 'react';
import { Badge } from 'react-bootstrap';
import { Button } from '@cmsgov/design-system';
import ModelFeedback from './ModelFeedback';


class AttachmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      topics: '',
    };

  }
  _isMounted = false;

  getData = () => {
    this._isMounted = true
    fetch('/api/' + this.props.office + '/attachmentDetails/' + this.props.docketId + '/' + this.props.commentId + '/' + this.props.attNum)
      .then((response) => { return response.json() })
      .then((json) => {
        if (this._isMounted) {
          this.setState({
            details: json.Items[0],
            topics: this.getTopics(json.Items)
          });

        }
      }
      );

  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  // componentDidMount() {
  //  if (this.props.commentId!=='')
  //      this.getData();
  // }
  componentDidUpdate(pp, ps) {

    if (pp.commentId !== this.props.commentId || this.props.attNum !== pp.attNum) {
      this.props.setPart(0);
      this.props.setAttNum(0);
      this.getData()
    }

  }
  getTopics = (topics) => {
    let str = '';
    for (let element of topics) {
      str += element.topic + ',  '
    }

    return str.slice(0, -3)
  }
  handleRowClick = (rowId) => {
    this.setState((prevState) => ({
      expandedRow: rowId === prevState.expandedRow ? null : rowId,
    }));
  };
  render() {
    console.log('parent details' + this.state.details)
    const { details } = this.props;
    const { expandedRow } = this.state;
    return (
      <>

        <div className="attachment-details-table">
          <table role="presentation">
            <tbody>
              <tr >
                <th>Stakeholder</th>
                <td>
                  {this.props.details?.primary_org && (

                    <Badge
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid #999',
                        background: 'white',
                      }}
                      pill
                      bg="light"
                      text="dark"
                    >

                      {this.props.details.primary_org}
                    </Badge>
                  )}
                  {this.props.details?.stakeholder_type && (

                    <Badge
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid #999',
                        background: 'white',
                      }}
                      pill
                      bg="light"
                      text="dark"
                    >
                      {this.props.details.stakeholder_type}
                    </Badge>)}
                  {this.props.details?.setting_of_care && (

                    <Badge
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid #999',
                        background: 'white',
                      }}
                      pill
                      bg="light"
                      text="dark"
                    >

                      {this.props.details.setting_of_care} </Badge>)}
                  {this.props.details?.specialty && (
                    <Badge
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid #999',
                        background: 'white',
                      }}
                      pill
                      bg="light"
                      text="dark"
                    >

                      {this.props.details.specialty} </Badge>)}
                  {this.props.details?.role && (

                    <Badge
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid #999',
                        background: 'white',
                      }}
                      pill
                      bg="light"
                      text="dark"
                    >
                      {this.props.details.role}
                    </Badge>)}
                </td>
                <td style={{ width: '10px' }}>{expandedRow === 0 ?



                  <Button aria-label="Collapse Stakeholder Model Feedback" type="button" aria-expanded="true" class="invisible-button" onClick={() => this.handleRowClick(0)}><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                  </svg></Button> : <Button aria-label="Expand Stakeholder Model Feedback" type="button" class="invisible-button" aria-expanded="false" onClick={() => this.handleRowClick(0)}><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                  </svg></Button>

                }</td>
              </tr>
              {expandedRow === 0 && (
                <tr>
                  <td colSpan="3" style={{ padding: 0 }}>
                    <table className="nested-table">
                      <tbody>
                        <tr>
                          <td>
                            <ModelFeedback
                              updateAriaLiveRegion={this.props.updateAriaLiveRegion}
                              details={this.props.details}
                              commentId={this.props.commentId}
                              attNum={this.props.attNum}
                              user={this.props.user}
                              docketId={this.props.docketId}
                              office={this.props.office}
                              filterText={this.props.filterText}

                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
              <tr>
                <th>Topic(s)</th>
                <td>
                  {this.props.topics.length !== 0 ? this.props.topics.map((topic, index) => (
                      <Badge
                        key={index}
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          border: '1px solid #999',
                          background: 'white',
                        }}
                        pill
                        bg="light"
                        text="dark"
                      >
                        {topic}
                      </Badge>
                    ))
                      :
                    <></>
                  }
                </td>
              </tr>
              <tr>
                <th>Location</th>
                <td>
                  {this.props.details?.location && (
                    <Badge
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid #999',
                        background: 'white',
                      }}
                      pill
                      bg="light"
                      text="dark"
                    >
                      {this.props.details.location}
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <th>Category</th>
                <td>
                  {this.props.details?.category && (
                    <Badge
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        border: '1px solid #999',
                        background: 'white',
                      }}
                      pill
                      bg="light"
                      text="dark"
                    >
                      {this.props.details.category}
                    </Badge>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };
}
export default AttachmentDetails;
