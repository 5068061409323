import React, { Component } from 'react';
let QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");
import './QuicksightDashboards.css'
class QuickSight extends Component {

	constructor(props) {
		super(props);
		this.state = {
			url: '',
		};  
		this.dashboard=null;
	}
	componentDidMount() {
		fetch('/api/quicksight/'+this.props.board)
		  .then((response) => response.json())
		  .then((json) => {
			if (json.EmbedUrl!=='') {
				let embedUrl = json.EmbedUrl
				this.setState({ url: embedUrl })
				this.embedDashboard(embedUrl);

			}
		  });
	}
	
	embedDashboard(embedUrl) {
		const container = document.getElementById('embeddingContainer'); // Make sure you have a container element with this ID
		
		if (container) {
		  const options = {
			url: embedUrl,
			container: container,
			footerPaddingEnabled: true,
			
		  };
		  this.dashboard = QuickSightEmbedding.embedDashboard(options);
		}
	  }
	  
	
	  render() {
		return (
		  <div id="embeddingContainer" className="responsive-container"></div>
		);
	  }

}
export default QuickSight;