import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Choice } from '@cmsgov/design-system';

import FilterSelect from '../sharedComponents/FilterSelect';
import ReviewSelections from './ReviewSelections';
import FlagSelections from './FlagSelections';

import { Alert } from 'react-bootstrap';




class MySelections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docketId: '',
            office: '',
            docketIds: [],
            selections: [],
            docketOffice: {},
            docketIdSelected: false,
            review: false,
            flag: false,
            reviewCount: 0,
            flagCount: 0,
            docketTitle: '',
            liveRegionContent: ''

        };

    }
    setup() {
        let restricted = this.props.restricted =='true' ? 'true' : 'false'

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user: this.props.user,
                restricted: restricted,


            })
        };
        fetch('/api/docketIds', requestOptions)
            .then((response) => response.json())
            .then((json) => this.setState({
                docketIds: this.docketOptions(json.Items),
                docketOffice: json.Items
            }));
        if (this.props.docketId !== '') {
            this.setState({
                docketId: this.props.docketId,
                docketIdSelected: true,
                office: this.props.office
            })
            this.setTitle(this.props.docketId)
        }
        // console.log(JSON.stringify(this.state.docketOffice))
    }

    setTitle = (docket) => {

        fetch(`/api/getDocketTitle/${docket}`)
            .then((response) => {
                if (this._isMounted)
                    return response.json()
            })
            .then((json) => {
                if (this._isMounted) {
                    // console.log(json)
                    this.setState({ docketTitle: json.Items[0].title })
                }
            });

    }

    componentDidMount() {

        this._isMounted = true;
        this.setup()
        document.title = 'My Selections'; // Replace with your default title

    }


    componentDidUpdate(pp, ps) {
        if (ps.docketId !== this.state.docketId) {
            this.setState({review: false,
                            flag: false})
            // console.log("component updated")

        }
        // console.log(this.state.data)
    }
    docketOptions = (dockets) => {
        var opts = []
        dockets.forEach((d) => {
            opts.push({ label: d.docket_id, value: d.docket_id });
        });
        // console.log(opts)
        return opts;
    }

    selectDocket = (event) => {
        var id = event.value
        var office = this.state.docketOffice.find(x => x.docket_id === id).office
        this.setState({
            docketId: id,
            docketIdSelected: true,
            office: office,
            review: false,
            flag: false

        })
        fetch(`/api/getDocketTitle/${id}`)
            .then((response) => {
                if (this._isMounted)
                    return response.json()
            })
            .then((json) => {
                if (this._isMounted) {
                    // console.log(json)
                    this.setState({ docketTitle: json.Items[0].title })
                }
            });

    }
    setReview = (e) => {
        let review = e.target.checked;
        // console.log(`ignoreDupes Changing to ${e.target.checked}`);
        this.setState({ review: review,
            liveRegionContent: 'Reviewed box checked' })
            document.getElementById('live-region').focus();


    }

    setFlag = (e) => {
        let flag = e.target.checked;
        // console.log(`ignoreDupes Changing to ${e.target.checked}`);
        this.setState({ flag: flag,
            liveRegionContent: 'Flagged box checked' })
            document.getElementById('live-region').focus();

    }

    setReviewCount = (c) => {
        this.setState({ reviewCount: c })
    }

    setFlagCount = (c) => {
        this.setState({ flagCount: c })
    }

    render() {
        
        var spacing = "tab-bottom-sel"
        this.props.docketId === '' ? spacing += '-nodocket' : spacing += ''
        // console.log(this.state.selections)
        const DisplayData = this.state.selections.map(
            (info) => {
                return (
                    <tr>
                        <td>{info.comment_id}</td>
                        <td>{info.comment}</td>
                    </tr>
                )
            }
        )
        return (
            <>
          <div className="sr-only" aria-live="polite" id="live-region">
            {this.state.liveRegionContent}
        </div>
                <div className="tab-top">
                    <Row>
                        <Col>
                            <h1 className="level-0-headings">My Selections</h1>
                            <p >Select a docket to view your reviewed and flagged groups</p>
                            <div style={{ width: "70%" }}>
                                <FilterSelect
                                    placeholder={'--Select--'}
                                    selection={this.state.docketId}
                                    selectItem={this.selectDocket}
                                    selections={this.state.docketIds}
                                    label="Select a docket to view your reviewed and flagged groups"

                                />
                 
                            </div>
                        </Col>
                        <Col md={8}>
                            <div style={{ paddingTop: "25px", paddingBottom: "15px" }}>
                                <span ><span tabIndex="0" style={{ color: "#1A4480" }}>Active:</span> {this.state.docketIdSelected ? <span tabIndex="0" style={{ color: "#1A4480" }}><b>{this.state.docketId}</b> <span tabIndex="0"style={{ display: "flex" }}> {this.state.docketTitle}</span></span> : <span tabIndex="0" style={{ color: "#1A4480" }}>None</span>}</span>
                            </div>
                            <span style={{ display: "flex" }}>
                                <div tabIndex="0" style={{ fontWeight: "bold", margin: "10px" }}>View Selection</div>
                                <div className="vl"></div>

                                <Choice
                                    label="Review"
                                    name="review"
                                    type="checkbox"
                                    disabled={!this.state.docketIdSelected}
                                    onChange={e => this.setReview(e)}
                                    onKeyPress={e => this.setReview(e)}
                                    checked={this.state.review}
                                    
                                ></Choice>
                                <div style={{ margin: "1%" }} />
                                <Choice
                                    label="Flag"
                                    name="flag"
                                    type="checkbox"
                                    disabled={!this.state.docketIdSelected}
                                    onChange={e => this.setFlag(e)}
                                    onKeyPress={e => this.setFlag(e)}
                                    checked={this.state.flag}
                                ></Choice>
                                <span style={{ float: "right", marginLeft: "20%" }}  >
                                    {this.state.docketIdSelected && this.state.review ? <span style={{ fontWeight: "bold" }}><svg aria-hidden = "true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg> {this.state.reviewCount}&nbsp;Reviewed Comments</span> : <div />}
                                    <p />
                                    {this.state.docketIdSelected && this.state.flag ? <span style={{ fontWeight: "bold" }}><svg aria-hidden = "true"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag-fill" viewBox="0 0 16 16">
                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                    </svg>&nbsp;&nbsp;{this.state.flagCount}&nbsp;Flagged Comments</span> : <div />}


                                </span>
                            </span>

                        </Col>
                    </Row>
                    {this.state.docketIdSelected ? <div /> :   <div style={{paddingTop: "10px"}}><Alert variant="info" style={{ backgroundColor: "#E7F6F8" }}>
                    <Alert.Heading><svg style={{ marginRight: "1%" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                    </svg>Select a Docket</Alert.Heading>
                    <p>
                        Currently you have no Docket selected. Please use the dropdown above to select the Docket you would like to see your reviewed or flagged selections for in this table.                       </p>
                </Alert></div>}
                </div>
                <div className={spacing}>
                    {this.state.docketIdSelected && this.state.review ? 
                        <ReviewSelections 
                            docketTitle={this.state.docketTitle} 
                            reviewCount={this.state.reviewCount} 
                            filters={this.props.filters} 
                            setReviewCount={this.setReviewCount} 
                            office={this.state.office} user={this.props.user} 
                            docketId={this.state.docketId} />
                        : <div />}
                        <div style={{padding: "20px"}}></div>
                    {this.state.docketIdSelected && this.state.flag ? 
                        <FlagSelections 
                            flagCount={this.state.flagCount} 
                            setFlagCount={this.setFlagCount} 
                            user={this.props.user} 
                            docketId={this.state.docketId} 
                            docketTitle={this.state.docketTitle} 
                            office={this.state.office} /> 
                        : <div />}


        
                </div>
            </>
        )
    };
}
export default MySelections;