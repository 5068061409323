import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FilterChip } from '@cmsgov/design-system';

import FeedbackDropdown from './FeedbackDropdown';

class ModelFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            topics: '',
            submitter_org_is_correction: '',
            stakeholder_is_correction: '',
            stakeholder_correct: '',
            setting_of_care_is_correction: '',
            setting_of_care_correct: '',
            specialty_is_correction: '',
            specialty_correct: '',
            role_type_is_correction: '',


            setting_of_care_correct_exists: false,
            specialty_correct_exists: false,
            stakeholder_correct_exists: false,

            so_feedback_given: false,
            st_feedback_given: false,
            soc_feedback_given: false,
            sp_feedback_given: false,
            ro_feedback_given: false,

            delete_correction: '',
            delete_entity: '',

            stakeholder_data: {
                comment_att: '',
                user: [],
                date: '',
                feedback: {
                    primary_org:
                    {
                        original: '',
                        is_correction: '',
                        correct: ''

                    },
                    stakeholder_type:
                    {
                        original: '',
                        is_correction: '',
                        correct: ''

                    },
                    setting_of_care:
                    {
                        original: '',
                        is_correction: '',
                        correct: ''

                    },
                    specialty:
                    {
                        original: '',
                        is_correction: '',
                        correct: ''

                    },
                    role_type:
                    {
                        original: '',
                        is_correction: '',
                        correct: ''

                    }
                }

            },
            show: false,
            setShow: false,
            history: []
        };

    }
    _isMounted = false;


    componentDidMount() {
        this._isMounted = true;
        // console.log
        // Check to see if a user has submitted feedback for any of the types
        // change boolean values accordingly
        this.getData();


    }

    getData = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                docketId: this.props.docketId,
                comment_id: this.props.commentId,
                attNum: this.props.attNum,
                user: this.props.user,
                comment_att: this.props.commentId + '_' + this.props.attNum
            })
        };
        fetch('/api/load_stakeholder_feedback', requestOptions)
            .then((response) => { return response.json() })
            .then((json) => {
                if (this._isMounted) {
                    // console.log("Fetching stakeholder data" + JSON.stringify(json))
                    // console.log("DATA" + json)
                    if (json !== 0) {

                        this.setState({
                            stakeholder_data: json,
                            submitter_org_is_correction: json.feedback.primary_org.is_correction,
                            stakeholder_is_correction: json.feedback.stakeholder_type.is_correction,
                            stakeholder_correct: json.feedback.stakeholder_type.correct,
                            setting_of_care_is_correction: json.feedback.setting_of_care.is_correction,
                            setting_of_care_correct: json.feedback.setting_of_care.correct,
                            specialty_is_correction: json.feedback.specialty.is_correction,
                            specialty_correct: json.feedback.specialty.correct,
                            role_type_is_correction: json.feedback.role_type.is_correction,
                            setting_of_care_correct_exists: json.feedback.setting_of_care.correct === '' ? false : true,
                            soecialty_correct_exists: json.feedback.specialty.correct === '' ? false : true,
                            stakeholder_correct_exists: json.feedback.stakeholder_type.correct === '' ? false : true,


                        })
                    }
                }
            }
            );
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    submit_correct_feedback = (e, correct) => {

        let entity = e

        // console.log("Logging info for " + e + this.state.stakeholder_correct)
        if (e === 'stakeholder_type') {
            // console.log("this is for stakeholder type")
            this.setState({ st_feedback_given: true })
        } else if (e === 'setting_of_care') {
            // console.log("for setting of care")
            this.setState({ soc_feedback_given: true })
        } else if (e === 'specialty') {
            // console.log("for specialty")
            this.setState({ sp_feedback_given: true })
        }

        this.setState(prevState => {
            const updatedFeedback = { ...prevState.stakeholder_data.feedback };
            updatedFeedback[entity]["correct"] = correct.toLowerCase();
            return {
                stakeholder_data: { ...prevState.stakeholder_data, feedback: updatedFeedback }
            }
        }

            , () => {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        docketId: this.props.docketId,
                        comment_id: this.props.commentId,
                        attNum: this.props.attNum,
                        user: this.props.user,
                        comment_att: this.props.commentId + '_' + this.props.attNum,
                        feedback: JSON.stringify(this.encodeJSON(this.state.stakeholder_data.feedback)),
                        feedback_correct: correct.toLowerCase(),
                        date: [],
                        entity: entity.toLowerCase(),
                        office: this.props.office
                    })
                };

                // console.log("Logging correct here" + correct);
                fetch('/api/add_stakeholder_correction', requestOptions)
                    .then((response) => { return response.json() })
                    .then((json) => {
                        if (this._isMounted) {
                            console.log("Adding stakeholder data")
                            // console.log("DATA" + json)
                            // this.setState({ stakeholder_data: json })
                        }
                    }
                    );
                fetch('/api/add_feedback_history', requestOptions)
                    .then((response) => { return response.json() })
                    .then((json) => {
                        if (this._isMounted) {
                            console.log(json)
                            // console.log("Adding correction to feedback history" + JSON.stringify(json))
                            // console.log("DATA" + json)
                            // this.setState({ stakeholder_data: json })
                        }
                    }
                    );
            }
        )



    }

    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    }



    encodeSpecialChars = (str) => {
        // Replace each special character with its Unicode equivalent
        let specialChars = ['[', ']', '~', '|', '{', '}', '\\', '\'', '^', '`', '_', '-', '@', '?', '>', '<', '=', ':', ';', '!', '#', '&', '$', '%', '"', '(', ')', ',', '.', '*', '+', '/']

        return Array.from(str).map(char => specialChars.includes(char) ? '%' + char.charCodeAt(0).toString(16) : char).join('');


    }

    encodeJSON = (obj) => {
        return JSON.parse(JSON.stringify(obj, (key, value) => {
            if (typeof value === 'string') {
                // console.log("string found" + value)
                return this.encodeSpecialChars(value);
            } else {
                return value;
            }
        }));
    }
    is_correction_feedback = (e, f) => {
        let x = f === 'true' ? 'correct' : 'incorrect'
        this.props.updateAriaLiveRegion(e + " is " + x)
        var m = this.convertUTCDateToLocalDate(new Date());
        var dtt = m.getUTCFullYear() + "/" +
            ("0" + (m.getUTCMonth() + 1)).slice(-2) + "/" +
            ("0" + m.getUTCDate()).slice(-2) + " " +
            ("0" + m.getUTCHours()).slice(-2) + ":" +
            ("0" + m.getUTCMinutes()).slice(-2);

        if (e === 'primary_org') {
            this.setState({ so_feedback_given: true })

        } else if (e === 'role_type') {
            this.setState({ ro_feedback_given: true })

        }
        let entity = e
        let val = f
        // console.log(f)
        if (val === 'true') {
            if (e === 'setting_of_care') {
                this.setState({ soc_feedback_given: true })

            } else if (e === 'role_type') {
                this.setState({ ro_feedback_given: true })

            } else if (e === "stakeholder_type") {
                this.setState({ st_feedback_given: true })
            } else if (e === "specialty") {
                this.setState({ sp_feedback_given: true })
            }
        }
        // console.log(this.props.details[entity])
        this.setState(prevState => {

            const updatedFeedback = { ...prevState.stakeholder_data.feedback };
            updatedFeedback[entity]["is_correction"] = val;
            val === 'true' ? updatedFeedback[entity]["correct"] = '' : updatedFeedback[entity]["correct"] = this.state.stakeholder_data["feedback"][entity]["correct"];
            e === 'role_type' ? updatedFeedback[entity]["original"] = this.props.details["role"] : updatedFeedback[entity]["original"] = this.props.details[entity]
            return {
                stakeholder_data: { ...prevState.stakeholder_data, feedback: updatedFeedback }
            }
        }

            , () => {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        docketId: this.props.docketId,
                        comment_id: this.props.commentId,
                        attNum: this.props.attNum,
                        user: this.props.user,
                        comment_att: this.props.commentId + '_' + this.props.attNum,
                        //feedback: {"key":"test", "key":"test2", "key":"TEST3"}//})};
                        //feedback: JSON.stringify({"primary_org": {"test1": "","val1": "","val2": ""},"test2": {"val2": ""}}) //WORKING
                        feedback: JSON.stringify(this.encodeJSON(this.state.stakeholder_data.feedback)),
                        feedback_is_correction: val,
                        date: dtt,
                        entity: entity.toLowerCase(),
                        feedback_correction: this.state.stakeholder_data["feedback"][entity]["correct"].toLowerCase()
                    })
                };


                fetch('/api/add_stakeholder_feedback', requestOptions)
                    .then((response) => { return response.json() })
                    .then((json) => {
                        if (this._isMounted) {
                            console.log("Adding stakeholder data")
                            // console.log("DATA" + json)
                            // this.setState({ stakeholder_data: json })
                        }
                    }
                    );
            }

        )

    }
    deleteCorrection = (entity) => {
        // console.log(entity)
        this.setState(prevState => {
            const updatedFeedback = { ...prevState.stakeholder_data.feedback };
            updatedFeedback[entity]["correct"] = '';
            return {
                stakeholder_data: { ...prevState.stakeholder_data, feedback: updatedFeedback }
            }
        }, () => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    docketId: this.props.docketId,
                    comment_id: this.props.commentId,
                    attNum: this.props.attNum,
                    user: this.props.user,
                    comment_att: this.props.commentId + '_' + this.props.attNum,
                    feedback: JSON.stringify(this.state.stakeholder_data.feedback),
                    entity: entity.toLowerCase(),
                })
            };


            fetch('/api/delete_stakeholder_feedback', requestOptions)
                .then((response) => { return response.json() })
                .then((json) => {
                    if (this._isMounted) {
                        console.log("Deleting stakeholder data")
                        // console.log("DATA" + json)
                        // this.setState({ stakeholder_data: json })
                    }
                }
                );
        }
        )
    }
    handleClose = (e) => {
        this.setState({ setShow: false });
    }
    handleSave = () => {
        // console.log(this.state.delete_correction)
        // console.log(this.state.delete_entity)

        this.deleteCorrection(this.state.delete_entity)
        this.setState({ setShow: false });
    }
    handleShow = (e) => {
        // console.log(this.state.stakeholder_data.feedback[e]["correct"])

        this.setState({
            setShow: true,
            delete_correction: this.state.stakeholder_data.feedback[e]["correct"],
            delete_entity: e
        });
    }

    handleKeyPress = (event, entity, val) => {
        if (event.key === 'Enter') {
            console.log('enter press here! ')
        }
        this.is_correction_feedback(entity, val)
    }
    render() {
        // console.log(JSON.stringify(this.state.stakeholder_data.feedback.stakeholder_type.correct))

        const InfoIcon = ({ text, color }) => (
            <>
              <span className="sr-only">{text}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                className={`bi bi-${color === "#00A91C" ? 'check-circle-fill' : 'x-circle-fill'}`}
                viewBox="0 0 16 16"
              >
                <path
                  d={
                    color === "#00A91C"
                      ? "M13.03 4.97a.75.75 0 0 0-1.08.02L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                      : "M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                  }
                  fill={color} // Set the fill color directly based on the prop
                />
              </svg>
            </>
          );
          
          
          
        // let greenFilled = <><span className="sr-only">Information has been identified as correct</span><svg aria-hidden="true" style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00A91C" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
        //     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        // </svg></>
        // let redFilled = <><span className="sr-only">Information has been identified as incorrect</span><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D54309" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
        //     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        // </svg></>

        let po_greenUnfilled = <button type="button" className="invisible-button" aria-label="Green check - submitter organization is correct" onKeyPress={(e) => this.handleKeyPress(e, "primary_org", "true")} onClick={() => this.is_correction_feedback("primary_org", 'true')}><svg aria-hidden="true" style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00A91C" class="bi bi-check-circle" viewBox="0 0 16 16" >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg></button>


        let po_redUnfilled = <button type="button" className="invisible-button" onKeyPress={(e) => this.handleKeyPress(e, "primary_org", "false")} onClick={() => this.is_correction_feedback("primary_org", 'false')} aria-label="Red x -   submitter organization type is incorrect" > <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D54309" class="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg></button>
        let st_greenUnfilled = <button type="button" className="invisible-button" aria-label="Green check -   stakeholder type is correct" onKeyPress={(e) => this.handleKeyPress(e, "stakeholder_type", "true")} onClick={() => this.is_correction_feedback("stakeholder_type", 'true')} ><svg aria-hidden="true" style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00A91C" class="bi bi-check-circle" viewBox="0 0 16 16" >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg></button>


        let st_redUnfilled = <button type="button" className="invisible-button" aria-label="Red x -   stakeholder type type is incorrect" onKeyPress={(e) => this.handleKeyPress(e, "stakeholder_type", "false")} onClick={() => this.is_correction_feedback("stakeholder_type", 'false')}><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D54309" class="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg></button>

        let soc_greenUnfilled = <button type="button" className="invisible-button" aria-label="Green check -   setting of care is correct" onKeyPress={(e) => this.handleKeyPress(e, "setting_of_care", "true")} onClick={() => this.is_correction_feedback('setting_of_care', 'true')}><svg aria-hidden="true" style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00A91C" class="bi bi-check-circle" viewBox="0 0 16 16" >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg>
        </button>

        let soc_redUnfilled = <button type="button" className="invisible-button" aria-label="Red x -   setting of care is incorrect" onKeyPress={(e) => this.handleKeyPress(e, "setting_of_care", "false")} onClick={() => this.is_correction_feedback('setting_of_care', 'false')}><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D54309" class="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg></button>
        let sp_greenUnfilled = <button type="button" className="invisible-button" aria-label="Green check -   specialty is correct" onKeyPress={(e) => this.handleKeyPress(e, "specialty", "true")} onClick={() => this.is_correction_feedback('specialty', 'true')}><svg aria-hidden="true" style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00A91C" class="bi bi-check-circle" viewBox="0 0 16 16" >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg></button>


        let sp_redUnfilled = <button type="button" className="invisible-button" aria-label="Red x -   specialty type is incorrect" onClick={() => this.is_correction_feedback('specialty', 'false')} onKeyPress={(e) => this.handleKeyPress(e, "specialty", "false")} ><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D54309" class="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg></button>
        let r_greenUnfilled = <button type="button" className="invisible-button" aria-label="Green check -   role is correct" onKeyPress={(e) => this.handleKeyPress(e, "role_type", "true")} onClick={() => this.is_correction_feedback('role_type', 'true')} ><svg aria-hidden="true" style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00A91C" class="bi bi-check-circle" viewBox="0 0 16 16" >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg></button>


        let r_redUnfilled = <button type="button" className="invisible-button" aria-label="Red x -   role is incorrect" onKeyPress={(e) => this.handleKeyPress(e, "role_type", "false")} onClick={() => this.is_correction_feedback('role_type', 'false')} ><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D54309" class="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg></button>

        /** Submitter Org:
         *  if submitter_org is correction == '': both icons should be clickable and unfilled
         *  if submitter_org is correction == 'true' then the green icon should be filled but not clickable, red should be unfilled and clickable
         *  if submitter_org is correction == 'false' then the green icon should be unfilled and clickable, red should be filled and unclickable
         */


        return (
            <>
                <span style={{ padding: "8px" }}><b>Below you will see the identified Stakeholder Information. Please use the green check <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00A91C" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg> or red 'x' <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D54309" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg> to identify if the information is correct. If the information is incorrect, please add the correct information in the text box to the right of the red 'x'.</b></span>

                <div class="model-feedback-table">
                    <Modal show={this.state.setShow} onHide={() => this.handleClose()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Remove "{this.state.delete_correction}"?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to remove this label? If you do, this will no longer appear as a correction to the information.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.handleClose()}>
                                Go back
                            </Button>
                            <Button variant="primary" onClick={() => this.handleSave()}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <table role="presentation" style={{ whiteSpace: "nowrap" }}>
                        <tbody>
                            <tr>
                                <th scope="row"> Submitter Organization</th>

                                <td style={{ width: "500%" }} >{this.props.details.primary_org}</td>


                                {(() => {
                                    if (this.state.stakeholder_data.feedback.primary_org.is_correction === '') {
                                        return (
                                            <React.Fragment>
                                                <td>
                                                    {po_greenUnfilled}
                                                </td><td>
                                                    {po_redUnfilled}
                                                </td>
                                            </React.Fragment>)
                                    } else if (this.state.stakeholder_data.feedback.primary_org.is_correction === 'true') {

                                        return (
                                            <React.Fragment>
                                                <td>
                                                <InfoIcon text="Submitter organization has been identified as correct" color="#00A91C" />

                                                </td><td>

                                                    {po_redUnfilled}
                                                </td>
                                            </React.Fragment>
                                        )
                                    } else if (this.state.stakeholder_data.feedback.primary_org.is_correction === 'false') {

                                        return (

                                            <React.Fragment>
                                                <td>
                                                    {po_greenUnfilled}
                                                </td><td>

                                                <InfoIcon text="Submitter organization has been identified as incorrect" color="#D54309" />

                                                </td>
                                            </React.Fragment>)
                                    }
                                })()}
                             


                            </tr>


                            {/* <td></td> */}



                            <tr>
                                <th scope="row"> Stakeholder Type </th>

                                <td style={{ width: "500%" }} >{this.props.details.stakeholder_type}</td>


                                {(() => {
                                    if (this.state.stakeholder_data.feedback.stakeholder_type.is_correction === '') {
                                        return (
                                            <React.Fragment>
                                                <td>
                                                    {st_greenUnfilled}
                                                </td><td>

                                                    {st_redUnfilled}
                                                </td>
                                            </React.Fragment>)
                                    } else if (this.state.stakeholder_data.feedback.stakeholder_type.is_correction === 'true') {

                                        return (
                                            <React.Fragment>
                                                <td>
                                                <InfoIcon text="Stakeholder type has been identified as correct" color="#00A91C" />

                                                </td><td>

                                                    {st_redUnfilled}
                                                </td>
                                            </React.Fragment>
                                        )
                                    } else if (this.state.stakeholder_data.feedback.stakeholder_type.is_correction === 'false') {

                                        return (

                                            <React.Fragment>
                                                <td>
                                                    {st_greenUnfilled}
                                                </td><td>

                                                <InfoIcon text="Stakeholder type has been identified as incorrect" color="#D54309" />

                                                </td>
                                            </React.Fragment>)
                                    }
                                })()}



                                <td style={{ paddingTop: "20px" }}>

                                    <FeedbackDropdown
                                        disabled={this.state.stakeholder_data.feedback.stakeholder_type.is_correction === 'false' ? false : true}
                                        office={this.props.office}
                                        entity="stakeholder_type"
                                        docketId={this.props.docketId}
                                        is_correction={this.state.stakeholder_data.feedback.stakeholder_type.is_correction}
                                        submit_correct_feedback={this.submit_correct_feedback}
                                        correct={this.state.stakeholder_correct.toLowerCase()}
                                        selectedValue={this.state.stakeholder_data.feedback.stakeholder_type.correct}
                                        handleShow={this.handleShow}

                                    />


                                </td>

                                {
                                    this.state.stakeholder_data.feedback.stakeholder_type.correct === '' ? <> </> : <td><FilterChip label={this.state.stakeholder_data.feedback.stakeholder_type.correct} onDelete={() => this.handleShow("stakeholder_type")} />

                                    </td>}





                            </tr>
                            <tr>
                                <th scope="row"> Setting of Care </th>

                                <td style={{ width: "500%" }} > {this.props.details.setting_of_care}</td>
                                {(() => {
                                    if (this.state.stakeholder_data.feedback.setting_of_care.is_correction === '') {
                                        return (
                                            <React.Fragment>
                                                <td>
                                                    {soc_greenUnfilled}
                                                </td><td>

                                                    {soc_redUnfilled}
                                                </td>
                                            </React.Fragment>)
                                    } else if (this.state.stakeholder_data.feedback.setting_of_care.is_correction === 'true') {

                                        return (
                                            <React.Fragment>
                                                <td>
                                                <InfoIcon text="Setting of care has been identified as correct" color="#00A91C" />

                                                </td><td>

                                                    {soc_redUnfilled}
                                                </td>
                                            </React.Fragment>
                                        )
                                    } else if (this.state.stakeholder_data.feedback.setting_of_care.is_correction === 'false') {

                                        return (

                                            <React.Fragment>
                                                <td>
                                                    {soc_greenUnfilled}
                                                </td><td>

                                                <InfoIcon text="Setting of care has been identified as incorrect" color="#D54309" />

                                                </td>
                                            </React.Fragment>)
                                    }
                                })()}
                                <td style={{ paddingTop: "20px" }}>


                                    <div style={{ width: "250px" }}>
                                        <FeedbackDropdown
                                            disabled={this.state.stakeholder_data.feedback.setting_of_care.is_correction === 'false' ? false : true}
                                            office={this.props.office}
                                            entity="setting_of_care"
                                            docketId={this.props.docketId}
                                            is_correction={this.state.stakeholder_data.feedback.setting_of_care.is_correction}
                                            submit_correct_feedback={this.submit_correct_feedback}
                                            correct={this.state.setting_of_care_correct.toLowerCase()}
                                            selectedValue={this.state.stakeholder_data.feedback.setting_of_care.correct}
                                            handleShow={this.handleShow}

                                        />
                                    </div>

                                </td>

                                {
                                    this.state.stakeholder_data.feedback.setting_of_care.correct === '' ? <> </> : <td><FilterChip value={this.state.stakeholder_data.feedback.setting_of_care.correct} label={this.state.stakeholder_data.feedback.setting_of_care.correct} onDelete={() => this.handleShow("setting_of_care")} />
                                    </td>}

                                


                            </tr>
                            <tr>
                                <th scope="row"> Specialty </th>

                                <td style={{ width: "500%" }}> {this.props.details.specialty}</td>
                                {(() => {
                                    if (this.state.stakeholder_data.feedback.specialty.is_correction === '') {
                                        return (
                                            <React.Fragment>
                                                <td>
                                                    {sp_greenUnfilled}
                                                </td><td>

                                                    {sp_redUnfilled}
                                                </td>
                                            </React.Fragment>)
                                    } else if (this.state.stakeholder_data.feedback.specialty.is_correction === 'true') {

                                        return (
                                            <React.Fragment>
                                                <td>
                                                <InfoIcon text="Specialty has been identified as correct" color="#00A91C" />

                                                </td><td>

                                                    {sp_redUnfilled}
                                                </td>
                                            </React.Fragment>
                                        )
                                    } else if (this.state.stakeholder_data.feedback.specialty.is_correction === 'false') {

                                        return (

                                            <React.Fragment>
                                                <td>
                                                    {sp_greenUnfilled}
                                                </td><td>

                                                <InfoIcon text="Specialty has been identified as incorrect" color="#D54309" />

                                                </td>
                                            </React.Fragment>)
                                    }
                                })()}
                                <td style={{ paddingTop: "20px" }}>



                                    <div style={{ width: "250px" }}>
                                        <FeedbackDropdown
                                            disabled={this.state.stakeholder_data.feedback.specialty.is_correction === 'false' ? false : true}
                                            office={this.props.office}
                                            entity="specialty"
                                            docketId={this.props.docketId}
                                            is_correction={this.state.stakeholder_data.feedback.specialty.is_correction}
                                            submit_correct_feedback={this.submit_correct_feedback}
                                            correct={this.state.specialty_correct.toLowerCase()}
                                            selectedValue={this.state.stakeholder_data.feedback.specialty.correct}
                                            handleShow={this.handleShow}

                                        />

                                    </div>
                                </td>

                                {
                                    this.state.stakeholder_data.feedback.specialty.correct === '' ? <> </> : <td><FilterChip label={this.state.stakeholder_data.feedback.specialty.correct} onDelete={() => this.handleShow("specialty")} />
                                    </td>}
                               

                            </tr>


                            <tr>
                                <th scope="row"> Role </th>

                                <td style={{ width: "500%" }} > {this.props.details.role}</td>
                                {(() => {
                                    if (this.state.stakeholder_data.feedback.role_type.is_correction === '') {
                                        return (
                                            <React.Fragment>
                                                <td>
                                                    {r_greenUnfilled}
                                                </td><td>

                                                    {r_redUnfilled}
                                                </td>
                                            </React.Fragment>)
                                    } else if (this.state.stakeholder_data.feedback.role_type.is_correction === 'true') {

                                        return (
                                            <React.Fragment>
                                                <td>
                                                <InfoIcon text="Role has been identified as correct" color="#00A91C" />

                                                </td><td>

                                                    {r_redUnfilled}
                                                </td>
                                            </React.Fragment>
                                        )
                                    } else if (this.state.stakeholder_data.feedback.role_type.is_correction === 'false') {

                                        return (

                                            <React.Fragment>
                                                <td>
                                                    {r_greenUnfilled}
                                                </td><td>

                                                <InfoIcon text="Role has been identified as incorrect" color="#D54309" />

                                                </td>
                                            </React.Fragment>)
                                    }
                                })()}
                              



                            </tr>
                        </tbody>

                    </table>
                </div>
                <div style={{ paddingBottom: "50px" }}></div>




            </>
        );
    };
}
export default ModelFeedback;

