import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";

/**
* shows an review state for a comment.
*/
class CommentReview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reviewed: {}, 
			unReviewed: true
		};  
	}

    _isMounted=false;
    
	componentWillUnmount() {
		this._isMounted = false;
	}
	
	getData = () => {
		this._isMounted = true;
		fetch(`/api/reviewed/${this.props.user}/${this.props.docketId}/${this.props.commentId}`)
		  .then((response) => {
			  if (this._isMounted)
			  	  return response.json()
		  })
		  .then((json) => {
			  if (this._isMounted) {
			  	if(Object.keys(json).length === 0) {
					this.setState({unReviewed: true})
				  } else {
					this.setState({unReviewed: false})

				  }
		  		this.setState({ reviewed: json })
			  }
		  });
	}
	componentDidMount() {
		if (this.props.docketId!=='' && this.props.commentId!=='')
			this.getData();
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.docketId!=='' && this.props.commentId!==''
			&& this.props.commentId !== prevProps.commentId) {
			this.getData();
		}
	}
	convertUTCDateToLocalDate(date) {
		var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
		var offset = date.getTimezoneOffset() / 60;
		var hours = date.getHours();
		newDate.setHours(hours - offset);
		return newDate;   
	}	
	handleKeyPressReviewed = (event) => {
		if(event.key === 'Enter'){
		  this.handleReviewed(event);
		}
	  }
	handleReviewed = (event) => {
        if (this._isMounted) {
			var m = this.convertUTCDateToLocalDate(new Date());
			var dtt=    m.getUTCFullYear() + "/" +
				("0" + (m.getUTCMonth()+1)).slice(-2) + "/" +
				("0" + m.getUTCDate()).slice(-2) + " " +
				("0" + m.getUTCHours()).slice(-2) + ":" +
				("0" + m.getUTCMinutes()).slice(-2);
			this.setState({reviewed: {uid: this.props.user, when: dtt,}});
			// console.log(this.props.comment)
			// console.log(this.props)
			// console.log(JSON.stringify(this.props))


			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ 
					commentId: this.props.commentId,
					docketId: this.props.docketId,
					uid: this.props.user,
					when: dtt,
					comment: this.props.comment,
				})
			};
			// console.log(requestOptions)
			// console.log(JSON.stringify(requestOptions))
			fetch('/api/reviewed', requestOptions)
				.then(response => response.json())
				.then((json) => {
					if (this._isMounted) {
						this.setState({ reviewed: json,
										unReviewed: false })
						// this.next(this.props.commentId);
					}
				});
		}
	};

	handleKeyPressUnreview = (event) => {
		if(event.key === 'Enter'){
		  this.handleUnreview(event);
		}
	  }

    handleUnreview = (event) => {
        if(window.confirm('Are you sure you want to undo this comment review?')) {

            if (this._isMounted) {
                // console.log("Confirm")
                event.target.checked = false;
                this.setState({ reviewed: {}})



                    // console.log("unreview button clicked" + event.target.checked)
                    this.setState({reviewed: {}})
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            commentId: this.props.commentId,
                            docketId: this.props.docketId,
                            uid: this.props.user,
                        })
                    };

                    fetch('/api/reviewed_delete', requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        if (this._isMounted) {
                            this.setState({ reviewed: json,
											unReviewed: true })


                        // this.next(this.props.commentId);
                        }
                    });
            } 
        } else {
            event.preventDefault();
            event.target.checked = true;
            // console.log("Cancel")
            this.setState({ reviewed: this.state.reviewed})
        }
    } 
    
	next = (cid) => {
		if (typeof this.props.nextComment !== 'undefined') {
			this.props.nextComment(cid,'Comment List');
		}
	}
	mr = (r) => {
		return r && r.user && r.when ? `${r.user}: ${r.when}` : '';
    }

	render() { 
		const reviewedInfo = this.mr(this.state.reviewed); 
		const isTooltipVisible = reviewedInfo.length > 0;
		if (this.state.unReviewed) {
			const Checkbox = ({ id, ariaLabel, value, onChange, onKeyPress, label}) => {
                return (
                        <input label={label} id={id} style={{margin: '3px'}} type="checkbox" onChange={onChange} onKeyPress={onKeyPress} aria-label={ariaLabel} value={value}/>

                );
            };
			return (
				<>

	
					<Checkbox
                        id={`review-${this.props.commentId}`}
                        value={this.props.user}
                        onChange={this.handleReviewed}
                        onKeyPress={this.handleKeyPressReviewed}
                        ariaLabel={`review comment ${this.props.commentId}`}
						label="Review"

                    />Review


				</>
			)
		} else {
			const Checkbox = ({ id, ariaLabel, value, label,onChange, onKeyPress, defaultChecked }) => {
                return (
                        <input label={label}id={id} style={{margin: '3px'}} type="checkbox" onChange={onChange} onKeyPress={onKeyPress} aria-label={ariaLabel} value={value} defaultChecked={defaultChecked}/>
                );
            };
			return (
				<>
			

					<span data-tip data-for={"whodunit_" + this.props.commentId} >
					<Checkbox
                        id={`unreview-${this.props.commentId}`}
                        value={this.props.user}
                        onChange={this.handleUnreview}
                        onKeyPress={this.handleKeyPressUnreview}
                        ariaLabel={`unreview comment ${this.props.commentId}`}
                        defaultChecked={true}
						label="Review"

                    />
					</span>
					{isTooltipVisible && (
                        <ReactTooltip id={`whodunit_${this.props.commentId}`} place="top" effect="solid">
                            {reviewedInfo}
                        </ReactTooltip>
                    )}
					Review
				</>
			)
		}
	};
}
export default CommentReview;