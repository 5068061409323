import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import packageJson from '../../../../package.json';

class Footer extends Component {
	render() {  
		return (
			<Row>
				<Col className='footer text-right'>FAS Application ({this.props.env}), Version {packageJson.version}</Col>
			</Row>
		)
	};
}
export default Footer;
