import React, { Component } from "react";
import { Badge } from '@cmsgov/design-system';

class ActiveKeywords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKeywords: []
        }

    }


    getKeywords = () => {
        var arr = []

        for (var i in this.props.filters) {
            if (i === 'text' || i === 'cid') {
            // console.log(i + this.props.filters[i])
                arr.push([this.props.filters[i]]);
            }
        }

        var filtered = arr.filter(function (el) {
            return el != null && el != '' && el != false && el != "false";
        });
        // console.log(arr)
        var words = arr[0][0].split(',')
        var ids = arr[1][0].split(',')



        return words.concat(ids)

    }
    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          this.props.clearKeyword()
        }
      }
      render() {
        var activeKeywords = this.getKeywords()
        // console.log("Got them" + activeKeywords)
        if (activeKeywords.length !== 0) {
            return (
                <>
                    <span style={{marginRight: "15px", marginTop: "15px", marginBottom: "15px"}}>
                   

                    {activeKeywords.map((data, index) => {
                            if(data !== '') {
                            return (
                                <Badge
                                    children={data}
                                    variation="info"
                                    aria-label={data + " keyword applied"}
                                    style={{margin: "2px", backgroundColor: "#005cb0"}}
                                    aria-live="assertive" 
                                    key={`${data}-${index}`}
                                    size="big" />

                            ); }
                        })}</span>
                </>

            )
        }
    }
}

export default ActiveKeywords;