import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class WarningBanner extends Component {
	render() {
	  return (
			<div>
				<Row>
					<Col md={1} />
					<Col>
						<p />
						<ul tabIndex="0">
							<li>This warning banner provides privacy and security notices consistent with applicable laws, executive orders, directives, regulations, policies, standards, and guidelines for accessing this Government system, which includes (1) this computer network, (2) all computers connected to this network, and (3) all devices and storage media attached to this network or to a computer on this network.</li>

							<li>This system is provided for Government authorized use only.</li>

							<li>Unauthorized or improper use of this system is prohibited and may result in disciplinary 
							action and/or civil and criminal penalties.</li>

							<li>Personal use of social media and networking sites on this system is limited as to not 
							interfere with official work duties and is subject to monitoring.</li>

							<li>By using this system, you understand and consent to the following:
								<ul>
									<li>The Government may monitor, record, and audit your system usage, 
									including usage of personal devices and email systems for official duties 
									or to conduct HHS business. Therefore, you have no reasonable expectation 
									of privacy regarding any communication or data transiting or stored 
									on this system. At any time, and for any lawful Government purpose, 
									the government may monitor, intercept, and search and seize any 
									communication or data transiting or stored on this system.</li>

									<li>Any communication or data transiting or stored on this system may be 
									disclosed or used for any lawful Government purpose.</li>
								</ul>
							</li>
						</ul>
						<p><b>By clicking the "Login" button above, you are agreeing to these conditions.</b></p>
					</Col>
					<Col md={1} />
				</Row>
			</div>
	  );
	}
}

export default WarningBanner;