import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Choice } from '@cmsgov/design-system';

import FilterSelect from '../sharedComponents/FilterSelect';
import Select from 'react-select';
import { Alert } from 'react-bootstrap';


import { components } from "react-select";
import DownloadAnnotations from './DownloadAnnotations';

import DocketSelected from '../docketSelect/DocketSelected';


class MyAnnotations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docketId: '',
            office: '',
            docketIds: [],
            data: [],
            annotations: [],
            selections: [],
            docketOffice: {},
            docketIdSelected: false,
            reviewCount: 0,
            flagCount: 0,
            docketTitle: '',
            sortedData: [],
            noAnnotations: [],
            numDisagrements: 0,
            numAgreements: 0,
            numRecommendations: 0,
            numClarifications: 0,
            selectedOptions: [
                { value: 'Disagreements', label: 'Disagreements' },
                { value: 'Agreements', label: 'Agreements' },
                { value: 'Recommendations', label: 'Recommendations' },
                { value: 'Clarifications', label: 'Clarifications' }

            ],
            selections: [],
            // selections: ['Disagreements', 'Agreements', 'Recommendations', 'Clarifications'],
            options: [
                { value: 'Disagreements', label: 'Disagreements' },
                { value: 'Agreements', label: 'Agreements' },
                { value: 'Recommendations', label: 'Recommendations' },
                { value: 'Clarifications', label: 'Clarifications' }

            ], 
            liveRegionContent: '',
            ariaLiveMessage: ''

        };

    }
    setup() {
        let restricted = this.props.restricted == 'true' ? 'true' : 'false'

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user: this.props.user,
                restricted: restricted,
            })
        };
        fetch('/api/docketIds', requestOptions)
            .then((response) => response.json())
            .then((json) => this.setState({
                docketIds: this.docketOptions(json.Items),
                docketOffice: json.Items
            }));
        if (this.props.docketId !== '') {
            this.setState({
                docketId: this.props.docketId,
                docketIdSelected: true,
                office: this.props.office
            })
            this.setTitle(this.props.docketId)
            this.getDarcAnnotations(this.props.docketId)

        }


        // console.log(JSON.stringify(this.state.docketOffice))
    }

    getDarcAnnotations = (id) => {
        // console.log("inside get darc annotations");
        // console.log('DOCKET ID IS HERE' + this.state.docketId)
        const data = {
            user: this.props.user,
            docketId: id
        };

        fetch(`/api/getDarcAnnotations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(json => {
                if (this._isMounted) {
                    // console.log("inside get annotations");
                    // console.log(json);
                    this.setState({
                        data: json,
                        annotations: this.transformAnnotations(json)
                    });
                }
            })
            .catch(error => {
                console.error("Error fetching annotations:", error);
            });
    };

    setTitle = (docket) => {
        console.log("Inside set title")
        fetch(`/api/getDocketTitle/${docket}`)
            .then((response) => {
                if (this._isMounted)
                    // console.log(JSON.stringify(response.json()))
                    return response.json()
            })
            .then((json) => {
                if (this._isMounted) {
                    // console.log(json)
                    this.setState({ docketTitle: json.Items[0].title })
                }
            });

    }

    componentDidMount() {

        this._isMounted = true;
        document.title = 'My Annotations'; // Replace with your default title

        this.setup()
    }

    componentDidUpdate(pp, ps) {
        if (ps.docketId !== this.state.docketId) {
            this.setState({
                review: false,
                flag: false
            })
            // console.log("component updated")

        }
        // console.log(this.state.data)
    }
    docketOptions = (dockets) => {
        var opts = []
        dockets.forEach((d) => {
            opts.push({ label: d.docket_id, value: d.docket_id });
        });
        // console.log(opts)
        return opts;
    }

    selectDocket = (event) => {
        var id = event.value
        var office = this.state.docketOffice.find(x => x.docket_id === id).office
        this.setState({
            docketId: id,
            docketIdSelected: true,
            office: office,
            review: false,
            flag: false

        })
        fetch(`/api/getDocketTitle/${id}`)
            .then((response) => {
                if (this._isMounted)
                    return response.json()
            })
            .then((json) => {
                if (this._isMounted) {
                    // console.log(json)
                    this.setState({ docketTitle: json.Items[0].title })
                }
            });
        this.getDarcAnnotations(event.value)

    }
    setSelectedTypes = (types) => {
        // console.log("Setting selected types" + types)
        this.setState({ selectedTypes: types })
    }

    updateSelectionList(e, value) {
        // console.log(e.target.checked)
        let announcement = e.target.checked
        ? `Checkbox ${value} is checked.`
        : `Checkbox ${value} is unchecked.`;
        if (e.target.checked) {
            console.log(value + "was checked")
            this.setState({
                selections: this.state.selections.concat([value])
            })
        } else {
            //remove from array
            this.setState({
                selections: this.state.selections.filter(function (val) { return val !== value })
            })
        }
        this.setState({ liveRegionContent: announcement }, () => {
            // After updating the content, focus on the live region to ensure screen reader users notice the change.
            document.getElementById('live-region').focus();
          });
    }
    transformAnnotations = (feedback) => {
        const transformFeedback = (data) => {
            let result = [];

            for (const entry of data) {
                const feedbacks = JSON.parse(entry.feedback);
                const [docket_id, att_num] = entry.comment_id_att.split('_');

                const groupedFeedbacks = feedbacks.reduce((acc, feedback) => {
                    if (!acc[feedback.tag]) acc[feedback.tag] = [];
                    acc[feedback.tag].push(feedback.text);
                    return acc;
                }, {});

                for (const feedbackType in groupedFeedbacks) {
                    for (const text of groupedFeedbacks[feedbackType]) {
                        const feedbackObj = {
                            CommentID: docket_id,
                            AttachmentNum: att_num,
                            Disagreement: '',
                            Agreement: '',
                            Recommendation: '',
                            Clarification: ''
                        };

                        feedbackObj[feedbackType] = text;
                        result.push(feedbackObj);
                    }
                }
            }

            return result;
        };


        const transformedData = transformFeedback(feedback);
        // console.log(JSON.stringify(transformedData))
        return transformedData
    }
    renderCellContent(fieldData) {
        return fieldData.map((entry, idx) => (
            <div key={idx} style={{ marginBottom: '8px' }}>
                {`${idx + 1}. ${entry}`}
            </div>
        ));
    }
    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    }

    countInstances = (data) => {

        let recommendations = 0;
        let agreements = 0;
        let disagreements = 0;
        let clarifications = 0;

        for (let comment of data) {
            recommendations += comment.Recommendation.length;
            agreements += comment.Agreement.length;
            disagreements += comment.Disagreement.length;
            clarifications += comment.Clarification.length;
        }


        return {
            recommendations,
            agreements,
            disagreements,
            clarifications
        };

    }

    hasDataForSelections(data) {
        for (let item of data) {
            for (let selection of this.state.selections) {
                if (Array.isArray(item[selection]) && item[selection].length > 0) {
                    // console.log('data exists')

                    return true;  // Data exists for one of the selections
                }
            }
        }
        return false;  // No data found for the selected columns
    }

    filterObject = (comments, selections) => {
        let filteredComments = [];
        filteredComments.push({ "Docket ID": this.state.docketId })
        filteredComments.push({ "Docket Title": this.state.docketTitle })

        for (let comment of comments) {
            for (let selection of selections) {
                if (selection === "Agreements") {
                    if (comment.Agreement !== "") {
                        filteredComments.push(comment);
                    }
                } else if (selection === "Disagreements") {
                    if (comment.Disagreement !== "") {
                        filteredComments.push(comment);
                    }
                } else if (selection === "Recommendations") {
                    if (comment.Recommendation !== "") {
                        filteredComments.push(comment);
                    }
                } else if (selection === "Clarifications") {
                    if (comment.Clarification !== "") {
                        filteredComments.push(comment);
                    }
                }
            }
        }

        return filteredComments;
    }
    updateAriaLiveRegion = (message) => {
        this.setState({ariaLiveMessage: message});
    };
    render() {
      
        // console.log(this.state.selections)
        const { selectedOptions } = this.state;

        var spacing = "tab-bottom-sel"
        this.props.docketId === '' ? spacing += '-nodocket' : spacing += ''
        // console.log(this.state.selections)


        const mergedData = this.state.annotations.reduce((acc, item) => {
            const index = acc.findIndex(x => x.CommentID === item.CommentID && x.AttachmentNum === item.AttachmentNum);
            if (index > -1) {
                ['Disagreement', 'Agreement', 'Recommendation', 'Clarification'].forEach(field => {
                    if (item[field]) {
                        // Instead of directly appending, we add the entry as an array item
                        acc[index][field].push(item[field]);
                    }
                });
            } else {
                // Initially setting each field as an array
                acc.push({
                    ...item,
                    Disagreement: item.Disagreement ? [item.Disagreement] : [],
                    Agreement: item.Agreement ? [item.Agreement] : [],
                    Recommendation: item.Recommendation ? [item.Recommendation] : [],
                    Clarification: item.Clarification ? [item.Clarification] : [],
                });
            }
            return acc;
        }, []);
        const sortedData = mergedData.sort((a, b) => a.CommentID.localeCompare(b.CommentID));
        // console.log("DOES DATA EXIST" + this.hasDataForSelections(sortedData))

        // console.log(sortedData)
        // console.log(this.state.options)

        const numInstances = this.countInstances(sortedData)
        // console.log(numInstances)
        // console.log("annotations here" + JSON.stringify(this.state.annotations))

        if (!this.state.docketIdSelected) {
            return (<div className="tab-top">
                  <div className="sr-only" aria-live="polite" id="live-region">
            {this.state.liveRegionContent}
        </div>
                <Row>
                    <Col>
                        <h1 className="level-0-headings" tabIndex="0" >My DARC Annotations</h1>
                        <p >Select a docket to see your annotations for that docket</p>
                        <div style={{ width: "70%" }}>
                            <FilterSelect
                                placeholder={'--Select--'}
                                selection={this.state.docketId}
                                selectItem={this.selectDocket}
                                selections={this.state.docketIds}
                                label="Select a docket to see your annotations for that docket"

                            />

                        </div>
                    </Col>
                    <Col md={8}>
                        {/* <div style={{ paddingTop: "25px", paddingBottom: "15px" }}>
                            <span ><span tabIndex="0" style={{ color: "#1A4480" }}>Active:</span> {this.state.docketIdSelected ? <span tabIndex="0" style={{ color: "#1A4480" }}><b>{this.state.docketId}</b> <span tabIndex="0" style={{ display: "flex" }}> {this.state.docketTitle}</span></span> : <span tabIndex="0" style={{ color: "#1A4480" }}>None</span>}</span>
                        </div> */}

                        <DocketSelected docketId={this.state.docketId} docketTitle={this.state.docketTitle}></DocketSelected>



                    </Col>
                </Row>
                {/* <div>{JSON.stringify(this.state.annotations)}</div> */}

                {/* <CustomTable data={this.state.annotations} /> */}
                <div style={{ maxWidth: "700px", marginTop: "50px", display: "flex" }}

                >
                    <div style={{ fontWeight: "bold", margin: "10px" }}>View Selection</div>

                    <div className="vl"></div>

                    <fieldset>
                        <legend className="sr-only">View DARC (Disagreement, Agreement, Recommendation, Clarification) Annotations </legend>


                        <Choice
                            label="Disagreements"
                            name="disagreements"
                            type="checkbox"
                            disabled={true}
                            onChange={e => this.updateSelectionList(e, 'Disagreements')}
                            onKeyPress={e => this.updateSelectionList(e, 'Disagreements')}
                            checked={this.state.selections.includes('Disagreements') && numInstances['disagreements'] > 0}

                        ></Choice>
                        <div style={{ margin: "1%" }} />
                        <Choice
                            label="Agreements"
                            name="Agreements"
                            type="checkbox"
                            disabled={true}
                            onChange={e => this.updateSelectionList(e, 'Agreements')}
                            onKeyPress={e => this.updateSelectionList(e, 'Agreements')}
                            checked={this.state.selections.includes('Agreements') && numInstances['agreements'] > 0}
                        ></Choice>
                        <div style={{ margin: "1%" }} />

                        <Choice
                            label="Recommendations"
                            name="Recommendations"
                            type="checkbox"
                            disabled={true}
                            onChange={e => this.updateSelectionList(e, 'Recommendations')}
                            onKeyPress={e => this.updateSelectionList(e, 'Recommendations')}
                            checked={this.state.selections.includes('Recommendations') && numInstances['recommendations'] > 0}

                        ></Choice>
                        <div style={{ margin: "1%" }} />
                        <Choice
                            label="Clarifications"
                            name="Clarifications"
                            type="checkbox"
                            disabled={true}
                            onChange={e => this.updateSelectionList(e, 'Clarifications')}
                            onKeyPress={e => this.updateSelectionList(e, 'Clarifications')}
                            checked={this.state.selections.includes('Clarifications') && numInstances['clarifications'] > 0}
                        ></Choice>

                    </fieldset>
                </div>


                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Comment ID</th>
                            <th>Att #</th>
                            <th>Disagreements</th>
                            <th>Agreements</th>
                            <th>Recommendations</th>
                            <th>Clarifications</th>
                        </tr>
                    </thead>
                </table>
                <Alert variant="info" style={{ backgroundColor: "#E7F6F8" }}>
                    <Alert.Heading><svg style={{ marginRight: "1%" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                    </svg>Select a Docket</Alert.Heading>
                    <p>
                        Currently you have no Docket selected. Please use the dropdown above to select the Docket you would like to see the Disagreements, Agreements, Recommendations or Clarifications for in this table.                       </p>
                </Alert>
            </div>)
        } else {
            if (numInstances['recommendations'] === 0 && numInstances['agreements'] === 0 && numInstances['disagreements'] === 0 && numInstances['clarifications'] === 0) {
                return (
                
                    <><div className="sr-only" aria-live="assertive" id="aria-live-region">
                    No DARC Annotations for docket + {this.props.docketId}
                    </div><div className="tab-top">
                            <div className="sr-only" aria-live="polite" id="live-region">
                                {this.state.liveRegionContent}
                            </div>
                            <Row>
                                <Col>
                                    <p className="level-0-headings">My DARC Annotations</p>
                                    <p>Select a docket to see your annotations for that docket</p>
                                    <div style={{ width: "70%" }}>
                                        <FilterSelect
                                            placeholder={'--Select--'}
                                            selection={this.state.docketId}
                                            selectItem={this.selectDocket}
                                            selections={this.state.docketIds}
                                            label="Select a docket to see your annotations for that docket" />

                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div style={{ paddingTop: "25px", paddingBottom: "15px" }}>
                                        <DocketSelected docketId={this.state.docketId} docketTitle={this.state.docketTitle}></DocketSelected>
                                    </div>


                                </Col>
                            </Row>
                            {/* <div>{JSON.stringify(this.state.annotations)}</div> */}

                            {/* <CustomTable data={this.state.annotations} /> */}
                            <div style={{ maxWidth: "700px", marginTop: "50px", display: "flex" }}

                            >

                                <div style={{ fontWeight: "bold", margin: "10px" }}>View Selection</div>
                                <div className="vl"></div><Choice
                                    label="Disagreements"
                                    name="disagreements"
                                    type="checkbox"
                                    disabled={true}
                                    onChange={e => this.updateSelectionList(e, 'Disagreements')}
                                    onKeyPress={e => this.updateSelectionList(e, 'Disagreements')}
                                    checked={this.state.selections.includes('Disagreements') && numInstances['disagreements'] > 0}

                                ></Choice>
                                <div style={{ margin: "1%" }} />
                                <Choice
                                    label="Agreements"
                                    name="Agreements"
                                    type="checkbox"
                                    disabled={true}
                                    onChange={e => this.updateSelectionList(e, 'Agreements')}
                                    onKeyPress={e => this.updateSelectionList(e, 'Agreements')}
                                    checked={this.state.selections.includes('Agreements') && numInstances['agreements'] > 0}
                                ></Choice>
                                <div style={{ margin: "1%" }} />

                                <Choice
                                    label="Recommendations"
                                    name="Recommendations"
                                    type="checkbox"
                                    disabled={true}
                                    onChange={e => this.updateSelectionList(e, 'Recommendations')}
                                    onKeyPress={e => this.updateSelectionList(e, 'Recommendations')}
                                    checked={this.state.selections.includes('Recommendations') && numInstances['recommendations'] > 0}

                                ></Choice>
                                <div style={{ margin: "1%" }} />
                                <Choice
                                    label="Clarifications"
                                    name="Clarifications"
                                    type="checkbox"
                                    disabled={true}
                                    onChange={e => this.updateSelectionList(e, 'Clarifications')}
                                    onKeyPress={e => this.updateSelectionList(e, 'Clarifications')}
                                    checked={this.state.selections.includes('Clarifications') && numInstances['clarifications'] > 0}
                                ></Choice>
                            </div>
                            <table className="table table-striped">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>Comment ID</th>
                                        <th>Att #</th>
                                        <th>Disagreements</th>
                                        <th>Agreements</th>
                                        <th>Recommendations</th>
                                        <th>Clarifications</th>
                                    </tr>
                                </thead>
                            </table>
                            <Alert variant="info" style={{ backgroundColor: "#F4E3DB", borderColor: "red" }}>
                                <Alert.Heading><svg style={{ marginRight: "1%", }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>No DARC Annotations</Alert.Heading>
                                <p>
                                    Currently you have no Disagreements, Agreements, Recommendations or Clarifications annotations for this docket.</p>
                            </Alert>
                        </div></>)
            } else {
                return (


                    <>
                      <div className="sr-only" aria-live="polite" id="live-region">
            {this.state.liveRegionContent}
        </div>
                        <div className="tab-top">
                            <Row>
                                <Col>
                                    <p className="level-0-headings" >My DARC Annotations</p>
                                    <p >Select a docket to see your annotations for that docket</p>
                                    <div style={{ width: "70%" }}>
                                        <FilterSelect
                                            placeholder={'--Select--'}
                                            selection={this.state.docketId}
                                            selectItem={this.selectDocket}
                                            selections={this.state.docketIds}
                                            label="Select a docket to see your annotations for that docket"

                                        />

                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div style={{ paddingTop: "25px", paddingBottom: "15px" }}>
                                        <span ><span tabIndex="0" style={{ color: "#1A4480" }}>Active:</span> {this.state.docketIdSelected ? <span tabIndex="0" style={{ color: "#1A4480" }}><b>{this.state.docketId}</b> <span tabIndex="0" style={{ display: "flex" }}> {this.state.docketTitle}</span></span> : <span tabIndex="0" style={{ color: "#1A4480" }}>None</span>}</span>
                                    </div>


                                </Col>
                            </Row>
                            {/* <div>{JSON.stringify(this.state.annotations)}</div> */}

                            {/* <CustomTable data={this.state.annotations} /> */}
                            <div style={{ maxWidth: "700px", marginTop: "50px", display: "flex" }}

                            >

                                <div style={{ fontWeight: "bold", margin: "10px" }}>View Selection</div>
                                <div className="vl"></div><Choice
                                    label="Disagreements"
                                    name="disagreements"
                                    type="checkbox"
                                    disabled={!this.state.docketIdSelected || numInstances['disagreements'] === 0}
                                    onChange={e => this.updateSelectionList(e, 'Disagreements')}
                                    onKeyPress={e => this.updateSelectionList(e, 'Disagreements')}
                                    checked={this.state.selections.includes('Disagreements') && numInstances['disagreements'] > 0}

                                ></Choice>
                                <div style={{ margin: "1%" }} />
                                <Choice
                                    label="Agreements"
                                    name="Agreements"
                                    type="checkbox"
                                    disabled={!this.state.docketIdSelected || numInstances['agreements'] === 0}
                                    onChange={e => this.updateSelectionList(e, 'Agreements')}
                                    onKeyPress={e => this.updateSelectionList(e, 'Agreements')}
                                    checked={this.state.selections.includes('Agreements') && numInstances['agreements'] > 0}
                                ></Choice>
                                <div style={{ margin: "1%" }} />

                                <Choice
                                    label="Recommendations"
                                    name="Recommendations"
                                    type="checkbox"
                                    disabled={!this.state.docketIdSelected || numInstances['recommendations'] === 0}
                                    onChange={e => this.updateSelectionList(e, 'Recommendations')}
                                    onKeyPress={e => this.updateSelectionList(e, 'Recommendations')}
                                    checked={this.state.selections.includes('Recommendations') && numInstances['recommendations'] > 0}

                                ></Choice>
                                <div style={{ margin: "1%" }} />
                                <Choice
                                    label="Clarifications"
                                    name="Clarifications"
                                    type="checkbox"
                                    disabled={!this.state.docketIdSelected || numInstances['clarifications'] === 0}
                                    onChange={e => this.updateSelectionList(e, 'Clarifications')}
                                    onKeyPress={e => this.updateSelectionList(e, 'Clarifications')}
                                    checked={this.state.selections.includes('Clarifications') && numInstances['clarifications'] > 0}
                                ></Choice>

                                <div style={{ float: "right", marginLeft: "15%", width: "200px" }}>
                                    <p style={{ fontWeight: "bold" }}><span style={{ fontSize: "24px" }}>{numInstances['disagreements']}</span> <span style={{ fontSize: "14px" }}>Disagreements found</span></p>
                                    <p style={{ fontWeight: "bold" }}><span style={{ fontSize: "24px" }}>{numInstances['agreements']}</span><span style={{ fontSize: "14px" }}> Agreements found</span></p>




                                </div>

                                <div style={{ float: "right", marginLeft: "15%" }}>
                                    <p style={{ fontWeight: "bold" }}><span style={{ fontSize: "24px" }}>{numInstances['recommendations']}</span><span style={{ fontSize: "14px" }}> Recommendations found</span></p>
                                    <p style={{ fontWeight: "bold" }}><span style={{ fontSize: "24px" }}>{numInstances['clarifications']}</span><span style={{ fontSize: "14px" }}> Clarifications found</span></p>

                                </div>





                            </div>



                            <div style={{ fontWeight: "bold", paddingBottom: "16px" }}>DARC |  Below are the Comments that have Disagreement, Agreement, Recommendation, and/or Clarification (DARC) annotations.
                                <DownloadAnnotations
                                    docketId={this.state.docketId}
                                    office={this.props.office}
                                    docketTitle={this.state.docketTitle}
                                    data={this.filterObject(this.state.annotations, this.state.selections)}


                                />
                            </div>
                            {this.state.selections.length === 0 ?
                                <>
                                    <table className="table table-striped">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th>Comment ID</th>
                                                <th>Att #</th>
                                                <th>Disagreements</th>
                                                <th>Agreements</th>
                                                <th>Recommendations</th>
                                                <th>Clarifications</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </table>
                                    <Alert variant="info" style={{ backgroundColor: "#E7F6F8" }}>
                                        <Alert.Heading><svg style={{ marginRight: "1%" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                        </svg>Select a Checkbox</Alert.Heading>
                                        <p>
                                            Currently you have no checkboxes selected. Please use the checkboxes above to see the Disagreements, Agreements, Recommendations or Clarifications in this table.                             </p>
                                    </Alert>
                                </>


                                :
                                <table className="table" >
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>Comment ID</th>
                                            <th>Att #</th>
                                            {this.state.selections.includes('Disagreements') && numInstances['disagreements'] > 0 && <th>Disagreements</th>}
                                            {this.state.selections.includes('Agreements') && numInstances['agreements'] > 0 && <th>Agreements</th>}
                                            {this.state.selections.includes('Recommendations') && numInstances['recommendations'] > 0 && <th>Recommendations</th>}
                                            {this.state.selections.includes('Clarifications') && numInstances['clarifications'] > 0 && <th>Clarifications</th>}
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {sortedData.map((item) => (
                                            <tr key={item.CommentID.split('-').pop()}>
                                                <td>{item.CommentID.split('-').pop()}</td>
                                                <td>{item.AttachmentNum}</td>
                                                {this.state.selections.includes('Disagreements') &&
                                                    <td style={{ maxWidth: '200px' }}>
                                                        <div style={{ height: '150px', overflowY: 'scroll' }}>
                                                            {this.renderCellContent(item.Disagreement)}
                                                        </div>
                                                    </td>}
                                                {this.state.selections.includes('Agreements') &&
                                                    <td style={{ maxWidth: '200px' }}>
                                                        <div style={{ height: '150px', overflowY: 'scroll' }}>
                                                            {this.renderCellContent(item.Agreement)}
                                                        </div>
                                                    </td>}
                                                {this.state.selections.includes('Recommendations') &&
                                                    <td style={{ maxWidth: '200px' }}>
                                                        <div style={{ height: '150px', overflowY: 'scroll' }}>
                                                            {this.renderCellContent(item.Recommendation)}
                                                        </div>
                                                    </td>}
                                                {this.state.selections.includes('Clarifications') &&

                                                    <td style={{ maxWidth: '200px' }}>
                                                        <div style={{ height: '150px', overflowY: 'scroll' }}>
                                                            {this.renderCellContent(item.Clarification)}
                                                        </div>
                                                    </td>}
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>


                            }

                        </div>
                        <div className={spacing}>

                        </div>
                    </>
                )
            }
        };
    }
}
export default MyAnnotations;