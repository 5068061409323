import React, { Component } from 'react';

import CommentReview from '../sharedComponents/CommentReview';
import CommentFlag from '../sharedComponents/CommentFlag';
import SortControl from './SortControl';
import DownloadCommentList from './DownloadCommentList';
import { Button } from '@cmsgov/design-system';
const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
};

const thStyle = {
    backgroundColor: '#f2f2f2',
    padding: '8px',
    paddingBottom: '5px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    whiteSpace: 'nowrap'

};

const thStyleSort = {
    backgroundColor: '#f2f2f2',
    padding: '8px',
    paddingBottom: '1px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    whiteSpace: 'nowrap'



};
const thStyleSortST = {
    backgroundColor: '#f2f2f2',
    padding: '8px',
    paddingBottom: '1px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    whiteSpace: 'nowrap',
    paddingBottom: '5px',
    paddingTop: '0px'

};
const tdStyle = {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
};

const containerStyle = {
    display: 'inline-flex',
    alignItems: 'center', // Align items vertically in the center
};

class CommentAccordion extends Component {

    constructor() {
        super();

        this.state = {
            expandedRows: [],
            comments: [],
            attachments: [],
            selected: ''
        };
    }
    _isMounted = false;


    getData = (col, dir) => {
        // this._isMounted = true;
        var start = this.props.page * 15 + 1;
        var stop = start + 15 - 1;
        col = col ? col : this.props.sortOption.col;
        dir = dir ? dir : this.props.sortOption.dir;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                docketId: this.props.docketId,
                filters: JSON.stringify(this.props.filters).replaceAll("'", "%27"),
                start: start,
                stop: stop,
                sortOption: {
                    col: col,
                    dir: dir,
                },
            })
        };
        fetch('/api/' + this.props.office + '/comments', requestOptions)
            .then((response) => {
                // if (this._isMounted)
                    return response.json()
            })
            .then((json) => {
                // if (this._isMounted)
                this.props.setComments(json)
                this.props.setChain(json)
                this.setState({
                    comments: json
                })
            });
        fetch('/api/' + this.props.office + '/count', requestOptions)
            .then((response) => {
                // if (this._isMounted)
                    return response.json()
            })
            .then((json) => {
                // if (this._isMounted)
                    this.props.setCount(json)
                this.props.setCountLoaded(true);

            });
        this.setState({ expandedRows: [] })

    }
    componentDidMount() {
        // if (this.props.office !== '' && this.props.docketId !== '') {
        //     this.getData();
        // }
    }


    componentDidUpdate(prevProps, prevState) {
        this._isMounted = true;

        if (JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters)
            || this.props.page !== prevProps.page
            || this.props.docketId !== prevProps.docketId
            || JSON.stringify(prevState.sortOption) !== JSON.stringify(this.state.sortOption)
            || this.props.sortOption != prevProps.sortOption
            || (this.state.expandedRows.length !== prevState.expandedRows.length) && this.state.expandedRows.every((el, ix) => el === prevState.expandedRows[ix])
            || (this.state.attachments.length !== prevState.attachments.length) && this.state.attachments.every((el, ix) => el === prevState.attachments[ix])) {


            this.getData();
        } else {
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    changePage = (i) => {
        var cp = (i === '-')
            ? this.props.page - 1
            : (i === '+')
                ? this.props.page + 1
                : parseInt(i) - 1;
        var pages = Math.ceil(this.props.count / 15);
        if (cp >= 0 && cp < pages) {
            this.props.setPage(cp);
        }
    }

    clearFilters = () => {
        this.props.setSort("cidn", "asc")
        this.changePage(1);
        this.props.clearFilters()
    }
    getPrimaryOrg = (word) => {
        var fixed = '';
        word == 'See Attachment' ? fixed = '*See Attachment' : fixed = word
        word == 'Not Found' ? fixed = '' : fixed = word
        return fixed;

    }
    getStakeholder = (word) => {
        var fixed = '';
        word == 'Not Available' ? fixed = '' : fixed = word
        return fixed;

    }
    getSetting = (word) => {
        var fixed = '';
        word == 'Not Available' ? fixed = '' : fixed = word
        return fixed;

    }


    getAttDetails = (cid, rows) => {
        /*Get the attachment rows for this comment ID*/
        if (rows.includes(cid)) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    docketId: this.props.docketId,
                    office: this.props.office,
                    commentId: cid
                })
            };
            fetch('/api/' + this.props.office + '/commentAttachments', requestOptions)
                .then((response) => {
                    if (this._isMounted)
                        return response.json()
                })
                .then((json) => {
                    if (this._isMounted) {
                        var atts = this.state.attachments
                        json.splice(0, 1)
                        atts.push({ key: cid, value: json })
                        this.setState({ attachments: atts })
                    }
                });
        }

    }
    handleExpand = cmt => {
        let newExpandedRows = [...this.state.expandedRows];
        let idxFound = newExpandedRows.findIndex(id => {
            return id === cmt.comment_id;
        });

        if (idxFound > -1) {
            newExpandedRows.splice(idxFound, 1);
        } else {
            newExpandedRows.push(cmt.comment_id);
        }
        this.setState({ expandedRows: [...newExpandedRows] });
        this.getAttDetails(cmt.comment_id, newExpandedRows)
    };

    isExpanded = cmt => {
        var idx = this.state.expandedRows.includes(cmt.comment_id)
        return idx;
    };

    expandAll = cmts => {
        if (this.state.expandedRows.length === cmts.length) {
            let newExpandedRows = [];
            this.setState({ expandedRows: [...newExpandedRows] });
        } else {
            let newExpandedRows = cmts.map(cmt => cmt.comment_id);
            this.setState({ expandedRows: [...newExpandedRows] });

        }
    };

    getRows = cmt => {
        let rows = [];
        var projects = cmt.atts;

        const firstRow = (

            <tr className={this.isExpanded(cmt) ? "expanded-cmt" : "nonexpand-cmt"}>
                <td style={tdStyle}>
                    {projects > 0 && (
                        <button aria-label="expand comment attachments" type="button" onClick={() => this.handleExpand(cmt)}>
                            {this.isExpanded(cmt) ? <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                            </svg> : <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            </svg>}
                        </button>
                    )}
                </td>
                <td style={tdStyle}><CommentReview
                    user={this.props.user} office={this.props.office} docketId={this.props.docketId} commentId={cmt.comment_id} comment={cmt.comment} />
                    <br /><span><CommentFlag

                        user={this.props.user}
                        docketId={this.props.docketId}
                        office={this.props.office}
                        commentId={cmt.comment_id}
                        attNum={0}
                        comment={cmt.comment.substring(0, 300)} /><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-flag-fill" viewBox="0 0 16 16">
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                        </svg>
                    </span></td>
                <td style={tdStyle}
                    id={cmt.comment_id}
                    aria-label={`Navigate to Comment Details for ID ${cmt.comment_id}`}
                    md="auto"
                    className="docket-id-list"
                    >

                    <button
                        onClick={() => this.props.setCommentId(cmt.comment_id, 'Comment Details', 0)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                this.props.setCommentId(cmt.comment_id, 'Comment Details', 0);
                            }}}
                        style={{
                            border: 'none',
                            background: 'none',
                            padding: '0',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: 'blue',
                            fontSize: '1em',
                            outline: 'none', // Remove the default focus outline
                        }}
                        onFocus={(event) => {
                            event.target.style.boxShadow = '0 0 0 2px #007BFF'; // Add focus outline when focused
                        }}
                        onBlur={(event) => {
                            event.target.style.boxShadow = 'none'; // Remove focus outline when blurred
                        }}
                        tabIndex="0" // Make the button focusable
                    >
                        {cmt.comment_number}
                    </button>

                </td>
                <td style={tdStyle} id={cmt.comment_id + '-' + cmt.atts} >{cmt.atts}</td>
                <td style={tdStyle} id={"attachment-0-of " + cmt.comment_id} >{cmt.comment}</td>
                <td style={tdStyle} id={"submitter-organization-for-" + cmt.comment_id} >{cmt.primary_org}</td>
                <td style={tdStyle} id={"stakeholder-type-for-" + cmt.comment_id} >{cmt.stakeholder_type}</td>
                <td style={tdStyle} id={"setting-of-care-for-" + cmt.comment_id} >{cmt.setting_of_care}</td>
                <td style={tdStyle} id={"specialty-for-" + cmt.comment_id} >{cmt.specialty}</td>


            </tr>
        );

        rows.push(firstRow);
        if (this.isExpanded(cmt) && projects > 0) {
            var itemRows = []
            var atts = this.state.attachments.filter(function (entry) {
                return entry.key === cmt.comment_id;
            });
            if (atts.length !== 0) {
                atts[0].value.forEach(att => {
                    itemRows.push(
                        <tr className="expanded-cell" key={"row-expanded-" + cmt.comment_id}>
                            <td ></td>

                            <td ><span><CommentFlag

                                user={this.props.user}
                                docketId={this.props.docketId}
                                office={this.props.office}
                                commentId={cmt.comment_id}
                                attNum={att.att_num}
                                comment={att.comment === " " ? 'Text for file unavailable' : att.comment.substring(0, 300)} />
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-flag-fill" viewBox="0 0 16 16">
                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                </svg></span></td>
                            <td></td>
                            <td ><button
                                onClick={() => this.props.setCommentId(cmt.comment_id, 'Comment Details', att.att_num)}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.props.setCommentId(cmt.comment_id, 'Comment Details', att.att_num);
                                    }}}
                                style={{
                                    border: 'none', // Remove button border
                                    background: 'none', // Remove button background
                                    padding: '0', // Remove button padding
                                    textDecoration: 'underline', // Add underline to make it look like a link
                                    cursor: 'pointer', // Change the cursor to indicate it's clickable
                                    color: 'blue', // Set the link color
                                    fontSize: "1em"
                                }}
                            >{att.att_num}</button></td>
                            <td >{att.comment === " " ? 'Text for file unavailable' : att.comment} </td>
                            <td >{att.primary_org}</td>
                            <td >{att.stakeholder_type}</td>
                            <td > {att.setting_of_care}</td>
                            <td  >{att.specialty}</td>


                        </tr>
                    );
                })
            }
            rows.push(itemRows);
        }
        return rows;
    };
    getcmtTable = cmts => {
        const cmtRows = cmts.map(cmt => {
            return this.getRows(cmt);
        });

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ paddingBottom: '5px' }}>
                        <b>Click on a comment below to open it. Add a comment to a “Selection” by clicking on the box to the left of “Review” or “Flag” in the “Selection” column.</b>
                    </p>
                    <DownloadCommentList
                        download={this.props.download}
                        docketId={this.props.docketId}
                        office={this.props.office}
                        docketTitle={this.props.docketTitle}
                        filters={this.props.filters}
                        sortOption={this.props.sortOption}
                    />
                </div>
                <table className="my-table" style={{width: "100%"}}>
                    <thead>

                        <tr >
                            <th style={thStyle}><svg label="expand" aria-label="Click Icon in row to Expand Comment" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            </svg></th>
                            <th style={thStyle}>Selection</th>
                            <th style={thStyleSort}> ID    <SortControl
                                onOrderChange={(order) => {
                                    this.props.setSort("cidn", order);
                                    // this.changePage(1);
                                }}
                                order={this.props.sortOption.col === "cidn" ? this.props.sortOption.dir : "asc"}
                                sortColumn={this.props.sortOption.col} // Pass the current sorting column
                                columnName="comment ID" // Specify the column name for this SortControl
                            /></th>
                            <th style={thStyleSort}>
                                <span style={{ marginRight: '8px' }}>#Att</span>
                                <div style={{ display: 'inline-block' }}>
                                    <SortControl
                                        onOrderChange={(order) => {
                                            this.props.setSort('atts', order);
                                            // this.changePage(1);
                                        }}
                                        order={
                                            this.props.sortOption.col === 'atts'
                                                ? this.props.sortOption.dir
                                                : 'asc'
                                        }
                                        sortColumn={this.props.sortOption.col} // Pass the current sorting column
                                        columnName="attachments" // Specify the column name for this SortControl
                                    />
                                </div>
                            </th>

                            <th style={thStyle}>Comment</th>
                            <th style={thStyleSort}>Organization
                            
                            {/* <SortControl
                                        onOrderChange={(order) => {
                                            this.props.setSort('primary_org', order);
                                            // this.changePage(1);
                                        }}
                                        order={
                                            this.props.sortOption.col === 'primary_org'
                                                ? this.props.sortOption.dir
                                                : 'asc'
                                        }
                                        sortColumn={this.props.sortOption.col} // Pass the current sorting column
                                        columnName="primary_org" // Specify the column name for this SortControl
                                    /> */}
                                    
                                    </th>
                            <th style={thStyleSort}>
                                <span>Stakeholder Type       </span>


                                <div style={containerStyle}>
                                    {/* <SortControl
                                        onOrderChange={(order) => {
                                            this.props.setSort("stakeholder_type", order);
                                            // this.changePage(1);
                                        }}
                                        order={this.props.sortOption.col === "stakeholder_type" ? this.props.sortOption.dir : "asc"}
                                        sortColumn={this.props.sortOption.col} // Pass the current sorting column
                                        columnName="stakeholder_type" // Specify the column name for this SortControl
                                    /> */}
                                    
                                    </div></th>
                            <th style={thStyleSort}>Setting of Care
                                {/* <SortControl
                                    onOrderChange={(order) => {
                                        this.props.setSort("setting_of_care", order);
                                        // this.changePage(1);
                                    }}
                                    order={this.props.sortOption.col === "setting_of_care" ? this.props.sortOption.dir : "asc"}
                                    sortColumn={this.props.sortOption.col} // Pass the current sorting column
                                    columnName="setting_of_care" // Specify the column name for this SortControl
                                /> */}
                            </th>
                            <th style={thStyleSort}>Specialty
                                {/* <SortControl
                                    onOrderChange={(order) => {
                                        this.props.setSort("specialty", order);
                                        // this.changePage(1);
                                    }}
                                    order={this.props.sortOption.col === "specialty" ? this.props.sortOption.dir : "asc"}
                                    sortColumn={this.props.sortOption.col} // Pass the current sorting column
                                    columnName="specialty" // Specify the column name for this SortControl
                                /> */}
                            </th>


                        </tr>
                    </thead>

                    <tbody>{cmtRows}</tbody>
                </table>
            </>
        );
    };
    render() {
        var space = "tab-bottom-commentlist"
        if (this.props.commentId !== '') {
            space += '-commentlist-more'
        }
        var space = "tab-bottom-commentlist"
        return (
            <div >

                <div>{this.getcmtTable(this.state.comments)}</div>
            </div>
        );
    }
}
export default CommentAccordion;