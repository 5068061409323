import React, { useState } from 'react';
import { Col, Container, Button, Form, Card } from 'react-bootstrap';
import OffCanvas from '../navigation/OffCanvas';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router';

const Header = (props) => {
  const [showCardBody, setShowCardBody] = useState(false);
  const navigate = useNavigate()
  const toggleApp = () => {

    navigate('/');

    props.adminSwitchApplication();

    toggleSwitch();

  }

  const toggleSwitch = () => {
    setShowCardBody((prevState) => !prevState);
  };

  const office = props.office;
  const user = props.user;

  return (
    <Navbar style={{ borderBottom: "solid .5pt #D3D3D3"}}>
      <Container>
        <Col md={1}>
          <OffCanvas
            setTab={props.setTab}
            activeTab={props.activeTab}
            setDocketId={props.setDocketId}
            setOffice={props.setOffice}
            office={props.office}
            docketId={props.docketId}
            setDocketTitle={props.setDocketTitle}
            docketTitle={props.docketTitle}
            user={props.user}
            restricted={props.restricted}
            filters={props.filters}
            setFilters={props.setFilters}
            clearFilters={props.clearFilters}
            filterSelections={props.filterSelections}
            setFilterSelections={props.setFilterSelections}
            comments={props.comments}
            setComments={props.setComments}
            count={props.count}
            setCount={props.setCommentsCount}
            setChain={props.setChain}
            page={props.page}
            sortOption={props.sortOption}
            setPage={props.setPage}
            setCommentId={props.setCommentId}
            setSort={props.setSort}
            clearFiltersNoKeywords={props.clearFiltersNoKeywords}
            commentId={props.commentId}
            nextComment={props.nextComment}
            sourceLink={props.sourceLink}
            previousComment={props.previousComment}
            handlePrevClick={props.handlePrevClick}
            next={props.next}
            handleNextClick={props.handleNextClick}
            truncateCommentId={props.truncateCommentId}
            setSourceLink={props.setSourceLink}
            filterText={props.filters.text}
            attNum={props.attNum}
            setAttNum={props.setAttNum}
          />
        </Col>
        <Col style={{ padding: 0 }}>
          <div>
            <p style={{ fontSize: "30px", padding: 0, margin: 0 }}>Feedback Analysis System</p>
          </div>
        </Col>
        <div className='title' style={{display: 'flex', alignItems: 'center'}}>
          <div style={{ paddingRight: '30px'}} className="title-user">{`Hi, ${props.userDisplayName}`}{office ? ' | ' + office : ''}</div>
          <Button size="sm" style={{ borderRadius: '0.5rem', backgroundColor: "#005EA2", cursor: 'pointer', marginRight: "10px", paddingTop: '7px', paddingBottom: '6px', border:'none'}} className="btn btn-secondary" onClick={() => props.logInOut()}>
            <div className='bootstrap_icon_align_text'>
              <div style={{ paddingRight: '4px' }}>Logout</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
              </svg>
            </div>
          </Button>
        </div>
        <Col hidden={!props.admin} md={2}>
          <Card className="custom-card">
            <Card.Header style={{ paddingTop: '3px', paddingBottom: '3px', backgroundColor: "#005EA2", color: 'white' }} className="rounded-bottom">
              <div className="d-flex justify-content-between align-items-center">
                <div>Switch Applications</div>
                <div>
                  {showCardBody ? (
                    <button type="button" style={{color: "white"}} aria-expanded="false" className="invisible-button" aria-label="collapse switch applications" onClick={toggleSwitch}  onKeyDown={(event) => { if (event.key === "Enter") { toggleSwitch } }}
					><svg aria-hidden="true" style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    </svg></button>
                  ) : (
                    <button type="button" style={{color: "white"}} aria-expanded="true" className="invisible-button" aria-label="expand switch applications" onClick={toggleSwitch} onKeyDown={(event) => { if (event.key === "Enter") { toggleSwitch } }}><svg aria-hidden="true" style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down ml-auto" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg></button>
                  )}
                </div>
              </div>
            </Card.Header>
            {showCardBody && (
              <Card.Body id="switch-application" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '2rem'
                }}>
                  <div>
                    <Form.Check tabIndex="0" className="custom-radio" value='fas' onChange={toggleApp} checked={props.application === 'fas'} type="radio" aria-label="FAS" label="FAS" />
                  </div>
                  <div>
                    <Form.Check tabIndex="0" className="custom-radio" value='qsog' onChange={toggleApp} checked={props.application === 'qsog'} type="radio" aria-label="2567" label="2567" />
                  </div>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Container>
    </Navbar>
  );
};

export default Header;
