import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import CommentReview from '../sharedComponents/CommentReview';
import CommentPager from './CommentPager';
import AttachmentDetails from './AttachmentDetails';
import AttachmentTable from './AttachmentTable';
import CommentPartTextOrPDF from './CommentPartTextOrPDF';
import DocketSelected from '../docketSelect/DocketSelected';


class CommentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attNumR: 0,
      hasText: true,
      sims: [],
      document: '',
      cnt: '',
      comment: '',
      details: [],
      topics: []

    }
  }
  getData = () => {
    this.setPart(this.props.attNum)
    // console.log("GETTING DATA IN CD" + this.state.attNum)
    this._isMounted = true
    fetch('/api/' + this.props.office + '/getDocument/' + this.props.docketId + '/' + this.props.commentId)
      .then((response) => { return response.json() })
      .then((json) => {
        if (this._isMounted) {
          this.setState({ document: json.Items[0].commentondocumentid });
          // console.log(this.state.comments)
        }
      }
      );
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        group: this.props.office,
        commentId: this.props.commentId,

      })
    };
    this._isMounted = true;
    fetch('/api/' + this.props.office + '/commentForReview', requestOptions)
      .then((response) => {
        if (this._isMounted)
          return response.json()
      })
      .then((json) => {
        if (this._isMounted) {
          this.setState({ comment: json.comment[0].comment })
        }
      });

    fetch('/api/' + this.props.office + '/attachmentDetails/' + this.props.docketId + '/' + this.props.commentId + '/' + this.props.attNum)
      .then((response) => { return response.json() })
      .then((json) => {
        if (this._isMounted) {
          const topicsSorted = json.Items.map(item => item.topic || '').filter(topic => topic !== '').sort();
          this.setState({
            details: json.Items[0],
            topics: topicsSorted
          });

        }
      }
      );


  }

  setPart = (att) => {
    // console.log("SETTING PART" + att)
    // console.log("100")
    this.props.setAttNum(att)
    // this.setState({
    //   attNum: att,
    //   attNumR: 0,
    // });
  };
  setParts = (attl, attr) => {
    this.props.setAttNum(attl)
    // this.setState({
    //   attNum: attl,
    //   attNumR: attr,
    // });
  };
  setHasText = (tf) => {
    this.setState({ hasText: tf })
  }
  setSims = (s) => {
    this.setState({ sims: s });
  }
  setCnt = (c) => {
    this.setState({ cnt: c })
  }
  stripDocketId = (cid) => {
    return cid.replace(this.props.docketId + '-', '');
  }
  componentDidMount() {
    // this.setPart(0);
    this.getData();
    document.title = 'Comment Details'; // Replace with your default title

  }
  componentDidUpdate(pp, ps) {
    if (pp.commentId !== this.props.commentId) {
      // console.log("UPDATING ATTACHMENT")
      // console.log("before" + this.state.attNum)

      this.setPart(0);
      // console.log("after" + this.state.attNum)
      this.getData();

    }
    if (pp.attNum !== this.props.attNum) {
      this.setPart(this.props.attNum);
      this.getData();

    }
  }
  linky = (lk) => {
    let lv = <span>Not Available</span>
    if (typeof lk !== 'undefined' && (lk.startsWith('http://') || lk.startsWith('https://'))) {
      lv = <a aria-label={"Click the Comment ID to be taken to the source regulations.gov page for comment " + this.props.commentId} role="link" href={lk} target="_blank" rel="noopener noreferrer" >{this.props.commentId}</a>
    }
    return lv;
  }
  render() {

    return (
      <>
        <Row>
          <div style={{ display: "inline" }}>
            <div className="tab-top">
              <div className="row">
                <div className="column">
                  <h1 className="level-0-headings">

                    Comment Details
                  </h1 ></div>
                <div className="column">
                  <DocketSelected docketId={this.props.docketId} docketTitle={this.props.docketTitle} />
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Container fluid="true">
          <Row>
            <Col>
              <div>
                <span><b>Comment ID |</b></span>

                <span style={{padding: "5px"}} > {this.linky(this.props.sourceLink)}</span>
                <span style={{paddingLeft: "5px"}}><CommentReview
                  nextComment={this.props.nextComment}
                  user={this.props.user}
                  docketId={this.props.docketId}
                  office={this.props.office}
                  label='Reviewed?'
                  commentId={this.props.commentId}
                  comment={this.state.comment} />

                </span>

              </div>
              <p></p>

            </Col>
            <Col>
              <span style={{ float: 'right' }}>

                <CommentPager
                  previousComment={this.props.previousComment}
                  handlePrevClick={this.props.handlePrevClick}
                  commentId={this.props.commentId}
                  next={this.props.next}
                  handleNextClick={this.props.handleNextClick}
                  truncateCommentId={this.props.truncateCommentId}
                />

              </span >

            </Col>


            <Row style={{paddingTop: "10px"}}>
              <AttachmentDetails
                updateAriaLiveRegion={this.props.updateAriaLiveRegion}
                commentId={this.props.commentId}
                filterText={this.props.filterText}
                office={this.props.office}
                hasText={this.state.hasText}
                attNum={this.props.attNum}
                docketId={this.props.docketId}
                setPart={this.setPart}
                setAttNum={this.props.setAttNum}
                details={this.state.details}
                topics={this.state.topics}
              >

              </AttachmentDetails>
            </Row>
          </Row>
          <Row>
            <Col md={2} style={{ marginTop: "50px" }}>
              <AttachmentTable
                user={this.props.user}
                docketId={this.props.docketId}
                office={this.props.office}
                commentId={this.props.commentId}
                filterText={this.props.filterText}
                setPart={this.setPart}
                setSourceLink={this.props.setSourceLink}
                setHasText={this.setHasText}
                attNum={this.props.attNum}
                nextComment={this.props.nextComment}
                label='Flagged?'
              />

            </Col>
            <Col md={9} style={{ marginTop: "30px" }}>
              {/* <CommentPDForText ></CommentPDForText> */}

              <CommentPartTextOrPDF
                details={this.state.details}
                key={this.props.attNum}
                commentId={this.props.commentId}
                filterText={this.props.filterText}
                office={this.props.office}
                hasText={this.state.hasText}
                attNum={this.props.attNum}
                user={this.props.user}
                docketId={this.props.docketId}
              />

            </Col>
          </Row>

        </Container>



      </>
    );
  };
}
export default CommentDetail;

