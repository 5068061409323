import { useEffect, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { Modal, CloseButton } from 'react-bootstrap';
import { Button } from '@cmsgov/design-system';

export default function App(props) {
  //Activity: 
  // Defined at screen level, includes: using the mouse (clicking, moving, or scrolling), 
  //pressing a key, changing the state of a window between active and inactive, or acknowledging 
  //any warning that might be generated by the operating system’s own timeout functionality.
  
  //Time dealt in Milseconds.
  const timeout = props.maxInactiveTimeBeforeLogout * 60 * 1000;
  const promptBeforeIdle = timeout - (props.maxInactiveTimeBeforeWarning * 60 * 1000);
  
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const { getRemainingTime, activate, reset } = useIdleTimer({
    onIdle: () => {
      setState('Idle');
      setOpen(false);
      props.onTimeout(200, 'Successful');
    },
    onActive: () => {
      setState('Active');
      setOpen(false);
    },
    onPrompt: () => {
      setState('Prompted');
      setOpen(true);
    },
    promptBeforeIdle,
    timeout,
  });

  const handleOnClose = () => {
    props.onTimeout(200, 'Successful');
  }

  const calculateTimeMessage = () => {
    const minutes = Math.floor(remaining / 60);
    const seconds = remaining % 60;
    const minutesText = minutes === 1 ? 'minute' : 'minutes';
    const secondsText = seconds === 1 ? 'second' : 'seconds';

    if (minutes > 0 && seconds > 0) {
      return `${minutes} ${minutesText} and ${seconds} ${secondsText}`;
    } else if (minutes > 0) {
      return `${minutes} ${minutesText}`;
    } else {
      return `${seconds} ${secondsText}`;
    }
  };

  useEffect(() => {
    if (props.ddbData) {
      console.log("ddbData changed from false to true");
      reset();
      console.log('Session timer restarted...');
    }
  }, [props.ddbData, reset]); 


  useEffect(() => {
    setRemaining(Math.ceil(getRemainingTime() / 1000));

    const interval = workerTimers.setInterval(() => {
      const timeLeft = Math.ceil(getRemainingTime() / 1000);
      setRemaining(timeLeft);
      console.log(`Time left until idle: ${timeLeft} seconds`);
    }, 1000);
  
    return () => {
      workerTimers.clearInterval(interval);
    };
  }, []);

  const handleStillHere = () => {
    activate();
    setState('Active');
  };

  return (
    <>
      <Modal show={open} onHide={handleStillHere} backdrop="static">
      <Modal.Header>
				<Modal.Title><strong>Are you still there?</strong></Modal.Title>
				<CloseButton                                 
					type="button"
					aria-label="Hide" 
					onClick={handleStillHere}
					id="hide-button"
				/>
			</Modal.Header>
      <Modal.Body>
					<div style={{fontWeight:400, wordWrap: 'break-word'}}>You have been inactive for a while.</div>
					<div style={{ wordWrap: 'break-word' }}>Your session will end in <strong>{calculateTimeMessage()}</strong>.</div>
					<br/>
					<div style={{ wordWrap: 'break-word' }}>Select "Continue Session" below if you want more time.</div>
			</Modal.Body>
      <Modal.Footer >
				<Button 
					type="button"
					className='comments_button_primary_outline' 
					style={{fontWeight: "700"}} 
					onClick={handleOnClose}
					id="logout-button"
					aria-label='Logout'
					>
					Logout
				</Button>
				<Button 
					type="button"
					className='comments_button_primary'
					style={{fontWeight: "700"}}
					onClick={handleStillHere}
					id="continue-session-button"
					aria-label='Continue session'
					>
					Continue Session
				</Button>
				</Modal.Footer>
      </Modal>
    </>
  );
}