import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import CommentDetail from './CommentDetail';

class CommentHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tst: -1,
            showHide: false,
            ariaLiveMessage: ''
        }
    }

    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }
    linky = (lk) => {
        let lv = <span>Not Available</span>
        if (typeof lk !== 'undefined' && (lk.startsWith('http://') || lk.startsWith('https://'))) {
            lv = <a href={lk} target="_blank" rel="noopener noreferrer" >{lk}</a>
        }
        return lv;
    }
    updateAriaLiveRegion = (message) => {
        this.setState({ariaLiveMessage: message});
    };
    render() {
            return (
                <>
                <div className="sr-only" aria-live="assertive" id="aria-live-region">
                    {this.state.ariaLiveMessage}
                </div>
                    <CommentDetail
                        updateAriaLiveRegion={this.updateAriaLiveRegion}

                        sourceLink={this.props.sourceLink}
                        previousComment={this.props.previousComment}
                        handlePrevClick={this.props.handlePrevClick}
                        commentId={this.props.commentId}
                        next={this.props.next}
                        handleNextClick={this.props.handleNextClick}
                        truncateCommentId={this.props.truncateCommentId}
                        nextComment={this.props.nextComment}
                        user={this.props.user}
                        docketId={this.props.docketId}
                        office={this.props.office}
                        filterText={this.props.filterText}
                        docketTitle={this.props.docketTitle}
                        commentId={this.props.commentId}
                        setSourceLink={this.props.setSourceLink}
                        setAttNum={this.props.setAttNum}
                        attNum={this.props.attNum}>
    
                        
    
                    </CommentDetail><Row>
    
                       
                    </Row>
    
                </>
            );
    };
}
export default CommentHeader;

