import React, { Component } from 'react';

import AttachmentPartPDF from './AttachmentPartPDF';
import ModelingTabs from './ModelingTabs';

class CommentPartTextOrPDF extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			hasPdf: false,
			showPdf: false,
			comment: {}
		};  
	}
	
	getHasPdf = () => {
		console.log(this.props.attNum)
		fetch(`/api/${this.props.office}/hasPdf/${this.props.commentId}/${this.props.attNum}`)
		.then((response) => response.json())
		.then((json) => {
			this.setState({hasPdf: json.pdf, showPdf: true})
		});
		
	}
	comnponentDidUpdate(pp,ps) {
		if (pp.commentId!==this.props.commentId | pp.attNum!==this.props.attNum) {
			console.log("component updating" + pp.attNum + this.props.attNum)
			this.getHasPdf()	

		}
	}
	componentDidMount() {
		if (typeof this.props.commentId!=='undefined'
			&& this.props.commentId!=='' 
			&& typeof this.props.attNum!=='undefined'
			&& this.props.attNum!=='') {
				this.getHasPdf()
			}
	}
	setShowPdf = (tf) => {
		this.setState({showPdf: tf});	
	}

	render() {  
	
		if (this.state.hasPdf && this.state.showPdf) {
			return (
		
					<>
					<AttachmentPartPDF key={this.props.attNum}
						commentId={this.props.commentId}
						filterText={this.props.filterText}
						office={this.props.office}
						setShowPdf={this.setShowPdf}
						hasText={this.props.hasText}
						attNum={this.props.attNum} /></>
			)
		} else {
			return (
				
				<ModelingTabs
				details={this.props.details}
				key={this.props.attNum}
				commentId={this.props.commentId}
				filterText={this.props.filterText}
				office={this.props.office}
				setShowPdf={this.setShowPdf}
				hasText={this.props.hasText}
				hasPdf={this.state.hasPdf}
				showPdf={this.state.showPdf}
				attNum={this.props.attNum}
				user={this.props.user}
				docketId={this.props.docketId}
				>

			</ModelingTabs>
			)
		}
	}
}
export default CommentPartTextOrPDF;