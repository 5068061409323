import React, { Component } from "react";
import { Badge } from '@cmsgov/design-system';

class ActiveFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeFilters: []
        }

    }


    getFilters = () => {
        var arr = []
        // console.log(JSON.stringify("Printing filters" + this.props.filters))
        // for(var i in this.props.filters) {
        //     arr.push([i, this.props.filters[i]]);
        // }

        for (var i in this.props.filters) {
            // console.log("Printing item" + i + "printing value" + this.props.filters[i])
            if (Array.isArray(this.props.filters[i])) {
                for (var j in this.props.filters[i]) {
                    if (j !== [] && j !== [''] && j !== [false])
                        arr.push(this.props.filters[i][j])
                }
            } else if (i === "submitterOrgFound" && this.props.filters[i] === true) {
                arr.push("Submitter Organization Found")

            } else if (i === "ignoreDupes" && this.props.filters[i] === true) {
                arr.push("Remove Duplicate Comments")

            } else if (i === "recInstancesFound" && this.props.filters[i] === true) {
                arr.push("Recommendation Instances Found")
            } else if (i === "clarInstancesFound" && this.props.filters[i] === true) {
                arr.push("Clarification Instances Found")
            } else if (i === "agreementInstancesFound" && this.props.filters[i] === true) {
                arr.push("Agreement Instances Found")
            } else if (i === "disagreementInstancesFound" && this.props.filters[i] === true) {
                arr.push("Disagreement Instances Found")

            } else {
                if (i !== 'text' && i !== 'cid') {
                    arr.push([this.props.filters[i]]);
                }
            }
        }

        var filtered = arr.filter(function (el) {
            return el != null && el != '' && el != false && el != "false";
        });
        return filtered

    }
    /*{"text":"hello","category":["Academic - OT005","Association - Drug"],"topic":["All-Inclusive Care Elderly - 460.74","Home Health Agencies - 484.70"],"cid":"","docId":[],"ignoreDupes":true,"submitterOrgFound":true,"recInstancesFound":true}*/
    createBadges = () => {

    }
    render() {
        var activeFilters = this.getFilters()
        console.log('hihihi')
        console.log(activeFilters)
        var color = activeFilters.length === 0 ? "inactive-filters" : "active-filters"

        // console.log("Got them" + activeFilters)
        return (
            <>

                <div tabIndex="0" style={{ marginBottom: "1%", marginTop: '8px' }}>

                    {activeFilters.map((data, key) => {
                        return (
                            <Badge
                                children={data}
                                variation="info"
                                style={{margin: "2px", backgroundColor: "#005cb0"}}
                                size="default"
                                aria-label={data + " filtered selected"}
                                aria-live="assertive" />

                        );
                    })}</div>
            </>
        )
    }
}

export default ActiveFilters;