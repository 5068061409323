import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Example from './components/Example';
import './QSOGApp.css';
import Cookies from 'js-cookie';
import { Navigate } from "react-router-dom";

import Header from './components/Header';
import Footer from '../FAS/components/footer/Footer'; 
import Surveys from  '../QSOG/components/quicksight/Surveys'

class QSOGApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    /* 
    capture machine name to ensure proper login and out routing.
    */
    this.state.machine = new URL(window.location.href).origin;
  }

  logout = () => {
    this.props.sharedLogout(200, 'Successful')
}

  render() {
    console.log(this.props.logoutUrl)
    console.log(this.props.user)
    console.log(this.props.email)
    console.log(this.props.logoutUrl)
    return (
      <Container className='mx-auto app-container fluid'>
                    <Router forceRefresh={true}>
 
                        <Header
                            adminSwitchApplication={this.props.adminSwitchApplication}
                            logInOut={this.logout}
                            
                            application={this.props.application}
                            admin={this.props.admin}
                            validated={this.props.validated}
                            office={this.props.office}
                            user={this.props.user}
                            qsog={this.props.qsog}
                            userDisplayName={this.props.userDisplayName}

                             />

                        <Routes>
                            <Route path="/example" element={<Example
                            />} />
                            <Route path="/" element={<Surveys
                                label='Survey View'
                                board='surveys' />} />
                            <Route path="/" element={<Navigate replace to="/" />} />
                        </Routes>
                    </Router>
                    <Footer env={this.props.env} />

                
                </Container>
    );
  }
}

export default QSOGApp;
