import React from 'react';
import { Link } from 'react-router-dom';
import '../QSOGApp.css';
import { useNavigate } from 'react-router';


function Example() {
  return (
    <div className="App">
      <h1>Hello, World! I am a QSOG User. Here's an example QSOG page.</h1>
      <Link to="/QSOG/example">
      </Link>
    </div>
  );
}

export default Example;